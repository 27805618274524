/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gamesActions, reportActions, userActions } from '../../_actions';
import Loader from "../../components/Loader/Loader";
import moment from 'moment';
import { FaDownload } from 'react-icons/fa6';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { FaPlusSquare, FaRegMinusSquare } from 'react-icons/fa';
import { getCustomTimestamp } from '../../_helpers';
const keyWord = ""
const fromDate = ""
const toDate = ""


function getDaysInCurrentMonth() {
  const now = new Date(); // Get the current date
  const year = now.getFullYear(); // Get the current year
  const month = now.getMonth(); // Get the current month (0-based)

  // Create a date object for the next month with day 0, which gives the last day of the current month
  return new Date(year, month + 1, 0).getDate();
}


const UserProfilLoss = () => {
  const dispatch = useDispatch();
  const { reports } = useSelector(state => state) || {};
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const { userListNewLean } = useSelector(state => state.users);
  const [isOpen, setIsOpen] = useState(false)
  const [localData, setLocalData] = useState({})
  let sessiondata = JSON.parse(window.sessionStorage.getItem("adminuser"));
  const { subAdminData } = useSelector(state => state.games)
  const [backendTimeStamp, setBackendTimeStamp] = useState(Date.now())


  const { gamesList } = useSelector(state => state.games)
  const today = moment(getCustomTimestamp(backendTimeStamp)).format('YYYY-MM-DD')
  useEffect(() => {
    const fetchGlobalDate = async () => {
      try {
        const res = await userActions.getGlobalDate();
        if (res?.data) {
          const stamp = getCustomTimestamp(Number(res.data.timeStamp), Date.now())
          setBackendTimeStamp(() => stamp);
        }
      } catch (error) {
        console.error("Error fetching global date:", error);
        // Optionally set a fallback or error state here
      }
    };

    fetchGlobalDate();
  }, []);


  useEffect(() => {
    let gameReq = {
      fromDate: '',
      toDate: '',
      keyWord: '',
      sortBy: '',
      sortOrder: '',
      pageNo: 1,
      size: 100
    }
    dispatch(gamesActions.getAllGames(gameReq))
  }, [])


  useEffect(() => {
    const adminUser = window.localStorage.getItem("adminuser");

    if (adminUser) {
      try {
        const parsedAdminUser = JSON.parse(adminUser);

        setLocalData(parsedAdminUser)

        if (parsedAdminUser?.userId && parsedAdminUser.roleId !== 0) {
          // Update `fieldsGame` state
          setFieldsGame((prev) => ({ ...prev, userId: parsedAdminUser.userId }));
          // Find details in `subAdminData`
          if (Array.isArray(subAdminData)) {
            const details = subAdminData.find(ele => ele._id === parsedAdminUser.userId);
            setUserDetails(details || null); // Set `null` if no details are found
          }
        }
      } catch (error) {
        console.error("Error parsing adminuser from localStorage:", error);
      }
    }
  }, [subAdminData]);


  function calculatePercentAmount(value, percent) {
    return (value * percent) / 100;
  }

  useEffect(() => {
    let gameReq2 = {
      "fromDate": fromDate,
      "toDate": toDate,
      "keyWord": keyWord,
      "sortBy": "",
      "sortOrder": "",
      "pageNo": 1,
      "size": 500
    }

    let gameReq = {
      "fromDate": "",
      "toDate": "",
      "keyWord": "",
      "sortBy": "",
      "sortOrder": "",
      "pageNo": 1,
      "size": 500,
      "roleId": 1
    }
    dispatch(gamesActions.getSubAdminList())
    dispatch(gamesActions.getAllGames(gameReq2))
    dispatch(userActions.getUserListNewLean(gameReq))

    return () => {
      setIsOpen(false)
    }
  }, []);

  const createGameSubmit = async (e) => {
    e.preventDefault();
    if (handleValidationCreateGame()) {
      setIsOpen(true)
      let game = {
        "date": fieldsGame?.date ?? today,
        "userId": fieldsGame?.userId || sessiondata.userId,
      }
      dispatch(reportActions.getGamePaymentsForSubAdmin(game));
    }
  };
  const handleValidationCreateGame = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsGame.userId || fieldsGame.userId === "") {
      formIsValid = false;
      errors.userId = "Select User Name";
    }



    setErrorsGame(errors);
    return formIsValid;
  };


  const inputChangeCreate = (e) => {
    e.preventDefault();

    const { name, value } = e.target;
    setIsOpen(false)
    setFieldsGame(prevState => ({ ...prevState, [name]: value }));
    setErrorsGame(prevState => ({ ...prevState, [name]: "" }));
    if (name === 'userId') {
      const details = userListNewLean?.list?.find(ele => ele._id === value)
      setUserDetails(details)
    }

  };

  let { getGamePayments, loading } = reports ? reports : []
  const totalBetsAmountSum = (getGamePayments || []).reduce((sum, record) => sum + record.totalBetsAmount, 0);
  const totalSingleCloseAmountSum = (getGamePayments || []).reduce((sum, record) => sum + record.totalSingleCloseAmount, 0);
  const totalSingleOpenAmountSum = (getGamePayments || []).reduce((sum, record) => sum + record.totalSingleOpenAmount, 0);
  const totalBetsOpenClose1Sum = Number(totalSingleCloseAmountSum) + Number(totalSingleOpenAmountSum);
  const totalJodiAmountSum = (getGamePayments || []).reduce((sum, record) => sum + record.totalJodiAmount, 0);

  const handleDownloadPDF = async () => {
    const input = document.getElementById("report-section");
    const pdf = new jsPDF("p", "mm", "a2");
    const canvas = await html2canvas(input, { scale: 2 });
    const imgData = canvas.toDataURL("image/png");

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(`Report-${moment(fieldsGame?.date).format("DD-MM-YYYY")}.pdf`);
  };



  let letSum = 0

  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden bg-gray-50">
        <div className="flex flex-col flex-1">
          <main className="relative mt-4 px-4">
            <div className="space-y-2">
              <div className='flex items-center justify-between  mx-auto gap-4'>
                <div className="w-full">
                  <label className="block text-gray-700 text-base mb-1">Date</label>
                  <input
                    className="w-full px-3 py-1 text-base bg-white border uppercase border-gray-400 rounded-md focus:outline-none"
                    id="date"
                    name="date"
                    placeholder="Enter Date"
                    value={fieldsGame && fieldsGame["date"] ? fieldsGame["date"] : today}

                    type="date"
                    onChange={inputChangeCreate}
                  />
                  {errorsGame && errorsGame["date"] ? (
                    <div className="text-red-600 text-base invalid-feedback">
                      {errorsGame["date"]}
                    </div>
                  ) : null}
                </div>
                {localData.roleId === 0 ? (
                  <div className="md:w-full w-60">
                    <label class="text-gray-700 text-base mb-1" for="name">User Name :</label>
                    <select className="md:w-full px-3 py-2 text-base bg-white border border-gray-400 rounded-md focus:outline-none w-[600px]" id="userId" name="userId" placeholder="name" value={fieldsGame && fieldsGame["userId"] ? fieldsGame["userId"] : ""} type="text" onChange={inputChangeCreate} >
                      <option value="">Select User Name</option>
                      {userListNewLean?.list && userListNewLean?.total > 0 ? userListNewLean?.list.map((element, index) => (<option value={element && element._id ? element._id : ""}>{element && element.userName ? element.userName : ""}
                      </option>
                      )) : null}
                    </select>
                    {errorsGame && errorsGame["userId"] ? <div className="text-red-600">{errorsGame["userId"]}</div> : null}
                  </div>
                ) : <div>
                  <label class="block text-gray-700 text-base mb-1 w-60" htmlFor="name">User Name :</label>
                  <div className='px-5 py-1 border border-gray-400 rounded'>{userDetails?.userName || "Guest"}</div>
                </div>}
                <div className="w-full">
                  <label className='invisible'>Download Button</label>
                  <button
                    className="px-6 py-2 all-btn text-white font-bold rounded-md shadow-lg transition-all duration-200 w-72"
                    type="button"
                    onClick={createGameSubmit}
                  >
                    Submit
                  </button>
                </div>
                <div className="w-full ">
                  <label className='invisible'>Download Button</label>
                  <button
                    className="px-6 flex items-center gap-6 py-2 all-btn text-white font-bold rounded-md shadow-lg transition-all duration-200 w-60"
                    type="button"
                    onClick={handleDownloadPDF}
                  ><FaDownload className='ml-6' /> Download Report
                  </button>
                </div>
              </div>
              {fieldsGame && !fieldsGame["userId"] && (
                <div className="font-bold flex justify-center text-xl py-40">
                  Please Select User Name to see Game Profit Loss of User
                </div>
              )}
              {isOpen ?
                <div id="report-section" class="bg-white shadow-md rounded-lg w-2/5 px-4 mx-auto" >
                  <div className=' items-center'>
                    <div class="flex justify-center space-x-4 text-md font-semibold border-b bg-[#cecece] p-0.5">
                      <div className='uppercase'> {`${"<< Draw Detail of"} ${fieldsGame?.date ? moment(fieldsGame?.date).format('DD-MM-YYYY') : moment(today).format('DD-MM-YYYY')} >>`} </div>
                    </div>
                    <div className='border border-gray-400 p-0.5 flex justify-center font-bold uppercase bg-gray-200 text-md'>{`<< ${userDetails?.userName ?? null} >>`}</div>
                  </div>

                  <div className='grid grid-cols-1'>
                    <div className=''>
                      <div className='grid grid-cols-6 border text-white text-sm'>
                        <div className='bgcolor px-2 py-2 font-semibold col-span-2'>Game</div>
                        <div className='bgcolor px-2 py-2 font-semibold text-center'>Total Sale</div>
                        <div className='bgcolor px-2 py-2 font-semibold text-center'>O/C</div>
                        <div className='bgcolor px-2 py-2 font-semibold text-center'>Akara</div>
                        <div className='bgcolor px-2 py-2 font-semibold text-center'>Prize</div>
                      </div>

                      {getGamePayments && getGamePayments.length > 0 && Array.isArray(gamesList) && gamesList.length > 0 ? (

                        gamesList.map((ele) => {
                          return getGamePayments.find((e) => e.gameName === ele.name)
                        }).map((ele) => {
                          const totalOpenCloseAmount = (Number(ele?.totalSingleCloseAmount ?? 0) + Number(ele?.totalSingleOpenAmount ?? 0)) / 9;
                          const totalSingleAmount = Number(ele?.totalSingleCloseAmount ?? 0) + Number(ele?.totalSingleOpenAmount ?? 0) + Number(ele?.totalJodiAmount ?? 0);

                          const profitOrLoss = totalSingleAmount;
                          let akCloseId = "akc.close"

                          const gameResult = () => {
                            if (ele?.gameName === "ak open") {
                                const openResult = ele?.resultsGameWise?.find((item) => item._id === ele.gameId)?.result || "";
                                const closeResult = ele?.resultsGameWise?.find((item) => item.gameDetails?.name === akCloseId)?.result || "";
                                return openResult + closeResult;
                            }
                        
                            return ele?.resultsGameWise?.find((item) => item._id === ele.gameId)?.result || null;
                        };

                          letSum += profitOrLoss
                          return (
                            <>
                              <div className='grid grid-cols-6 gap-[2px] border'>
                                <div className='bg-stone-300 px-2 flex justify-between items-center col-span-2 border border-black font-semibold'>
                                  <span className='text-red-600 capitalize text-sm'>{ele?.gameName ?? null}</span>
                                  <span className='text-green-800 text-sm'>{gameResult()}</span>
                                </div>
                                <div className='bg-orange-100 text-sm px-2 py-2 text-center text-green-500 font-semibold border border-gray-400'>{ele?.totalBetsAmount ?? 0}</div>
                                <div className='bg-orange-100 text-sm px-2 py-2 text-center text-[#16392b] font-semibold border border-gray-400'>{(totalOpenCloseAmount).toFixed(2) ?? 0}</div>
                                <div className='bg-orange-100 text-sm px-2 py-2 text-center text-[#16392b] font-semibold border border-gray-400'>{(ele?.totalJodiAmount / 90).toFixed(2) ?? 0}</div>
                                <div className='bg-orange-100 text-sm px-2 py-2 text-center text-fuchsia-900  font-semibold border border-gray-400'>{profitOrLoss}</div>
                              </div>
                            </>
                          );
                        })
                      ) : null}
                      <div className='grid grid-cols-6 gap-1 py-1'>
                        <div className='flex justify-between items-center col-span-2 font-semibold'>
                          <span className='bg-red-700 text-sm px-2 py-2 text-amber-200  font-semibold w-full'>Total</span>
                        </div>
                        {/* <div className='bg-red-700 text-sm px-1 py-1 text-right text-amber-200 font-semibold'>Total</div> */}
                        <div className='bg-red-700 text-sm px-1 py-1 text-right text-amber-200 font-semibold'>{totalBetsAmountSum}</div>
                        <div className='bg-red-700 text-sm px-1 py-1 text-right text-amber-200 font-semibold'>{(totalBetsOpenClose1Sum / 9).toFixed(2)}</div>
                        <div className='bg-red-700 text-sm px-1 py-1 text-right text-amber-200 font-semibold'>{(totalJodiAmountSum / 90).toFixed(2)}</div>
                        <div className='bg-red-700 text-sm px-1 py-1 text-right text-amber-200 font-semibold'>{letSum}</div>
                      </div>

                      <div class="bg-stone-300">

                        {/* <p class="text-center font-bold text-md mb-1 border border-gray-400 py-1">SUMMARY</p> */}
                        <div className='border border-gray-400 py-1 mt-1'>
                          <div class="flex justify-between text-sm border-b border-neutral-400 mx-4" style={{ padding: "2px" }}>
                            <span className='text-red-700 font-semibold'>Total Sale</span>
                            <span className='font-semibold text-green-800 flex items-center gap-1'> {totalBetsAmountSum} <FaRegMinusSquare className="text-[#911212]" /></span>
                          </div>
                          <div class="flex justify-between text-sm border-b border-neutral-400 mx-4" style={{ padding: "2px" }}>
                            <span className='text-red-700 font-semibold'>Prize Detail</span>
                            <span className='font-semibold text-green-800  flex items-center'>{(letSum) || 0}  {<FaPlusSquare className="text-[#911212]" />}</span>
                          </div>
                          <div class="flex justify-between text-sm border-b border-neutral-400 mx-4" style={{ padding: "2px" }}>
                            <span className='text-red-700 font-semibold'>Comm. Detail</span>
                            <span className='text-right text-[#2A0037] font-semibold'>{userDetails?.comm ?? 0} % </span>
                            <span className='font-semibold text-green-800 flex items-center gap-1'> {calculatePercentAmount(totalBetsAmountSum, userDetails?.comm ?? 0)}  <FaPlusSquare className="text-[#911212]" /></span>
                          </div>
                          <div className="flex justify-between text-sm border-b border-neutral-400 mx-4" style={{ padding: "2px" }}>
                            <span className='text-red-700 font-semibold'>Gross Amount</span>
                            <span className='text-right text-[#2A0037] font-semibold'>{(((totalBetsAmountSum + calculatePercentAmount(totalBetsAmountSum, userDetails?.comm)) - letSum) > 0 ? `Har ` : `Jeet`) ?? "N/A"}</span>
                            <span className='font-semibold text-green-800 flex items-center gap-1'>  {Math.abs(((totalBetsAmountSum + calculatePercentAmount(totalBetsAmountSum, userDetails?.comm)) - letSum))} {((totalBetsAmountSum + calculatePercentAmount(totalBetsAmountSum, userDetails?.comm)) - letSum) > 0 ? <FaRegMinusSquare className="text-[#911212]" /> : <FaPlusSquare className="text-[#911212]" />}</span>
                          </div>
                          <div className="flex justify-between text-sm border-b border-neutral-400 mx-4" style={{ padding: "2px" }}>
                            <span className='text-red-700 font-semibold'>Less % Percent</span>
                            <span className='text-right text-[#2A0037] font-semibold'> {userDetails?.share ?? 0} % {' '}</span>
                            <span className='font-semibold text-green-800 flex items-center gap-1'>
                              {Math.abs(calculatePercentAmount((totalBetsAmountSum + calculatePercentAmount(totalBetsAmountSum, userDetails?.comm)) - letSum, userDetails?.share))} {((totalBetsAmountSum + calculatePercentAmount(totalBetsAmountSum, userDetails?.comm)) - letSum) > 0 ? <FaPlusSquare className="text-[#911212]" /> : <FaRegMinusSquare className="text-[#911212]" />}
                            </span>
                          </div>
                          <div className="flex justify-between text-sm border-b border-neutral-400 mx-4" style={{ padding: "2px" }}>
                            <span className='text-red-700 font-semibold'>Final % Net</span>
                            <span className='text-right text-[#2A0037] font-semibold'>{100 - (userDetails?.share || 0)} % {' '}</span>
                            <span className='font-semibold text-green-800 flex items-center gap-1'>
                              {Math.abs(calculatePercentAmount((totalBetsAmountSum + calculatePercentAmount(totalBetsAmountSum, userDetails?.comm)) - letSum, (100 - userDetails?.share)))} {((totalBetsAmountSum + calculatePercentAmount(totalBetsAmountSum, userDetails?.comm)) - letSum) > 0 ? <FaRegMinusSquare className="text-[#911212]" /> : <FaPlusSquare className="text-[#911212]" />}
                            </span>
                          </div>
                          <div className="flex justify-between text-sm border-b border-neutral-400 mx-4" style={{ padding: "2px" }}>
                            <span className='text-red-700 font-semibold'>Installment</span>
                            <span className='font-semibold text-green-800 flex items-center gap-1'>
                              {((userDetails?.creditReference || 0) / getDaysInCurrentMonth())?.toFixed(2) ?? 0} <FaRegMinusSquare className="text-[#911212]" />
                            </span>
                          </div>
                          <div class="flex justify-between text-sm mx-4 border-b border-neutral-400" style={{ padding: "2px" }}>
                            <span className='text-red-700 font-semibold'>B/F Balance</span>
                            <span className='font-semibold text-green-800'>
                              {0}
                            </span>
                          </div>
                          <div class="flex justify-between text-sm mx-4" style={{ padding: "2px" }}>
                            <span className='text-red-700 font-semibold'>Final Amount</span>
                            <span className='font-semibold text-green-800 flex items-center gap-1'>
                              {Math.abs(calculatePercentAmount((totalBetsAmountSum + calculatePercentAmount(totalBetsAmountSum, userDetails?.comm)) - letSum, (100 - userDetails?.share))) - (((userDetails?.creditReference || 0) / getDaysInCurrentMonth())?.toFixed(2) ?? 0)} {
                                (calculatePercentAmount((totalBetsAmountSum + calculatePercentAmount(totalBetsAmountSum, userDetails?.comm)) - letSum, (100 - userDetails?.share))) - (((userDetails?.creditReference || 0) / getDaysInCurrentMonth())) < 0 ? <FaPlusSquare className="text-[#911212]" /> : <FaRegMinusSquare className="text-[#911212]" />
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> :
                null}
            </div>

          </main>
        </div>


      </div >
    </>
  );
};

export default UserProfilLoss;
