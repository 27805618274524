/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardJSON from "./GameManagement.json";
import { gamesActions, marketActions, userActions } from "../../_actions";
import CreateGameModal from "./components/CreateGameModal/CreateGameModal";
import UpdateGameModal from "./components/UpdateGameModal/UpdateGameModal";
import ViewGameModal from "./components/ViewGameModal/ViewGameModal";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css 
import Loader from "../../components/Loader/Loader";
import moment from "moment";
import { FiEdit } from "react-icons/fi";
const size =1000
const IndianJackpots = () => {
  let addref = useRef(null);
  let addref2 = useRef(null);
  const [resetKey, setResetKey] = useState(0);
  const dispatch = useDispatch();
  let [createModal, setCreateModal] = useState(false);
  let [updateModal, setUpdateModal] = useState(false);
  let [viewModal, setViewModal] = useState(false);
  const [marketSelected, setMarketSelected] = useState([]);
  const [fieldsUpdate, setFieldsUpdate] = useState({});
  const [errorsUpdate, setErrorsUpdate] = useState({});
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});

  const { allJodi, loading } = useSelector((state) => state.games);
  const { allMarket } = useSelector((state) => state.markets);

  useEffect(() => {
    let gameReq = {
      fromDate: "",
      toDate: "",
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: 1,
      size: size,
    };
    dispatch(gamesActions.getAllJodiGame(gameReq));
    dispatch(marketActions.getAllMarket());
  }, []);



  const handleCreateHideModal = () => {
    if (addref?.current?.value) {
      addref.current.value = "";
    }
    if (addref2?.current?.value) {
      addref2.current.value = "";
    }
    setCreateModal(false);
    setFieldsGame({});
    setErrorsGame({});
    setMarketSelected([]);
    dispatch(userActions.clearImage());
  };

  const handleUpdateOpenModal = (data) => {
    setFieldsUpdate({
      ...data,
      closingTime: data["closingTime"]
        ? moment.utc(data["closingTime"] * 1000).format("HH:mm")
        : "",
      openTime: data["openTime"]
        ? moment.utc(data["openTime"] * 1000).format("HH:mm")
        : "",
      betClosingTime: data["betClosingTime"]
        ? moment.utc(data["betClosingTime"] * 1000).format("mm:ss")
        : "",
      betOpenTime: data["betOpenTime"]
        ? moment.utc(data["betOpenTime"] * 1000).format("mm:ss")
        : "",
    });
    let datas = data.marketList.map((e) => ({ name: e.name, id: e._id }));
    dispatch(userActions.clearImage());
    setMarketSelected(datas);
    setUpdateModal(true);
  };

  const handleUpdateHideModal = (data) => {
    if (addref2?.current?.value) {
      addref2.current.value = "";
    }
    dispatch(userActions.clearImage());
    setUpdateModal(false);
    setErrorsUpdate({});
    setMarketSelected([]);
  };

  const handleViewHideModal = (data) => {
    setViewModal(false);
  };

  const inputChangeUpdate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsUpdate((prevState) => ({ ...prevState, [name]: value }));
    setErrorsUpdate((prevState) => ({ ...prevState, [name]: "" }));
  };

  const inputChangeCreate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame((prevState) => ({ ...prevState, [name]: value }));
    setErrorsGame((prevState) => ({ ...prevState, [name]: "" }));
  };

  const updateGameSubmit = (e) => {
    e.preventDefault();
    if (handleValidationUpdateGame()) {
      let selectedmarketData =
        marketSelected && marketSelected.length > 0
          ? marketSelected.map((element) => element.id)
          : null;
      let { users } = selector ? selector : {};
      let { fileData } = users ? users : {};
      let gamePageRefresh = {
        fromDate: "",
        toDate: "",
        keyWord: "",
        sortBy: "",
        sortOrder: "",
        pageNo: 1,
        size: size,
      };

      const {
        name,
        shortName,
        openTime,
        priority,
        min,
        max,
        backgroundColor,
        _id,
      } = fieldsUpdate;

      dispatch(
        gamesActions.updateGames(
          {
            id: _id,
            name: name && name.trim(),
            shortName: shortName && shortName.trim(),
            openTime: openTime,
            closingTime: openTime,
            betOpenTime: "00:00",
            betClosingTime: "00:00",
            min: min,
            max: max,
            priority: priority,
            backgroundColor: backgroundColor && backgroundColor.trim(),
            marketList: selectedmarketData,
            image: fileData || fieldsUpdate?.image,
          },
          setUpdateModal,
          gamePageRefresh
        )
      );
      if (addref2?.current?.value) {
        addref2.current.value = "";
      }
    }
  };

  let selector = useSelector((state) => state);

  const createGameSubmit = (e) => {
    let { users } = selector ? selector : {};
    let { fileData } = users ? users : {};
    e.preventDefault();
    if (handleValidationCreateGame()) {
      let selectedmarketData =
        marketSelected && marketSelected.length > 0
          ? marketSelected.map((element) => element.id)
          : null;

      let gamePageRefresh = {
        fromDate: "",
        toDate: "",
        keyWord: "",
        sortBy: "",
        sortOrder: "",
        pageNo: 1,
        size: size,
      };

      const {
        name,
        shortName,
        openTime,
        priority,
        betOpenTime,
        betClosingTime,
      } = fieldsGame;
      dispatch(
        gamesActions.createGames(
          {
            name: name && name.trim(),
            shortName: shortName && shortName.trim(),
            betOpenTime: betOpenTime,
            betClosingTime: betClosingTime,
            openTime: openTime,
            closingTime: openTime,
            priority: priority,
            isJodi: true,
            backgroundColor: "",
            marketList: selectedmarketData,
            image: fileData ? fileData : " ",
          },
          gamePageRefresh
        )
      );
      handleCreateHideModal();
    }
  };

  const handleValidationUpdateGame = () => {
    let formIsValid = true;
    let errors = {}; 
    let selectedmarketData =
      marketSelected && marketSelected.length > 0
        ? marketSelected.map((element) => element._id)
        : null;

    if (!selectedmarketData || selectedmarketData.length === 0) {
      formIsValid = false;
      errors.marketSelected = "Selected market data";
    }
    if (!fieldsUpdate.name || fieldsUpdate.name.trim() === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    } 
    if (!fieldsUpdate.shortName || fieldsUpdate.shortName.trim() === "") {
      formIsValid = false;
      errors.shortName = DashboardJSON.ErrorMsg.shortName;
    }

    if (!fieldsUpdate.openTime || fieldsUpdate.openTime === "") {
      formIsValid = false;
      errors.openTime = DashboardJSON.ErrorMsg.openTime;
    } 

    if (!fieldsUpdate.priority || fieldsUpdate.priority === "") {
      formIsValid = false;
      errors.priority = DashboardJSON.ErrorMsg.priority;
    }
    setErrorsUpdate(errors);
    return formIsValid;
  };

  const handleValidationCreateGame = () => {
    let formIsValid = true;
    let errors = {};
    let selectedmarketData =
      marketSelected && marketSelected.length > 0
        ? marketSelected.map((element) => element._id)
        : null;

    if (!selectedmarketData || selectedmarketData.length === 0) {
      formIsValid = false;
      errors.selectedmarketData = "Selected market data";
    }

    if (!fieldsGame.name || fieldsGame.name.trim() === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }
    if (!fieldsGame.shortName || fieldsGame.shortName.trim() === "") {
      formIsValid = false;
      errors.shortName = DashboardJSON.ErrorMsg.shortName;
    }

    if (!fieldsGame.openTime || fieldsGame.openTime === "") {
      formIsValid = false;
      errors.openTime = DashboardJSON.ErrorMsg.openTime;
    } 

    if (!fieldsGame.priority || fieldsGame.priority === "") {
      formIsValid = false;
      errors.priority = DashboardJSON.ErrorMsg.priority;
    }

    setErrorsGame(errors);
    return formIsValid;
  };

  const handleFile = (event) => {

    if (event.target.files[0]) {
      dispatch(userActions.uploadImage(event));
      setErrorsGame((prevState) => ({ ...prevState, img: "" }));
    } else {
      console.log("No File To Upload!");
    }
  };

  const onSelect = (selectedList, selectedItem) => {
    let data = selectedList.map((e) => ({ name: e.name, id: e._id || e.id })); 
    setMarketSelected(data);
    setErrorsGame((prevState) => ({ ...prevState, selectedmarketData: "" }));
  };

  const onRemove = (selectedList, removedItem) => {
    let filtervalue =
      selectedList && selectedList.filter((item) => item.id !== removedItem.id);
    setMarketSelected(filtervalue);
  };
  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl">
                      <section className="flex justify-between my-2 ">
                        <div>
                          <p className="text-2xl font-bold">
                            Indian Jackpots Games
                          </p>
                        </div>
                        <div>
                        </div>
                      </section>

                      <div>
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg border">
                          <table className="w-full text-base text-left rtl:text-right text-gray-500 ">
                            <thead className="text-base text-gray-900 font-bold capitalize bg-gray-50  ">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-3 whitespace-nowrap py-3"
                                >
                                  #
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 whitespace-nowrap py-3"
                                >
                                  Games
                                </th>

                                <th
                                  scope="col"
                                  className="px-3 whitespace-nowrap py-3"
                                >
                                  open Time
                                </th>

                                <th
                                  scope="col"
                                  className="px-3 whitespace-nowrap  text-center py-3 "
                                >
                                  action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {allJodi &&
                                allJodi.length > 0 &&
                                allJodi.map((element, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <tr
                                        key={index}
                                        className={
                                          (index + 1) % 2 === 0
                                            ? "bg-white border-b"
                                            : "bg-gray-100"
                                        }
                                      >
                                        <th
                                          scope="row"
                                          className="px-3 py-2 font-medium whitespace-nowrap"
                                        >
                                          {index + 1}{" "}
                                          {/* Use counter for serial number */}
                                        </th>
                                        <th
                                          scope="row"
                                          className="px-3 py-2 capitalize font-medium text-black whitespace-nowrap"
                                        >
                                          {element && element.name
                                            ? element.name
                                            : "-"}
                                        </th>
                                        <th
                                          scope="row"
                                          className="px-3 py-2 font-medium whitespace-nowrap"
                                        >
                                          {element &&
                                            element.openTime &&
                                            moment()
                                              .hours(
                                                parseInt(
                                                  element.openTime / 3600
                                                )
                                              )
                                              .minutes(
                                                parseInt(
                                                  (element.openTime % 3600) / 60
                                                )
                                              )
                                              .seconds(
                                                parseInt(element.openTime % 60)
                                              )
                                              .milliseconds(0)
                                              .format("h:mm A")}
                                        </th>

                                        <td className="px-3 py-2 flex justify-center">
                                          <button onClick={() =>
                                            handleUpdateOpenModal(element)
                                          } className="relative flex justify-start place-items-center gap-1 px-2 py-1 rounded cursor-pointer bg-green-600 text-white">

                                            <FiEdit

                                              title="Edit"
                                              className=" cursor-pointer"
                                            /> Edit


                                          </button>

                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div> 
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateGameModal
        createModal={createModal}
        handleCreateHideModal={handleCreateHideModal}
        handleFile={handleFile}
        selectedMarket={marketSelected}
        inputChangeCreate={inputChangeCreate}
        fieldsGame={fieldsGame}
        setMarketSelected={setMarketSelected}
        errorsGame={errorsGame}
        allMarket={allMarket}
        onSelect={onSelect}
        resetKey={resetKey}
        setResetKey={setResetKey}
        onRemove={onRemove}
        createGameSubmit={createGameSubmit}
        addref={addref}
      />

      <UpdateGameModal
        // options={options}
        updateModal={updateModal}
        handleUpdateHideModal={handleUpdateHideModal}
        handleFile={handleFile}
        inputChangeUpdate={inputChangeUpdate}
        fieldsUpdate={fieldsUpdate}
        addref2={addref2}
        errorsUpdate={errorsUpdate}
        allMarket={allMarket}
        marketSelected={marketSelected}
        onSelect={onSelect}
        onRemove={onRemove}
        updateGameSubmit={updateGameSubmit}
      />

      <ViewGameModal
        viewModal={viewModal}
        handleViewHideModal={handleViewHideModal}
        rowData={null}
      />
    </>
  );
};

export default IndianJackpots;
