/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gamesActions } from "../../_actions";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ReactPaginate from "react-paginate";
import Loader from "../../components/Loader/Loader";
import ViewGameModal from "../gameManagement/components/ViewGameModal/ViewGameModal";
import Select from "react-select";
import { RiDeleteBinLine } from "react-icons/ri";


function generateNumbers() {
  const numbers = [];
  for (let i = 0; i <= 99; i++) {
    numbers.push(i.toString().padStart(2, '0'));
  }
  return numbers;
}

const AddFundReport = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  let [viewModal, setViewModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const [isAkbar, setIsAkbar] = useState(false);
  const [numberList, setNumberList] = useState([]);
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  const size = 10;
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const keyWord = "";
  const [roleList, setRoleList] = useState([]);

  const today = new Date().toISOString().split("T")[0];

  const { gamesList } = useSelector((state) => state.games);


  const numberListJodi = generateNumbers()
  const numberListSingle = [
    "0", "1", "2", "3", "4", "5", "6", "7", "8", "9",
  ]


  useEffect(() => {
    if (fieldsGame?.gameId) {
      const resData = isAkbar ? numberListSingle : numberListJodi;
      if (Array.isArray(resData)) {
        setNumberList(resData);
      }
    }
  }, [fieldsGame]);

  useEffect(() => {
    setRoleList([
      { id: 1, name: 'AGENT' },
      { id: 100, name: 'ONLINE' }
    ])
    let gameReq = {
      fromDate: "",
      toDate: "",
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: 1,
      size: 500,
    };
    let listReq = {
      fromDate: "",
      toDate: "",
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
      brandGame: false,
    };

    dispatch(gamesActions.marketNumberInSingle());
    dispatch(gamesActions.getResultListAdmin(listReq));
    dispatch(gamesActions.getAllGames(gameReq));
    dispatch(gamesActions.marketNumberThreeDigit());
  }, [pageNo]);

  const inputChangeCreate = (e) => {
    const { name, value } = e.target;
    setFieldsGame((prevState) => ({ ...prevState, [name]: value }));
    setErrorsGame((prevState) => ({ ...prevState, [name]: "" }));

    if (name === "gameId") {
      setFieldsGame((prevState) => ({ ...prevState, num: '' }));
    }
    const filterDataRes = gamesList?.find((ele) => ele?._id === value);
    if (filterDataRes?.shortName === 'akbar_open' || filterDataRes?.shortName === 'akbar_close') {
      setIsAkbar(true)
    } else {
      setIsAkbar(false)
    }


  };

  const createGameSubmit = (e) => {
    e.preventDefault();
    if (handleValidationCreateGame()) {
      let gamePageRefresh = {
        fromDate: "",
        toDate: "",
        keyWord: keyWord,
        sortBy: "",
        sortOrder: "",
        pageNo: pageNo,
        size: size,
        brandGame: false,
      };

      let obj = {
        gameId: fieldsGame?.gameId,
        betNumber: fieldsGame?.num,
        date: fieldsGame?.date,
        roleId: fieldsGame?.roleId,
      };

      dispatch(gamesActions.resultDeclare(obj, gamePageRefresh));
    }
  };



  const handleValidationCreateGame = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsGame.date || fieldsGame.date === "") {
      formIsValid = false;
      errors.date = "Date is required";
    }

    if (!fieldsGame.gameId || fieldsGame.gameId === "") {
      formIsValid = false;
      errors.gameId = "Game is Required";
    }

    if (!fieldsGame.num || fieldsGame.num === "") {
      formIsValid = false;
      errors.num = "Game is Required";
    }
    setErrorsGame(errors);
    return formIsValid;
  };

  const handlePageClick = (data) => {
    let offset = Math.ceil(data.selected * size);
    setCurrentPage(data.selected);
    setOffset(offset);
    setPageNo(data.selected + 1);
    let pageReq = {
      fromDate: "",
      toDate: "",
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: data.selected + 1,
      size: size,
      brandGame: false,
    };
    dispatch(gamesActions.getAllGames(pageReq));
  };


  const ShowWinnerList2 = () => {
    let obj = {
      isJackPot: false,
      isStarLine: false,
      gameId: fieldsGame?.gameId,
      betNumber: fieldsGame?.num,
      isOpen: (fieldsGame.isOpen).toString() === "true" ? true : false,
      date: fieldsGame?.date,
    };
    if (handleValidationCreateGame()) {
      dispatch(gamesActions.resultDeclare(obj));
    }
  };

  let handleViewHideModal = () => {
    setViewModal(false);
  };



  let { games } = selector ? selector : {};
  let {
    loading,
    gameResultList,
    gameResultTotal,
    winnerList,
    // numberList,
  } = games ? games : {};

  let optionsData = gamesList && gamesList.length > 0 && gamesList.filter((e) => !e?.brandGame);

  const rollback = (data) => {
    let gamePageRefresh = {
      fromDate: "",
      toDate: "",
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
      brandGame: false,
    };
    dispatch(gamesActions.rollBackResultDeclare(data, gamePageRefresh))
  }

  return (
    <>
      <Loader loading={selector?.games?.loading || loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl space-y-5">
                      <div className="text-left bg-white shadow-md space-y-2 rounded-md">
                        <div className="px-6 py-4 w-full">
                          <div className="grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
                            <div className="w-ful">
                              <label
                                class="block text-gray-700 text-base font-semibold mb-1"
                                for="gamename"
                              >
                                Result Date
                              </label>
                              <input
                                className={`w-full px-3 py-1 text-base bg-white border border-gray-400 rounded-md focus:outline-none uppercase`}
                                id="date"
                                name="date"
                                placeholder="Enter Date"
                                value={
                                  fieldsGame && fieldsGame["date"]
                                    ? fieldsGame["date"]
                                    : ""
                                }
                                max={today}
                                type="date"
                                onChange={inputChangeCreate}
                              />
                              {errorsGame && errorsGame["date"] ? (
                                <div className="text-red-600 invalid-feedback">
                                  {errorsGame["date"]}
                                </div>
                              ) : null}
                            </div>

                            <div className="w-full">
                              <label class="block text-gray-700 text-base font-semibold mb-1" for="name">User Type :</label>
                              <select
                                className="w-full px-3 py-2 capitalize text-base bg-white border border-gray-400 rounded focus:outline-none"
                                id="roleId"
                                name="roleId"
                                placeholder="name"
                                value={fieldsGame && fieldsGame["roleId"] ? fieldsGame["roleId"] : ""}
                                type="text"
                                onChange={inputChangeCreate}
                              >
                                <option value="">Select User type</option>
                                {roleList && roleList.length > 0
                                  ? roleList.map((element, index) => (
                                    <option value={element && element.id ? element.id : ""}>  {element && element.name ? element.name : ""}</option>
                                  ))
                                  : null}
                              </select>
                              {errorsGame && errorsGame["roleId"] ? (
                                <div className="text-red-600 invalid-feedback">
                                  {errorsGame["roleId"]}
                                </div>
                              ) : null}
                            </div>

                            <div className="w-ful">
                              <label
                                class="block text-gray-700 text-base font-semibold mb-1"
                                for="name"
                              >
                                Game Name
                              </label>
                              <select
                                className={`w-full px-3 py-2 text-base bg-white border border-gray-400 rounded-md focus:outline-none`}
                                id="gameId"
                                name="gameId"
                                placeholder="name"
                                value={
                                  fieldsGame && fieldsGame["gameId"]
                                    ? fieldsGame["gameId"]
                                    : ""
                                }
                                type="text"
                                onChange={inputChangeCreate}
                              >
                                <option value="">Select Game</option>
                                {optionsData && optionsData.length > 0
                                  ? optionsData.map((element, index) => (
                                    <option
                                      value={
                                        element && element._id
                                          ? element._id
                                          : ""
                                      }
                                    >
                                      {element && element.name
                                        ? element.name
                                        : ""}
                                    </option>
                                  ))
                                  : null}
                              </select>
                              {errorsGame && errorsGame["gameId"] ? (
                                <div className="text-red-600 invalid-feedback">
                                  {errorsGame["gameId"]}
                                </div>
                              ) : null}
                            </div>
                            <div className="w-full">
                              <label className="block text-gray-700 text-base font-semibold mb-1">
                                Number
                              </label>
                              <Select
                                onChange={(selectedOption) =>
                                  inputChangeCreate({
                                    target: {
                                      name: "num",
                                      value: selectedOption.value,
                                    },
                                  })
                                }
                                name="num"
                                value={
                                  fieldsGame && fieldsGame["num"]
                                    ? {
                                      value: fieldsGame["num"],
                                      label: fieldsGame["num"],
                                    }
                                    : ""
                                }
                                options={
                                  Array.isArray(numberList)
                                    ? numberList.map((option) => ({
                                      value: option,
                                      label: option,
                                    }))
                                    : []
                                }

                                classNamePrefix="react-select"
                                placeholder="Select Number"
                                className=" w-full"
                              />
                              {errorsGame && errorsGame["num"] ? (
                                <div className="text-red-600 invalid-feedback">
                                  {errorsGame["num"]}
                                </div>
                              ) : null}
                            </div>

                            <div className="w-full flex items-center space-x-4">
                              <div className="w-full xl:pt-4 lg:pt-0 md:pt-4 pt-0">
                                <button className="flex-1 justify-center w-full px-4 py-1 all-btn rounded mt-1" type="button" onClick={(e) => createGameSubmit(e)}>Save</button>
                                <div className="text-white">--------------</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="relative overflow-x-auto shadow-md bg-white pb-4">
                        <table className="w-full text-base text-left rtl:text-right text-gray-500 ">
                          <thead className="text-gray-800 capitalize bgcolortb">
                            <tr>
                              <th className="px-3 py-3 text-left">
                                #
                              </th>
                              <th className="px-3 py-3 text-left">
                                Result Date
                              </th>
                              <th className="px-3 py-3 text-left">
                                User Type
                              </th>
                              <th className="px-3 py-3 text-left">
                                Game Name
                              </th>
                              <th className="px-3 py-3 text-left">
                                Result Number
                              </th>
                              <th className="px-3 py-3 text-left">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {gameResultList && gameResultList.length > 0
                              ? gameResultList.map((element, index) => (
                                <React.Fragment key={index}>
                                  <tr
                                    key={index}
                                    className={
                                      index % 2 === 0
                                        ? "bg-white border-b font-semibold text-black"
                                        : "bg-gray-100 font-semibold text-black"
                                    }
                                  >
                                    <td
                                      className="px-2 lg:px-4 py-2 text-base text-left"
                                    >
                                      {offset + index + 1}
                                    </td>
                                    <td
                                      className="px-2 lg:px-4 py-2 text-base text-left"
                                    >
                                      {element && element.rDate
                                        ? new Date(
                                          element.rDate
                                        ).toLocaleDateString()
                                        : "-"}
                                    </td>
                                    <td
                                      className="px-2 lg:px-4 py-2 text-base text-left capitalize"
                                    >
                                      {element && element.roleId && element.roleId === 100
                                        ? 'Online' : element && element.roleId && element.roleId === 1
                                          ? 'Agent'
                                          : "-"}
                                    </td>
                                    <td
                                      className="px-2 lg:px-4 py-2 text-base text-left uppercase"
                                    >
                                      {element && element.gameId
                                        ? element.gameId?.name
                                        : "-"}
                                    </td>

                                    <td
                                      className="px-2 lg:px-4 py-2 text-base text-left"
                                    >
                                      {element &&
                                        element.jodiNumber?.toString()
                                        ? element.jodiNumber?.toString()
                                        : "-"}
                                    </td>

                                    <td
                                      className="px-2 lg:px-4 py-2 text-base text-left"
                                    >
                                      <span
                                        className=" text-red-500 cursor-pointer"
                                        onClick={() => rollback({ id: element._id })}
                                      >
                                        <span className="">
                                          <RiDeleteBinLine className="w-6 h-6" />
                                        </span>
                                      </span>

                                    </td>
                                  </tr>
                                </React.Fragment>
                              ))
                              : null}
                          </tbody>
                        </table>
                        <div className="pt-4 flex justify-end">
                          {gameResultList && gameResultTotal > 10 ? (
                            <ReactPaginate
                              previousLabel={"Prev"}
                              nextLabel={"Next"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={Math.ceil(gameResultTotal / size)}
                              marginPagesDisplayed={0}
                              pageRangeDisplayed={10}
                              onPageChange={handlePageClick}
                              containerClassName={"pagination"}
                              pageClassName={"page-cls"}
                              activeClassName={"active"}
                              forcePage={currentPage}
                            />
                          ) : null}
                        </div>
                      </div>

                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
          <ViewGameModal
            viewModal={viewModal}
            handleViewHideModal={handleViewHideModal}
            winnerList={winnerList}
            ShowWinnerList2={ShowWinnerList2}
          />
        </div>
      </div>
    </>
  );
};

export default AddFundReport;
