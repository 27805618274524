/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { gamesActions, reportActions } from '../../_actions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader/Loader';

const StarlineBidPointHistory = () => {
    let selector = useSelector(state => state)
    const dispatch = useDispatch();
    const today = new Date().toISOString().split('T')[0];
    let { games } = selector ? selector : {};
    let { allMarket } = games ? games : {};
    const [fieldsGame, setFieldsGame] = useState({});
    const [errorsGame, setErrorsGame] = useState({});
    const { loading } = useSelector(state => state.reports);
    const { gamesListb } = useSelector(state => state.games);

    const keyWord = ""
    const fromDate = ""
    const toDate = ""
    const [fullList, setfullList] = useState([])
    useEffect(() => {

        let gameReq3 = {
            fromDate: fromDate,
            toDate: toDate,
            keyWord: keyWord,
            sortBy: "",
            sortOrder: "",
            pageNo: 1,
            size: 500,
        };

        let gameReq2 = {
            "fromDate": fromDate,
            "toDate": toDate,
            "keyWord": keyWord,
            "sortBy": "",
            "sortOrder": "",
            "pageNo": 1,
            "size": 500
        }
        dispatch(gamesActions.getAllGames(gameReq2))
        // dispatch(reportActions.getNumberWiseMarketSum(gameReq));
        dispatch(gamesActions.getAllMarket())
        dispatch(gamesActions.getAllUser())
        dispatch(gamesActions.getAllbrandGame(gameReq3));


    }, []);



    const inputChangeCreate = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setFieldsGame(prevState => ({ ...prevState, [name]: value }));
        setErrorsGame(prevState => ({ ...prevState, [name]: "" }));
    };


    const createGameSubmit = async (e) => {


        e.preventDefault();
        if (handleValidationCreateGame()) {
            let filterData = await allMarket && Array.isArray(allMarket) && allMarket.filter((e) => e._id === fieldsGame?.userId2)
            let dataList = await filterData && filterData[0] && filterData[0]?.numList
            setfullList(dataList)
            let gameReq2 = {
                "date": fieldsGame?.date || "",
                "gameId": fieldsGame?.userId,
                "marketId": fieldsGame?.userId2,
            }
            dispatch(reportActions.getNumberWiseMarketSum(gameReq2));
        }
    };

    const handleValidationCreateGame = () => {
        let formIsValid = true;
        let errors = {};

        if (!fieldsGame.userId || fieldsGame.userId === "") {
            formIsValid = false;
            errors.userId = "Select Game Name";
        }

        if (!fieldsGame.userId2 || fieldsGame.userId2 === "") {
            formIsValid = false;
            errors.userId2 = "Select Game Type";
        }

        // if (!fieldsGame.todate || fieldsGame.todate === "") {
        //   formIsValid = false;
        //   errors.todate = "Select Date";
        // }

        if (!fieldsGame.date || fieldsGame.date === "") {
            formIsValid = false;
            errors.date = "Select Date";
        }

        setErrorsGame(errors);
        return formIsValid;
    };







    function isNestedArray(arr) {
        // Base case: if arr is not an array, it's not nested
        if (!Array.isArray(arr)) {
            return false;
        }

        // Check each element in the array
        for (let element of arr) {
            // If any element is an array, it's nested
            if (Array.isArray(element)) {
                return true;
            }
        }

        // If none of the elements are arrays, it's not nested
        return false;
    }

    let { reports } = selector ? selector : {}
    let { numberListData } = reports ? reports : []
    let optionsData =
        gamesListb && gamesListb.length > 0 && gamesListb;


    return (
        <div>
            <Loader loading={loading} />
            <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
                <div className="relative">
                    <div className="relative z-20 flex min-h-screen">
                        <div className="flex w-full overflow-hidden ">
                            <div className="flex flex-col flex-1 overflow-hidden">
                                <main className="relative flex-1 ">
                                    <div className="p-3 2xl:p-10 sm:p-5">
                                        <div className="mx-auto max-w-screen-3xl space-y-10">

                                            <div className="px-6 py-4 text-left bg-white space-y-2 rounded-lg">

                                                <div className='flex justify-between md:flex-nowrap gap-1  flex-wrap items-end  w-full '>

                                                    <div className="mt-1 shadow-sm w-full">
                                                        <label class="block text-gray-700 text-base mb-2" for="gamename">Date :</label>
                                                        <input className="w-full px-3 py-1.5 text-base bg-white border border-gray-400 rounded-lg focus:outline-none"
                                                            id="date" name="date" max={today} placeholder="Enter date" value={fieldsGame && fieldsGame["date"] ? fieldsGame["date"] : ""} type="date" onChange={inputChangeCreate} />
                                                        {errorsGame && errorsGame["date"] ?
                                                            <div className="text-red-600 invalid-feedback">
                                                                {errorsGame["date"]}
                                                            </div>
                                                            : null}
                                                    </div>

                                                    <div className="mt-1 shadow-sm w-full">
                                                        <label class="block text-gray-700 text-base mb-2" for="name">Game Name :</label>
                                                        <select className="w-full px-3 py-2 capitalize text-base bg-white border border-gray-400 rounded-lg focus:outline-none"
                                                            id="userId" name="userId" placeholder="name"
                                                            value={fieldsGame && fieldsGame["userId"] ? fieldsGame["userId"] : ""} type="text" onChange={inputChangeCreate} >
                                                            <option value="">Select Game Name</option>
                                                            {optionsData && optionsData.length > 0 ? optionsData.map((element, index) => (
                                                                <option value={element && element._id ? element._id : ""}>{element && element.name ? element.name : ""}</option>
                                                            )) : null}

                                                        </select>
                                                        {errorsGame && errorsGame["userId"] ?
                                                            <div className="text-red-600 invalid-feedback">
                                                                {errorsGame["userId"]}
                                                            </div>
                                                            : null}
                                                    </div>


                                                    <div className="mt-1 shadow-sm w-full">
                                                        <label class="block text-gray-700 text-base mb-2" for="name">Game Type :</label>
                                                        <select className="w-full px-3 py-2 text-base bg-white border border-gray-400 rounded-lg focus:outline-none"
                                                            id="userId2" name="userId2" placeholder="name"
                                                            value={fieldsGame && fieldsGame["userId2"] ? fieldsGame["userId2"] : ""} type="text" onChange={inputChangeCreate} >
                                                            <option value="">Select Game Type</option>
                                                            {allMarket && allMarket.length > 0 ? allMarket?.filter(e => (!e.isJackpot && e.isStarLine))?.map((element, index) => (
                                                                <option value={element && element._id ? element._id : ""}>{element && element.name ? element.name : ""}</option>
                                                            )) : null}
                                                        </select>
                                                        {errorsGame && errorsGame["userId2"] ?
                                                            <div className="text-red-600 invalid-feedback">
                                                                {errorsGame["userId2"]}
                                                            </div>
                                                            : null}
                                                    </div>

                                                    <div className="w-full flex mt-2 md:mt-0 justify-start items-end">
                                                        <button className="flex justify-center w-full lg:w-auto px-4 py-2 text-base font-semibold text-white capitalize  transition duration-150 ease-in-out bgcolor border border-[#7D1970] rounded-xl focus:outline-none" type="button" onClick={createGameSubmit}>Submit</button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className=" py-4 text-left bg-white space-y-2 rounded-lg">
                                                <div className='flex justify-between items-center px-3 py-2 border-b'>
                                                    <span className="p-1 lg:p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white ">
                                                        Bits on Number List
                                                    </span>
                                                </div>
                                                <div className="relative flex  gap-4 flex-wrap overflow-x-auto p-5">


                                                    {!isNestedArray(fullList) && fullList && fullList.map((e) => {

                                                        let data = numberListData && numberListData.length > 0 && numberListData.filter((elem) => parseInt(elem._id) === e)
                                                        return (
                                                            !Array.isArray(e) && <div className=' w-14  flex justify-center flex-col items-center  h-14 bg-gray-300'>
                                                                <div className=' border-2 w-full text-center'>{e}</div>
                                                                <div className=' border-2 w-full text-center'><div className=' bg-red-500 text-white  rounded-full  text-center'>{(data && data[0] && data[0].totalAmount) || 0}</div></div>


                                                            </div>

                                                        )
                                                    })
                                                    }
                                                    {isNestedArray(fullList) && fullList && fullList.flat().map((e) => {

                                                        let data = numberListData && numberListData.length > 0 && numberListData.filter((elem) => parseInt(elem._id) === e)



                                                        return (
                                                            <div className=' w-14  flex justify-center flex-col items-center  h-14 bg-gray-300'>
                                                                <div className=' border-2 w-full text-center'>{e}</div>
                                                                <div className=' border-2 w-full text-center'>
                                                                    <div className=' bg-red-500 text-white  rounded-full  text-center'>{(data && data[0] && data[0].totalAmount) || 0}</div>
                                                                </div>
                                                            </div>
                                                        )

                                                    })
                                                    }

                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StarlineBidPointHistory