/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardJSON from "./UserManagement.json";
import { userActions, walletActions } from "../../_actions";
import CreateUserModal from "./components/CreateUserModal/CreateUserModal";
import ViewUserModal from "./components/ViewUserModal/ViewUserModal";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { IoWalletOutline } from "react-icons/io5";
import Loader from "../../components/Loader/Loader";
import { FiEdit } from "react-icons/fi";
import ModelTest from "./ModelTest";
import { TfiWallet } from "react-icons/tfi";
import { MdDelete } from "react-icons/md";
import ReactPaginate from "react-paginate";


const size = 10;


const SubAdmin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [userOpenModal, setCreateModal] = useState(false);
  const [fieldsUser, setfieldsUser] = useState({});
  const [errorsUser, setErrorsUpdate] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  const [isOpen3, setisOpen3] = useState(false);
  const [currentPage3, setCurrentPage3] = useState(0);
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const onClose3 = () => {
    setisOpen3(false);
  };
  let selector = useSelector((state) => state);
  let addRef = useRef(null);

  useEffect(() => {
    let myBetReq = {
      keyWord: "",
      pageNo: pageNo,
      sortBy: "createdAt",
      sortOrder: "desc",
      fromDate: "",
      toDate: "",
      size: size,
      roleId: 1,
    };
    dispatch(userActions.getUserListNewLean(myBetReq));
    // dispatch(reportActions.getLedger());
    dispatch(userActions.getAdminProfile());
  }, [pageNo]);

  const handleOpenModal = () => {
    setCreateModal(true);
  };

  const handleHideModal = (data) => {
    dispatch(userActions.clearImage());
    if (addRef.current) {
      addRef.current.value = "";
    }
    setCreateModal(false);
    setErrorsUpdate({});
    setfieldsUser({});
  };
  const [rowData, setrowData] = useState({});
  const [viewModal, setviewModal] = useState(false);

  const handleViewHideModal = (data) => {
    setviewModal(false);
  };

  const inputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setfieldsUser((prevState) => ({ ...prevState, [name]: value }));
    setErrorsUpdate((prevState) => ({ ...prevState, [name]: "" }));
  };

  const createUserSubmit = (e) => {
    let { users } = selector ? selector : {};
    let { fileData } = users ? users : {};
    e.preventDefault();
    if (handleValidationUser()) {
      let userPageRefresh = {
        keyWord: "",
        pageNo: pageNo,
        sortBy: "createdAt",
        sortOrder: "desc",
        fromDate: "",
        toDate: "",
        size: size,
        roleId: 1
      };

      const { userName, mobNo, comm, share, password, confirmPassword, karachiComm, karachiShare, creditReference, address, betLimit, name } =
        fieldsUser;

      let obj = {
        userName: userName && userName.trim(),
        mobNo: mobNo && mobNo.trim(),
        karachiComm: karachiComm || 0,
        karachiShare: karachiShare || 0,
        comm: comm || 0,
        share: share || 0,
        password: password.trim(),
        name: name,
        address: address,
        betLimit: betLimit,
        confirmPassword: confirmPassword && confirmPassword.trim(),
        image: fileData ? fileData : "",
        roleId: 1,
        creditReference: creditReference
      };


      dispatch(userActions.registerUserByAdmin(obj, userPageRefresh));

      handleHideModal();
    }
  };

  const handleValidationUser = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsUser.userName || fieldsUser.userName.trim() === "") {
      formIsValid = false;
      errors.userName = DashboardJSON.ErrorMsg.userName;
    }

    if (!fieldsUser.userName || fieldsUser.userName.length < 5) {
      formIsValid = false;
      errors.userName = "User name must be at least 5 characters";
    }

    if (!fieldsUser.mobNo || fieldsUser.mobNo.trim() === "") {
      formIsValid = false;
      errors.mobNo = DashboardJSON.ErrorMsg.mobNo;
    }

    if (!fieldsUser.password || fieldsUser.password.trim() === "") {
      formIsValid = false;
      errors.password = DashboardJSON.ErrorMsg.password;
    } else {
      const password = fieldsUser.password.trim();
      const passwordRegex = /^.{6,}$/; // Minimum 6 characters

      if (!passwordRegex.test(password)) {
        formIsValid = false;
        errors.password = "Password should be at least 6 characters long.";
      }
    }

    if (
      !fieldsUser.confirmPassword ||
      fieldsUser.confirmPassword.trim() === ""
    ) {
      formIsValid = false;
      errors.confirmPassword = DashboardJSON.ErrorMsg.confirmPassword;
    }
    if (fieldsUser.confirmPassword !== fieldsUser.password) {
      formIsValid = false;
      errors.confirmPassword = "Password Does not Match";
    }

    setErrorsUpdate(errors);
    return formIsValid;
  };

  const disableUser = (data) => {
    let userIdReq = {
      userId: data._id,
    };
    let userPageRefresh = {
      keyWord: "",
      pageNo: pageNo,
      sortBy: "slug",
      sortOrder: "asc",
      fromDate: "",
      toDate: "",
      size: size,
      roleId: 1,
    };
    confirmAlert({
      title: `${data?.isDisable ? "Confirm to UnBlock User?" : "Confirm to Block User?"
        }`,
      message: `Are you sure you want to ${data?.isDisable ? "unblock" : "block"
        } ${data.userName}?`,

      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(userActions.updateUserStatus(userIdReq, userPageRefresh)),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const handleValidationCreateGame = () => {
    let formIsValid = true;
    let errors = {};

    if (!rowData.amount || rowData.amount === "") {
      formIsValid = false;
      errors.amount = "Enter Amount";
    }

    setErrorsGame(errors);
    return formIsValid;
  };

  const handleChange = (e) => {
    setrowData({ ...rowData, [e.target.name]: e.target.value });
    setErrorsGame({ ...rowData, [e.target.name]: "" });
  };
  const handleEditUser = (url, element) => {
    navigate(url + element._id);
  };
  const handleFund = (e) => {
    e.preventDefault();

    if (handleValidationCreateGame()) {
      let obj = {
        userId: rowData?.userId,
        amount: rowData?.amount || 0,
      };
      if (rowData?.key === "Credit Reference") {
        dispatch(walletActions.creditReferenceAdd(obj));
      } else if (rowData?.key === "Withdraw Fund") {
        dispatch(walletActions.withdrawBalanceByAdmin(obj));
      } else {
        dispatch(walletActions.depositeBalanceByAdmin(obj));
      }

      setrowData({});
      setviewModal(false);
    }
  };

  const handleFile = async (e) => {
    dispatch(userActions.uploadImage(e));
  };


  const deleteUserByIdHandelClick = (id) => {
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to Delete Bid',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            userActions.deleteUserById({ id }).then((res) => {
              if (res?.data) {
                let myBetReq = {
                  keyWord: "",
                  pageNo: pageNo,
                  sortBy: "createdAt",
                  sortOrder: "desc",
                  fromDate: "",
                  toDate: "",
                  size: size,
                  roleId: 1,
                };
                dispatch(userActions.getUserListNewLean(myBetReq));
              }
            })
          }
        },
        {
          label: 'No'
        }
      ]
    })
  }


  const handlePageClick3 = (data) => {

    let offset = Math.ceil(data.selected * size);
    setCurrentPage3(data.selected)
    setOffset(offset);
    setPageNo(data.selected + 1);
  }



  let { users } = selector ? selector : {};
  let { loading, adminProfileDetails, userListNewLean } = users ? users : {};
  let { list, total } = userListNewLean ? userListNewLean : {};
  let [localStoragedata, setlocalStoragedata] = useState();
  useEffect(() => {
    const stateData = window.sessionStorage.getItem("adminuser");
    setlocalStoragedata(JSON.parse(stateData));
  }, []);
  let { balance } = adminProfileDetails ? adminProfileDetails : {};

  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3  sm:p-5">
                    <div className="mx-auto max-w-screen-3xl">

                      <div className="bg-white rounded-xl border shadow">
                        <div className="flex justify-between items-center px-3 py-2 border-b">
                          <div className="flex gap-2 justify-between w-full items-end">
                            <div>
                              {localStoragedata &&
                                localStoragedata?.roleId === 1 && (
                                  <span className="text-xl textcolor font-semibold whitespace-nowrap ">
                                    SUPER MASTER
                                  </span>
                                )}
                              {localStoragedata &&
                                localStoragedata?.roleId === 2 && (
                                  <span className="text-xl textcolor font-semibold whitespace-nowrap ">
                                    MASTER
                                  </span>
                                )}
                              {localStoragedata &&
                                localStoragedata?.roleId === 3 && (
                                  <span className="text-xl textcolor font-semibold whitespace-nowrap ">
                                    AGENT
                                  </span>
                                )}
                              {localStoragedata &&
                                localStoragedata?.roleId === 4 && (
                                  <span className="text-xl textcolor font-semibold whitespace-nowrap ">
                                    OPERATOR
                                  </span>
                                )}
                              {localStoragedata &&
                                localStoragedata?.roleId === 20 && (
                                  <span className="text-xl textcolor font-semibold whitespace-nowrap ">
                                    CLIENT
                                  </span>
                                )}
                            </div>

                            <div className="flex gap-2 w-full justify-end items-center">
                              <div className="shadow-sm">
                                <button
                                  className="px-4 py-1 tracking-wider text-blue-100 bgcolor rounded-md hover:shadow-lg hover:bg-red-600 uppercase"
                                  onClick={() => handleOpenModal()}
                                >
                                  {localStoragedata &&
                                    localStoragedata?.roleId === 0 && (
                                      <span className=" font-semibold whitespace-nowrap ">
                                        Add ADMIN
                                      </span>
                                    )}
                                  {localStoragedata &&
                                    localStoragedata?.roleId === 1 && (
                                      <span className=" font-semibold whitespace-nowrap ">
                                        Add SUPER MASTER
                                      </span>
                                    )}
                                  {localStoragedata &&
                                    localStoragedata?.roleId === 2 && (
                                      <span className=" font-semibold whitespace-nowrap ">
                                        Add MASTER
                                      </span>
                                    )}
                                  {localStoragedata &&
                                    localStoragedata?.roleId === 3 && (
                                      <span className=" font-semibold whitespace-nowrap ">
                                        Add AGENT
                                      </span>
                                    )}
                                  {localStoragedata &&
                                    localStoragedata?.roleId === 4 && (
                                      <span className=" font-semibold whitespace-nowrap ">
                                        Add OPERATOR
                                      </span>
                                    )}
                                  {localStoragedata &&
                                    localStoragedata?.roleId === 20 && (
                                      <span className=" font-semibold whitespace-nowrap ">
                                        Add CLIENT
                                      </span>
                                    )}
                                </button>
                              </div>
                              <div>
                                {localStoragedata &&
                                  localStoragedata?.roleId !== 0 ? (
                                  <span>
                                    <span className=" flex  justify-center items-center bg-[#992941] text-white p-2 rounded">
                                      <IoWalletOutline className=" text-2xl" />
                                      {(balance && balance) || "0"}
                                    </span>
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="relative overflow-x-auto p-3">
                          <table className="w-full text-sm text-left rtl:text-right text-slate-700">
                            <thead className="text-gray-900 capitalize bgcolortb">
                              <tr>
                                <th className="px-2 py-2">#</th>
                                {localStoragedata &&
                                  localStoragedata?.roleId === 20 ? (
                                  <th className="px-2 py-2">Username</th>
                                ) : (
                                  <th className="px-2 py-2">
                                    Username
                                  </th>
                                )}
                                <th className="px-2 py-2 ">Balance</th>
                                <th className="px-2 py-2 ">Share</th>
                                <th className="px-2 py-2 ">Comm</th>
                                <th className="px-2 py-2 ">k Share</th>
                                <th className="px-2 py-2 ">K Comm</th>


                                <th className="px-2 py-2 text-center">
                                  Status
                                </th>
                                <th className="px-2 py-2 text-center">Edit</th>
                              </tr>
                            </thead>
                            <tbody>
                              {list && list.length > 0 ? (
                                list.map((element, index) => (
                                  <React.Fragment key={index}>
                                    <tr className="bg-white border-b border-gray-400 font-semibold">
                                      <th className="px-2 py-3 text-slate-700 whitespace-nowrap">{offset + index + 1}</th>

                                      <th className="px-2 py-3 text-slate-700 whitespace-nowrap capitalize">
                                        {localStoragedata &&
                                          localStoragedata?.roleId === 20 ? (
                                          <div className="flex">
                                            {element?.userName || "-"}
                                          </div>
                                        ) : (
                                          <div className="flex">
                                            {element?.userName || "-"}{" "}
                                          </div>
                                        )}
                                      </th>

                                      <td className="px-2 py-3 flex justify-start items-center gap-2">

                                        <TfiWallet className="w-7 h-7 p-1 text-red-500" />
                                        {element?.walletData?.map(e => e?.balance) || '-'}
                                      </td>
                                      <td className="px-2 py-3 ">

                                        {element?.share || '0'}
                                      </td>
                                      <td className="px-2 py-3 ">

                                        {element?.comm || '0'}
                                      </td>
                                      <td className="px-2 py-3 ">

                                        {element?.karachiShare || '0'}
                                      </td><td className="px-2 py-3 ">

                                        {element?.karachiComm || '0'}
                                      </td>


                                      <td className="px-2 py-3">
                                        <div className="flex justify-center items-center">
                                          <button
                                            className={
                                              element.isDisable === false
                                                ? "px-4 py-0.5 border font-bold border-green-700  bg-green-700 text-white rounded "
                                                : "px-4 py-0.5 border font-bold border-red-700 text-white bg-red-700 rounded"
                                            }
                                            onClick={() => disableUser(element)}
                                          >
                                            {element.isDisable === false
                                              ? "Active"
                                              : "Inactive"}
                                          </button>
                                        </div>
                                      </td>

                                      <th className="px-2 py-3 ">
                                        <div className="flex items-center justify-center gap-2">
                                          <div className="flex justify-center items-center">
                                            <FiEdit onClick={() => handleEditUser("/app/EditUser/", element)} title="Edit" className="w-5 h-5 cursor-pointer" />
                                          </div>
                                          <div className="flex justify-center items-center text-lg text-red-600 hover:text-red-900">
                                            <MdDelete onClick={() => deleteUserByIdHandelClick(element._id)} title="Deleted" className="w-5 h-5 cursor-pointer" />
                                          </div>
                                        </div>
                                      </th>
                                    </tr>
                                  </React.Fragment>
                                ))
                              ) : (
                                <td colSpan={12} className="text-center py-4">
                                  No Data Found
                                </td>
                              )}
                            </tbody>
                          </table>
                        </div>

                        {
                          total && total > 10 ? (
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={Math.ceil(total / 10)}
                              marginPagesDisplayed={0}
                              pageRangeDisplayed={10}
                              onPageChange={handlePageClick3}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                              forcePage={currentPage3}
                            />
                          ) : null
                        }

                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateUserModal
        userOpenModal={userOpenModal}
        handleHideModal={handleHideModal}
        inputChange={inputChange}
        fieldsUser={fieldsUser}
        errorsUser={errorsUser}
        createUserSubmit={createUserSubmit}
        handleFile={handleFile}
        addRef={addRef}
      />

      <ViewUserModal
        viewModal={viewModal}
        rowData={rowData}
        handleViewHideModal={handleViewHideModal}
        handleFund={handleFund}
        errorsGame={errorsGame}
        handleChange={handleChange}
      />
      <ModelTest isOpen3={isOpen3} onClose3={onClose3} />
    </>
  );
};

export default SubAdmin;
