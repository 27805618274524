/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { gamesActions } from '../../_actions'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import moment from 'moment'
import { useTime } from 'react-timer-hook'
const totalAmount = 0
const Demo = () => {
  const [bet, setBet] = useState([])
  const [dropdownValue, setDropdownValue] = useState('Lahor') // Default selected game
  const [betNumber, setBetNumber] = useState('')
  const [amount, setAmount] = useState('')
  const { gamesList } = useSelector(state => state.games)
  const ActiveGameData =
    gamesList && gamesList.filter(item => item.status === 'ACTIVE')
  const dispatch = useDispatch()
  let [localStoragedata, setlocalStoragedata] = useState()
  const fieldsGame = {}
  const errorsGame = {}
  const gameName = {}
  const [userName, setUserName] = useState('')
  const { subAdminData } = useSelector(state => state.games)
  const scrollContainerRef = useRef(null)

  useEffect(() => {

    const stateData = window.sessionStorage.getItem('adminuser')
    setlocalStoragedata(JSON.parse(stateData))
    dispatch(gamesActions.getAllMarket())
    dispatch(gamesActions.getAllGames())
    const stateManage = JSON.parse(stateData)
    if (stateManage?.roleId === 0) {
      dispatch(gamesActions.getSubAdminList())
    }
  }, [dispatch])

  const inputChangeCreate = e => {
    e.preventDefault()
    const value = e.target.value
    const user = subAdminData?.find(ele => ele?._id === value).userName
    setUserName(user)
  }

  const handleDelete = data => {
    const res = {
      gameId: gameName?._id,
      userId: fieldsGame?.userId
    }
    dispatch(gamesActions.placeBetByFreeze({ id: data._id }, res))
  }
  const { seconds, minutes, hours, ampm } = useTime({ format: '12-hour' })

  const betNumberRef = useRef(null)
  const amountRef = useRef(null)
  const handleAddBet = () => {
    if (!amount || !betNumber) {
      alert('Please fill all fields')
      return
    }

    const newBet = {
      id: bet.length + 1,
      game: dropdownValue,
      betNumber,
      amount,
      dropdownValue
    }

    setBet(prevBets => [...prevBets, newBet])
    setBetNumber('')
    setAmount('')
    betNumberRef.current.focus()
  }

  const Dropdowndata = [
    { label: 'Single Open', value: 'Single Open' },
    { label: 'Single Close', value: 'Single Close' },
    { label: 'Jodi', value: 'Jodi' },
    { label: 'Single Kenchi', value: 'Single Kenchi' },
    { label: 'Double Kenchi', value: 'Double Kenchi' }
  ]

  const maxLength =
    dropdownValue === 'Single Open' || dropdownValue === 'Single Close'
      ? 1
      : dropdownValue === 'Jodi'
        ? 2
        : dropdownValue === 'Single Kenchi' || dropdownValue === 'Double Kenchi'
          ? 7
          : 0

  const minLength =
    dropdownValue === 'Single Open' || dropdownValue === 'Single Close'
      ? 1
      : dropdownValue === 'Jodi'
        ? 2
        : dropdownValue === 'Single Kenchi' || dropdownValue === 'Double Kenchi'
          ? 3
          : 0

  return (
    <div className='w-full bg-white h-screen overflow-hidden overflow-y-auto'>
      <div className='relative'>
        <div className='justify-between flex flex-col bg-white rounded-md border'>
          {localStoragedata && localStoragedata.roleId === 0 && (
            <div className='text-left bg-white space-y-2 rounded-md w-full p-4'>
              <div className='flex justify-between md:flex-nowrap gap-1 flex-wrap items-end w-full'>
                <div className='w-full'>
                  <label
                    className='block text-gray-700 text-base mb-1'
                    htmlFor='userId'
                  >
                    User Name :
                  </label>
                  <select
                    className='w-56 px-3 py-1.5 text-base bg-white border border-gray-400 rounded-md focus:outline-none'
                    id='userId'
                    name='userId'
                    value={fieldsGame?.userId || ''}
                    onChange={e => inputChangeCreate(e)}
                  >
                    <option value=''>Select User Name</option>
                    {subAdminData && subAdminData.length > 0
                      ? subAdminData.map((element, index) => (
                        <option key={index} value={element?._id || ''}>
                          {element?.userName || ''}
                        </option>
                      ))
                      : null}
                  </select>
                  {errorsGame?.userId && (
                    <div className='text-red-600 invalid-feedback'>
                      {errorsGame['userId']}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className='bg-gray-50 w-full px-4 pb-8'>
            <div className='text-black capitalize text-xl w-full flex justify-between px-4 pt-3 pb-2'>
              <p>Bet Table</p>
              <p className='text-base'>
                Game Name-:{' '}
                <span className='text-xl font-semibold'>
                  {ActiveGameData && ActiveGameData[0].name}
                </span>
              </p>
              <p className='text-base'>
                User Name-:{' '}
                <span className='text-xl font-semibold'>{userName}</span>
              </p>
              <p className='text-base'>
                Total Amount-:{' '}
                <span className='text-xl font-semibold'>{totalAmount}</span>
              </p>
              <p className='text-base flex justify-between items-center space-x-2'>
                Timer-:
                <span className='text-2xl font-semibold bg-black/20 px-3 py-1 rounded-md'>
                  {hours}
                </span>
                <span className='text-2xl font-semibold bg-black/20 px-3 py-1 rounded-md'>
                  {minutes}
                </span>
                <span className='text-2xl font-semibold bg-black/20 px-3 py-1 rounded-md'>
                  {seconds}
                </span>
                <span className='text-2xl font-semibold bg-black/20 px-3 py-1 rounded-md uppercase space-x-2'>
                  {ampm}
                </span>
              </p>
            </div>

            <div
              className='h-[33rem] overflow-x-auto overflow-y-auto'
              ref={scrollContainerRef}
            >
              <table className='min-w-full border border-gray-200'>
                <thead className='sticky top-0 bg-gray-200 z-10'>
                  <tr className='text-black font-semibold text-base'>
                    <th className='p-2 uppercase tracking-wide border border-gray-300'>
                      #
                    </th>
                    {localStoragedata && localStoragedata?.roleId === 0 && (
                      <>
                        <th className='p-2 uppercase tracking-wide border border-gray-300'>
                          User
                        </th>
                        <th className='p-2 uppercase tracking-wide border border-gray-300'>
                          Game
                        </th>
                      </>
                    )}
                    <th className='p-2 uppercase tracking-wide border border-gray-300'>
                      Number
                    </th>
                    <th className='p-2 uppercase tracking-wide border border-gray-300'>
                      Amount
                    </th>
                    <th className='p-2 uppercase tracking-wide border border-gray-300'>
                      Market
                    </th>
                    <th className='p-2 uppercase tracking-wide border border-gray-300'>
                      Packet
                    </th>
                    <th className='p-2 uppercase tracking-wide border border-gray-300'>
                      Date/Time
                    </th>
                    <th className='p-2 uppercase tracking-wide border border-gray-300'>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {bet.map((item, index) => (
                    <tr
                      key={index}
                      className='border text-2xl last:border-none last:bg-green-300 bg-white text-gray-700'
                    >
                      <td className='p-2 border border-gray-200 text-center'>
                        {index + 1}
                      </td>
                      {localStoragedata && localStoragedata?.roleId === 0 && (
                        <>
                          <td className='p-2 border border-gray-200 text-center'>
                            {userName}
                          </td>
                          <td className='p-2 font-medium border border-gray-200 text-center'>
                            {ActiveGameData && ActiveGameData[0].name}
                          </td>
                        </>
                      )}

                      <td className='p-2 text-red-600 border bordered-500 text-center font-bold'>
                        {item.betNumber}
                      </td>
                      <td className='p-2 text-red-600 border bordered-500 text-center font-bold'>
                        {['Single Open', 'Single Close'].includes(
                          item?.localmarketname
                        )
                          ? item?.amount * 10
                          : item?.amount}
                      </td>

                      <td className='p-2 border border-gray-200 text-center'>
                        {item?.dropdownValue}
                      </td>
                      <td className='p-2 border border-gray-200 text-center'>
                        10
                      </td>
                      <td className='p-2 border border-gray-200 text-center'>
                        {moment(item?.createdAt).format('DD-MM-YYYY HH:mm')}
                      </td>
                      <td className='p-2 text-center'>
                        <button
                          onClick={() => handleDelete(item)}
                          className='text-red-500 hover:text-red-600 font-semibold text-base'
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Input Section */}
          <div className='flex space-x-4 items-center bg-red-500 p-4 fixed bottom-0 w-full'>
            <select
              className='p-2 bg-white rounded-lg shadow-md'
              value={dropdownValue}
              onChange={e => setDropdownValue(e.target.value)}
            >
              {Dropdowndata.map(item => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>

            <input
              type='text'
              placeholder='Bet Number'
              maxLength={maxLength}
              minLength={minLength}
              // value={isEditingBetNumber ? betNumber : ''}
              value={betNumber}
              // onFocus={() => handleFocus('betNumber')}
              // onBlur={() => handleBlur('betNumber')}
              onChange={e => setBetNumber(e.target.value)}
              ref={betNumberRef}
              className='p-2 bg-white rounded-lg shadow-md'
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  amountRef.current.focus() // Move to Amount input
                }
              }}
            />

            <input
              type='text'
              placeholder='Amount'
              onChange={e => setAmount(e.target.value)}
              ref={amountRef}
              className=' p-2 bg-white rounded-lg shadow-md'
              onClick={e => {
                if (e.key === 'Enter') {
                  handleAddBet()
                }
              }}
            />

            <button
              onClick={handleAddBet}
              className='bg-blue-600 text-white font-bold py-2 px-4 rounded-lg shadow-md'
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Demo
