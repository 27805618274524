/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { karachiGamesActions, gamesActions, karachiMarketActions, userActions } from '../../_actions'
import { useParams } from 'react-router-dom'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import moment from 'moment'
import { useTime } from 'react-timer-hook'
import { FaDice } from 'react-icons/fa6'
import { FiDollarSign } from 'react-icons/fi'
import { FaRegUser } from "react-icons/fa6";
import { IoGameControllerOutline } from 'react-icons/io5'
import { CiCoinInsert } from "react-icons/ci";
import { LuAlarmClock } from 'react-icons/lu'
import { LiaHandPointRight } from "react-icons/lia";
import { getCustomTimestamp } from '../../_helpers'

const KarachiPlaceBet = () => {
  const dispatch = useDispatch()
  const { games, karachiGame, karachiMarket } = useSelector(state => state) || {}
  const { karachiGamesList, karachiPlaceBet, loading } = karachiGame || {}
  const { allKarachiMarket } = karachiMarket || {}

  const { id, name } = useParams()

  let [localStoragedata, setlocalStoragedata] = useState()
  let [isDisable, setIsDisable] = useState(false)
  const [fieldsGame, setFieldsGame] = useState({})
  const [errorsGame, setErrorsGame] = useState({})
  const [userName, setUserName] = useState({})
  const [gameName, setGameName] = useState({})
  const { subAdminData } = useSelector(state => state.games)
  const [isBetPrime, setIsBetPrime] = useState(false)
  const [gameNameRes, setGameNameRes] = useState(null)
  const [stateKey, setStateKey] = useState(null)
  let [options, setoptions] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)

  const [savedAmount, setSavedAmount] = useState('') // Value saved from the API call
  const [amount, setAmount] = useState('')
  const [isAmountBlank, setIsAmountBlank] = useState(false)

  const [savedBetNumber, setSavedBetNumber] = useState('') // Value saved from the API call
  const [betNumber, setBetNumber] = useState('')
  const [isBetNumberBlank, setIsBetNumberBlank] = useState(false)



  const handleKeyDown1 = event => {
    if (event.key.toLowerCase() === 'l') {
      setIsBetPrime(prevState => !prevState) // Toggle the status
    }
  }


  const [backendTimeStamp, setBackendTimeStamp] = useState(Date.now())

  useEffect(() => {
    const fetchGlobalDate = async () => {
      try {
        const res = await userActions.getGlobalDate();
        if (res?.data) {
          const stamp = getCustomTimestamp(Number(res.data.timeStamp), Date.now())
          setBackendTimeStamp(() => stamp);
        }
      } catch (error) {
        console.error("Error fetching global date:", error);
        // Optionally set a fallback or error state here
      }
    };

    fetchGlobalDate();
  }, []);

  const scrollContainerRef = useRef(null)

  useEffect(() => {
    return () => {
      dispatch(karachiGamesActions.getPlaceBetsByUserIdLocalCall([])) // Example cleanup action
    }
  }, [dispatch])

  useEffect(() => {
    if (scrollContainerRef.current) {
      setTimeout(() => {
        scrollContainerRef.current.scrollTop =
          scrollContainerRef.current.scrollHeight
      }, 100)
    }
    const totalAmountRes =
      karachiPlaceBet && karachiPlaceBet.length > 0
        ? karachiPlaceBet.reduce((sum, record) => {
          if (
            ['Single Open', 'Single Close'].includes(record?.localmarketname)
          ) {
            return sum + Number(record.amount) * 10 || 0
          }
          if (
            ['Single Kenchi', 'Double Kenchi'].includes(
              record?.localmarketname
            )
          ) {
            return sum + Number(record.amount) * record?.packet?.length || 0
          }
          return sum + Number(record.amount) || 0
        }, 0)
        : 0
    setTotalAmount(totalAmountRes)
  }, [karachiPlaceBet])

  const [enterPressCount, setEnterPressCount] = useState(0) // Track Enter presses
  const betNumberInputRef = useRef(null)
  const amountInputRef = useRef(null) // Reference for the amount input

  const handleKeyDown = async e => {
    if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
      e.preventDefault()
      return
    }
    if (e.key === 'Enter') {
      const { name } = e.target
      if (!e.target.value && e.target.name === 'betNumber') {
        setErrors(prev => {
          return {
            ...prev,
            betNumber: 'Bet Number is Required'
          }
        })
        return
      }

      if (name === 'betNumber') {
        if (
          currentOption.toShow === 'Open' &&
          String(e.target.value).length === 2
        ) {
          setCurrentIndex(() => 7)
        } else if (
          currentOption.toShow === 'Open' &&
          String(e.target.value).length === 1
        ) {
          setCurrentIndex(() => 0)
        }
      }
      let error = validate(e.target.name, e.target.value.toString())
      if (name === 'betNumber' && error) {
        setErrors(prev => {
          return {
            ...prev,
            betNumber: error
          }
        })
      }
      if (name === 'amount' && error) {
        setErrors(prev => {
          return {
            ...prev,
            amount: error
          }
        })
        return
      }
      if (error) {
        return
      }

      if (['F9'].includes(stateKey) && enterPressCount === 0 && name === 'betNumber') {
        amountInputRef.current?.focus()
        setEnterPressCount(prevCount => prevCount + 1)
      }

      if (['F9'].includes(stateKey) && enterPressCount === 1) {
        await handleSubmit()
        setEnterPressCount(0)
        betNumberInputRef.current?.focus()
        return
      }

      if (!['F9'].includes(stateKey) && enterPressCount === 0 && name === 'betNumber') {
        amountInputRef.current?.focus()
        setEnterPressCount(prevCount => prevCount + 1)
      }
      if (enterPressCount === 1) {
        const amount = amountInputRef.current.value.trim()
        if (amount === '') {
          return
        }
        setIsAmountBlank(true)
        setSavedAmount(amount)
        setAmount(e.target.value)
        const betNumber = betNumberInputRef.current.value.trim()
        if (betNumber === '') {
          return
        }
        setIsBetNumberBlank(true)
        setSavedBetNumber(betNumber)
        await handleSubmit()
        setEnterPressCount(0)
        betNumberInputRef.current?.focus()
        return
      }
    }
  }
  const handleSubmit = async e => {
    try {
      if (amount < 5 || !betNumber) {
        return
      }

      let packet = []
      let isOpen = false
      let marketId = currentOption
      if (['F9'].includes(stateKey) && betNumber.toString() === '11') {
        const resData = [
          {
            ...formData,
            isOpen: isOpen,
            marketId,
            games: currentOption2,
            localmarketname: 'First Akda',
            isBetPrime: isBetPrime
          }
        ]
        const gameId = fieldsGame && gameName?._id ? gameName?._id : id
        const res = {
          gameId: gameId,
          userId: fieldsGame && fieldsGame['userId'] ? fieldsGame['userId'] : null
        }
        const JodiRes = [
          '00',
          '11',
          '22',
          '33',
          '44',
          '55',
          '66',
          '77',
          '88',
          '99'
        ]
        resData.forEach(games => {
          const obj = {
            userId: fieldsGame?.userId || null,
            amount: amount,
            marketId: games?.marketId?._id,
            gameId: gameId,
            isBetPrime: games?.isBetPrime,
            isOpen: games?.isOpen,
            localmarketname: 'First Akda',
            packet: JodiRes
          }
          dispatch(
            karachiGamesActions.karachiPlaceBetByAdminSequentially(obj, res)
          )
        })
        setStateKey(null)
        setBetNumber('')
        setIsBetPrime(false)
        setEnterPressCount(0)
        setErrors({})
        setCurrentIndex(0)
        setFormData(prevData => ({
          ...prevData,
          betNumber: ''
        }))
      }
      if (
        marketId &&
        currentOption2 &&
        currentOption &&
        betNumber &&
        amount
      ) {
        const marketData = ['Open', 'First Akda'].includes(marketId?.toShow)
          ? betNumber?.length === 1
            ? 'Open'
            : betNumber?.length === 2
              ? 'First Akda'
              : marketId?.toShow
          : marketId?.toShow
        if (
          ['Close', 'Open', 'Center'].includes(marketData) &&
          betNumber?.length > 1
        ) {
          return
        }
        if (
          ['First TND', 'Second TND', 'FS Ring'].includes(marketData) &&
          betNumber?.length > 3
        ) {
          return
        }
        if (['Second Akda'].includes(marketData) && betNumber?.length !== 2) {
          return
        }
        if (
          ['Single Kenchi', 'Double Kenchi'].includes(marketData) &&
          (betNumber?.length < 3 || betNumber?.length > 7)
        ) {
          return
        }
        switch (marketData) {
          case 'Open':
            packet = [betNumber]
            break
          case 'Close':
            packet = [betNumber]
            break
          case 'Center':
            packet = [betNumber]
            break
          case 'First TND':
            packet = [betNumber]
            break
          case 'Second TND':
            packet = [betNumber]
            break
          case 'First Akda':
            packet = [betNumber]
            break
          case 'Second Akda':
            packet = [betNumber]
            break
          case 'FS Ring':
            packet = [betNumber]
            break
          case 'Single Kenchi':
            packet = getUniqueTwoDigitCombinations(betNumber)
            break
          case 'Double Kenchi':
            packet = getTwoDigitCombinations(betNumber)
            break
          default:
            break
        }

        const resData = {
          betNumber: betNumber,
          packet,
          isOpen: isOpen,
          marketId,
          games: currentOption2,
          localmarketname: marketData,
          isBetPrime: isBetPrime
        }
        const gameId = fieldsGame && gameName?._id ? gameName?._id : id
        const res = {
          gameId: gameId,
          userId:
            fieldsGame && fieldsGame['userId'] ? fieldsGame['userId'] : null
        }

        resData['amount'] = amount
        resData['gameId'] = gameId
        resData['userId'] =
          fieldsGame && fieldsGame['userId'] ? fieldsGame['userId'] : null
        const userData = subAdminData.find(
          ele => ele._id === fieldsGame['userId']
        )

        dispatch(
          karachiGamesActions.karachiPlaceBetByAdmin(
            { ...resData, userData },
            res,
            karachiPlaceBet
          )
        )

        if (!['FS Ring', 'First TND', 'Second TND', 'Second Akda'].includes(marketData)) {
          setCurrentIndex(0)
          setStateKey(null)
        }
        setIsBetPrime(false)
        setEnterPressCount(0)
        setBetNumber('')
        setErrors({})
        setFormData(prevData => ({
          ...prevData,
          betNumber: ''
        }))
      }
    } catch (err) {
      console.error('Error in handleSubmit:', err)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown1)
    return () => {
      document.removeEventListener('keydown', handleKeyDown1)
    }
  }, [])

  useEffect(() => {
    if (!isDisable) {
      window.addEventListener('keydown', handleKeyDown2)
      return () => {
        window.removeEventListener('keydown', handleKeyDown2)
      }
    }
  }, [options, isDisable])

  useEffect(() => {
    if (!isDisable) {
      // Add the event listener
      window.addEventListener('keydown', handleKeyDown3)

      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('keydown', handleKeyDown3)
      }
    }
  }, [isDisable])

  const [formData, setFormData] = useState({})
  const [errors, setErrors] = useState({})
  const [options2, setoptions2] = useState([])
  let currentOption2 =
    Array.isArray(options2) && options2.length > 0 ? options2[0] : null

  const [currentIndex, setCurrentIndex] = useState(0)
  let currentOption =
    Array.isArray(options) && options.length > 0 ? options[currentIndex] : null

  useEffect(() => {
    if (name) {
      setGameNameRes(name)
    }
    const stateData = window.sessionStorage.getItem('adminuser')
    if (stateData) {
      setFieldsGame((pre) => ({ ...pre, userId: stateData?.userId }))
    }
    setlocalStoragedata(JSON.parse(stateData))
    dispatch(karachiMarketActions.getAllKarachiMarket())
    dispatch(karachiGamesActions.getAllKarachiGames())
    const stateManage = JSON.parse(stateData)
    if (stateManage?.roleId === 0) {
      setIsDisable(true)
    }
    dispatch(gamesActions.getSubAdminList())
  }, [dispatch])

  useEffect(() => {
    if (fieldsGame?.userId && gameName?._id) {
      dispatch(
        karachiGamesActions.getKarachiPlaceBetsByUserId({
          gameId: gameName?._id,
          userId: fieldsGame.userId
        })
      )
    }

  }, [fieldsGame])

  // Single Kachi: Unique Two-Digit Combinations
  function getUniqueTwoDigitCombinations(number) {
    const digits = [...new Set(number?.toString().split(''))]
    const combinations = []

    for (let i = 0; i < digits.length; i++) {
      for (let j = 0; j < digits.length; j++) {
        if (i !== j) {
          combinations.push(digits[i] + digits[j])
        }
      }
    }
    return combinations
  }

  function getTwoDigitCombinations(number) {
    const digits = [...new Set(number?.toString().split(''))]
    const combinations = []

    for (let i = 0; i < digits.length; i++) {
      for (let j = 0; j < digits.length; j++) {
        combinations.push(digits[i] + digits[j])
      }
    }
    return combinations
  }

  const handleChange = e => {
    e.preventDefault()
    const { name, value } = e.target

    if (name === 'betNumber') {
      if (
        currentOption.toShow === 'Open' &&
        e.target.value.toString().length === 2
      ) {
        setCurrentIndex(() => 7)
      } else if (
        currentOption.toShow === 'Open' &&
        e.target.value.toString().length === 1
      ) {
        setCurrentIndex(() => 0)
      }
    }

    const error = validate(e.target.name, e.target.value)

    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: error
    }))

    // Common logic for updating form data and errors
    const updateFormDataAndErrors = (key, val, err) => {
      setFormData(prev => ({
        ...prev,
        [key]: val
      }))
    }

    if (name === 'betNumber') {
      if (savedBetNumber === '') {
        setBetNumber(value)
        setIsBetNumberBlank(false)
      } else if (value !== savedBetNumber && isBetNumberBlank) {
        updateFormDataAndErrors(name, value, error)
        setBetNumber(e.target.value)
        setIsBetNumberBlank(false)
      } else {
        setBetNumber(value)
      }

      if (error) {
        return
      } else {
        setEnterPressCount(0)
      }
    }

    if (name === 'amount') {
      if (savedAmount === '') {
        // Initial amount logic
        setAmount(value)
        setIsAmountBlank(false)
      } else if (value !== savedAmount && isAmountBlank) {
        updateFormDataAndErrors(name, value, error)
        const lastCharacter = value[value.length - 1] || ''
        amountInputRef.current.value = lastCharacter
        setAmount(e.target.value)
        setIsAmountBlank(false)
      } else {
        setAmount(value)
      }
      if (error) {
        return
      }
      setEnterPressCount(1) // Set enter press count for `amount`
    }
  }

  const validate = (name, value) => {
    let error = ''

    if (name === 'amount') {
      if (value < 5) {
        error = 'Amount must be greater than 5.'
      }
    } else if (name === 'betNumber') {
      let indexChange = currentIndex

      if (currentOption.toShow === 'Open' && value.toString().length === 2) {
        setCurrentIndex(() => 7)
        indexChange = 7
      } else if (
        currentOption.toShow === 'Open' &&
        value.toString().length === 1
      ) {
        setCurrentIndex(() => 0)
        indexChange = 0
      }
      const marketData =
        Array.isArray(options) && options.length > 0
          ? options[indexChange]
          : null
      error = ErrorResponseFunction(
        marketData?.toShow || null,
        value.toString()
      )
    }

    return error
  }

  function ErrorResponseFunction(marketData, value = '') {
    let error

    if (!marketData) {
      error = 'Market Data is required'
      return error
    }

    if (['Close', 'Center', 'Open'].includes(marketData)) {
      if (value.length !== 1) {
        error = 'Only a single digit is allowed for Close, Center, or Open.'
      }
    } else if (['Single Kenchi', 'Double Kenchi'].includes(marketData)) {
      if (value.length < 3 || value.length > 7) {
        error =
          'Please enter between 3 to 7 unique digits for Single Kenchi or Double Kenchi.'
      } else if (new Set(value).size !== value.length) {
        error =
          'Duplicate digits are not allowed for Single Kenchi or Double Kenchi.'
      }
    } else if (['Second Akda'].includes(marketData)) {
      if (value.length !== 2) {
        error = 'Second Akda requires exactly 2 digits.'
      }
    } else if (['First TND', 'Second TND', 'FS Ring'].includes(marketData)) {
      if (value.length !== 3) {
        error = 'First TND, Second TND, and FS Ring require exactly 3 digits.'
      }
    } else if (marketData === 'First Akda') {
      if (value.length !== 2) {
        error = 'First Akda requires exactly 2 digits.'
      }
    }

    return error
  }
  const handleDelete = (data) => {
    const res = {
      gameId: gameName?._id ?? null,
      userId: fieldsGame?.userId ?? null
    }

    dispatch(karachiGamesActions.karachiPlaceBetByFreeze({ id: data._id }, res))
  }

  let isOpen =
    allKarachiMarket &&
    allKarachiMarket?.length > 0 &&
    allKarachiMarket.find(e => e?.name === 'Open')
  let isClose =
    allKarachiMarket &&
    allKarachiMarket?.length > 0 &&
    allKarachiMarket.find(e => e?.name === 'Close')
  let isCenter =
    allKarachiMarket &&
    allKarachiMarket?.length > 0 &&
    allKarachiMarket.find(e => e?.name === 'Center')
  let isSingleKahchee =
    allKarachiMarket &&
    allKarachiMarket?.length > 0 &&
    allKarachiMarket.find(e => e?.name === 'Single Kenchi')
  let isDoubalKahchee =
    allKarachiMarket &&
    allKarachiMarket?.length > 0 &&
    allKarachiMarket.find(e => e?.name === 'Double Kenchi')
  let isFirstAkda =
    allKarachiMarket &&
    allKarachiMarket?.length > 0 &&
    allKarachiMarket.find(e => e?.name === 'First Akda')
  let isSecondAkda =
    allKarachiMarket &&
    allKarachiMarket?.length > 0 &&
    allKarachiMarket.find(e => e?.name === 'Second Akda')
  let isFirstTND =
    allKarachiMarket &&
    allKarachiMarket?.length > 0 &&
    allKarachiMarket.find(e => e?.name === 'First TND')
  let isSecondTND =
    allKarachiMarket &&
    allKarachiMarket?.length > 0 &&
    allKarachiMarket.find(e => e?.name === 'Second TND')
  let isFSRing =
    allKarachiMarket &&
    allKarachiMarket?.length > 0 &&
    allKarachiMarket.find(e => e?.name === 'FS Ring')

  useEffect(() => {
    const currentTime =
      moment().hours() * 3600 + moment().minutes() * 60 + moment().seconds()
    const availableGames = karachiGamesList?.filter(
      game =>
        currentTime >= game?.openTime &&
        currentTime <= game?.closingTime &&
        !game.isGameClose
    )

    if (availableGames?.length > 0) {
      setGameNameRes(availableGames?.[0]?.name)
      setGameName(availableGames?.[0])
    }

    let data = [
      { ...isOpen, toShow: 'Open' },
      { ...isClose, toShow: 'Close' },
      { ...isCenter, toShow: 'Center' },
      { ...isSingleKahchee, toShow: 'Single Kenchi' },
      { ...isDoubalKahchee, toShow: 'Double Kenchi' },
      { ...isFirstTND, toShow: 'First TND' },
      { ...isSecondTND, toShow: 'Second TND' },
      { ...isFirstAkda, toShow: 'First Akda' },
      { ...isSecondAkda, toShow: 'Second Akda' },
      { ...isFSRing, toShow: 'FS Ring' }
    ].filter(item => item !== null)
    setoptions(data)
    setoptions2(karachiGamesList)
  }, [
    stateKey,
    fieldsGame,
    gameNameRes,
    isOpen,
    isClose,
    isCenter,
    isSingleKahchee,
    isDoubalKahchee,
    isFirstAkda,
    isSecondAkda,
    isFirstTND,
    isSecondTND,
    isFSRing,
    karachiGamesList,
    games
  ])

  const handleNext = e => {
    e.preventDefault()
    setCurrentIndex(prevIndex =>
      prevIndex === options?.length - 1 ? 0 : prevIndex + 1
    )
  }

  const handleKeyDown2 = event => {
    const keyMap = {
      ArrowUp: 'Single Kenchi', // Move to Single Kenchi
      ArrowDown: 'Double Kenchi', // Move to Double Kenchi
      ArrowLeft: 'Open', // Move to Single Open
      ArrowRight: 'Close', // Move to Single Close
      F: 'First TND', // Move to First TND
      f: 'First TND', // Move to First TND
      S: 'Second TND', // Move to Second TND
      s: 'Second TND', // Move to Second TND
      C: 'Center', // Move to Center
      c: 'Center', // Move to Center
      R: 'FS Ring', // Move to FS Ring
      r: 'FS Ring', // Move to FS Ring
      F10: 'Second Akda' // Move to Second Akda
      // F11: 'First Akda', // Move to Second Akda
    }

    const action = keyMap[event.key]

    if (
      [
        'ArrowUp',
        'ArrowDown',
        'ArrowLeft',
        'ArrowRight',
        'F',
        'f',
        'S',
        's',
        'C',
        'c',
        'R',
        'r',
        'F10'
      ].includes(event.key)
    ) {
      event.preventDefault()
    }

    if (action) {
      setCurrentIndex(prevIndex => {
        const targetIndex = options.findIndex(item => item.toShow === action)
        return targetIndex >= 0 ? targetIndex : prevIndex
      })
    }
  }

  const handleKeyDown3 = event => {
    if (['F9'].includes(event.key) && !['akc.close'].includes(gameNameRes)) {
      setStateKey(event.key)
      setBetNumber('11')
      setCurrentIndex(7)
    }
  }

  const inputChangeCreate = e => {
    e.preventDefault()
    setStateKey(null)
    const { name, value } = e.target
    setFieldsGame(prevState => ({ ...prevState, [name]: value }))
    setErrorsGame(prevState => ({ ...prevState, [name]: '' }))

    if (name === 'userId' && gameName?._id) {
      const user = subAdminData?.find(ele => ele?._id === value)
      setUserName(user)
      setIsDisable(false)
      setTimeout(() => {
        betNumberInputRef.current?.focus()
      }, 0)
    }
  }

  const { seconds, minutes, hours, ampm } = useTime({ format: '12-hour' })

  let isControlPressed = false

  const handleKeyDown4 = useCallback(
    event => {
      if (event.key === 'Control') {
        isControlPressed = true
      }

      if (isControlPressed && (event.key === 'q' || event.key === 'Q')) {
        const index = karachiPlaceBet?.length - 1
        const userData = karachiPlaceBet?.[index]
        if (userData && (gameName['_id'] || id || fieldsGame['userId'])) {
          const res = {
            gameId: gameName && gameName['_id'] ? gameName['_id'] : id,
            userId:
              fieldsGame && fieldsGame['userId'] ? fieldsGame['userId'] : localStoragedata?.userId
          }
          dispatch(
            karachiGamesActions.karachiPlaceBetByFreeze(
              { id: userData?._id },
              res
            )
          )
        }
      }
    },
    [karachiPlaceBet]
  )

  // Keyup handler
  const handleKeyUp = useCallback(event => {
    if (event.key === 'Control') {
      isControlPressed = false
    }
  }, [])

  useEffect(() => {
    // Attach event listeners
    document.addEventListener('keydown', handleKeyDown4)
    document.addEventListener('keyup', handleKeyUp)

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown4)
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [handleKeyDown4, handleKeyUp])

  useEffect(() => {
    const localData = sessionStorage.getItem("adminuser");
    if (!localData) return;

    try {
      const parsedData = JSON.parse(localData);

      if (parsedData?.roleId !== 0) {
        console.log("subAdminData", parsedData)
        const user = subAdminData?.find(ele => String(ele?._id) === String(parsedData?.userId));
        setUserName(user);

        setFieldsGame(prev => ({
          ...prev,
          userId: parsedData?.userId
        }));
      }
    } catch (error) {
      console.error("Error parsing sessionStorage data:", error);
    }
  }, [subAdminData]);

  const userPalyerName = games?.karachiPlaceBet &&
  games?.karachiPlaceBet?.[0]?.userId &&
  games?.karachiPlaceBet?.[0]?.userId?.userName
  ? games?.karachiPlaceBet?.[0]?.userId?.userName
  : userName && userName?.userName
    ? userName?.userName
    : null



  return (
    <div className='w-full bg-white h-screen overflow-hidden overflow-y-auto'>
      <div className='relative bg-white'>
        <div className='w-full'>
          <div className='flex justify-between w-full'>
            {localStoragedata && localStoragedata.roleId === 0 && (
              <div className="p-3 px-5 space-y-4 bg-gray-50 rounded-lg w-full">
                <div className="flex justify-end w-full gap-4">
                  <div className='relative w-full sm:w-72'>
                    <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                      <FaRegUser className="h-4 w-4 text-black" />
                    </div>
                    <select
                      className='w-full pl-10 pr-4 py-2.5 text-base bg-white border border-gray-200 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent appearance-none cursor-pointer hover:border-gray-300 transition-colors font-semibold capitalize'
                      id='userId'
                      name='userId'
                      placeholder='name'
                      value={fieldsGame['userId']}
                      type='text'
                      onChange={inputChangeCreate}
                    >
                      <option value=''>Select User Name</option>
                      {subAdminData && subAdminData.length > 0
                        ? subAdminData.map((element, index) => (
                          <option
                            value={element && element._id ? element._id : ''}
                          >
                            {element && element.userName
                              ? element.userName
                              : ''}
                          </option>
                        ))
                        : null}
                    </select>
                    <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
                      <svg className="h-4 w-4 text-gray-black" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                      </svg>
                    </div>
                  </div>
                  {errorsGame && errorsGame['userId'] ? (
                    <div className='text-red-600 invalid-feedback'>
                      {errorsGame['userId']}
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </div>

          <div className='w-full px-4'>
            <div className="w-full bg-gray-200 px-4 py-2 rounded-md shadow-lg">
              <div className="flex flex-col lg:flex-row lg:justify-between gap-4 items-center">
                {/* Game Stats Section */}
                <div className="flex flex-wrap gap-3 justify-start">


                  <div className="flex items-center px-4">
                    <IoGameControllerOutline className="w-6 h-6 text-black mr-2" />
                    <div className="flex flex-row items-center font-semibold space-x-2 text-xl">
                      <span className="text-black">Game Name :</span>
                      <span className="text-black uppercase">{gameNameRes || 'N/A'}</span>
                    </div>
                  </div>

                  <div className="flex items-center px-4">
                    <FaRegUser className="w-5 h-5 text-black mr-2" />
                    <div className="flex flex-row items-center font-semibold space-x-2 text-xl">
                      <span className="text-black">User Name :</span>
                      <span className="text-black capitalize">{userPalyerName || 'N/A'}</span>
                    </div>
                  </div>

                  <div className="flex items-center px-4">
                    <CiCoinInsert className="w-6 h-6 text-black mr-2" />
                    <div className="flex flex-row items-center font-semibold space-x-2 text-xl">
                      <span className="text-black">Total Bet Amount :</span>
                      <span className="text-black capitalize">{" " + totalAmount || '0'}</span>
                    </div>
                  </div>
                </div>
                <div className="px-4 min-w-[200px]">
                  <div className="flex items-center justify-center gap-2">
                    <LuAlarmClock className="w-5 h-5 text-black" />
                    <div className="flex items-center">
                      <span className="text-black font-mono font-semibold text-xl">
                        {String(hours).padStart(2, '0')}:
                        {String(minutes).padStart(2, '0')}:
                        {String(seconds).padStart(2, '0')}
                      </span>
                      <span className="text-gray-200 ml-2 text-sm uppercase">{ampm}</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div
              className='h-[32rem] overflow-x-auto overflow-y-auto'
              ref={scrollContainerRef}
            >
              <table className='min-w-full border border-gray-300 text-sm sm:text-base'>
                <thead className='sticky top-0 bgcolortb '>
                  <tr className='text-lg font-semibold'>
                    <th className='px-4 py-3'>#</th>
                    {localStoragedata && localStoragedata?.roleId === 0 && (
                      <>
                        <th className='px-4 py-3'>User</th>
                        <th className='px-4 py-3'>Game</th>
                      </>
                    )}
                    <th className='px-4 py-3'>Number</th>
                    <th className='px-4 py-3'>Amount</th>
                    <th className='px-4 py-3'>Market</th>
                    <th className='px-4 py-3'>Packet</th>
                    <th className='px-4 py-3'>Date/Time</th>
                    <th className='px-4 py-3'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {karachiPlaceBet && karachiPlaceBet?.length > 0 ? (
                    karachiPlaceBet.map((bet, index) => (
                      <tr
                        key={index}
                        className='hover:bg-gray-50 text-center last:bg-green-300 transition-colors duration-200 text-lg font-semibold border-b border-gray-400'
                      >
                        <td className='px-4 py-2 whitespace-nowrap'>
                          {index + 1}
                        </td>
                        {localStoragedata && localStoragedata?.roleId === 0 && (
                          <>
                            <td className='px-4 py-2 whitespace-nowrap capitalize'>
                              {(bet?.userId?.userName ||
                                bet?.userData?.userName) ??
                                ''}
                            </td>
                            <td className='px-4 py-2 whitespace-nowrap capitalize'>
                              {(bet?.karachiGameId?.name || bet?.games?.name) ??
                                ''}
                            </td>
                          </>
                        )}
                        {bet.isBetPrime ? (
                          <>
                            <td className='px-4 py-2 whitespace-nowrap'>
                              {bet.betNumber}
                            </td>
                            <td className='px-4 py-2 whitespace-nowrap'>
                              {['Single Open', 'Single Close'].includes(
                                bet?.localmarketname
                              )
                                ? bet?.amount * 10
                                : bet?.amount}
                            </td>
                          </>
                        ) : (
                          <>
                            <td className='px-4 py-2 whitespace-nowrap'>
                              {bet.betNumber}
                            </td>
                            <td className='px-4 py-2 whitespace-nowrap'>
                              {['Single Open', 'Single Close'].includes(
                                bet?.localmarketname
                              )
                                ? bet?.amount * 10
                                : bet?.amount}
                            </td>
                          </>
                        )}
                        <td className='px-4 py-2 whitespace-nowrap'>
                          {bet?.localmarketname}
                        </td>
                        <td className='px-4 py-2 whitespace-nowrap'>
                          {['Single Open', 'Single Close'].includes(
                            bet?.marketId?.name
                          )
                            ? bet?.packet?.length * 10
                            : bet?.packet?.length}
                        </td>
                        <td className='px-4 py-2 whitespace-nowrap'>
                          {moment(bet?.createdAt || backendTimeStamp).format('DD-MM-YYYY HH:mm')}
                        </td>
                        <td className='px-4 py-2 whitespace-nowrap'>
                          <button
                            onClick={() => handleDelete(bet)}
                            className='text-red-500 hover:text-red-600 font-semibold text-base '
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className='border border-gray-300'>
                      <td
                        colSpan='8'
                        className='text-center px-4 py-2 text-base text-gray-500'
                      >
                        No bets available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>


            <div
              disabled={isDisable}
              className='p-6 bg-[#D4EBF8] rounded-xl shadow-lg'>
              <div className='flex flex-col lg:flex-row items-center gap-6'>
                <div className='gap-3 h-16'>
                  <div className='w-44 bg-white border border-blue-200 rounded-lg px-6 py-2.5 text-center text-gray-700 shadow-sm font-semibold'>
                    {currentOption?.toShow}
                  </div>
                </div>

                <div className='h-16'>
                  <button onClick={handleNext} disabled={isDisable} className="bg-blue-500 hover:bg-blue-600 disabled:bg-blue-300 text-black p-3 rounded-lg transition-all duration-300 flex items-center justify-center active:scale-95" title="Next">  <LiaHandPointRight className="w-5 h-5" /></button>
                </div>

                <div className='relative w-72 h-16'>
                  <div className="relative">
                    <div className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400">
                      <FaDice className="w-5 h-5" />
                    </div>
                    <input
                      type='text'
                      ref={betNumberInputRef} // Reference for shifting focus back
                      value={betNumber}
                      onChange={e => {
                        const value = e.target.value
                        if (/^\d*$/.test(value)) {
                          handleChange(e) // Call your existing handleChange function
                        }
                      }}
                      onKeyDown={handleKeyDown}
                      disabled={isDisable}
                      name='betNumber'
                      autoComplete='off'
                      className='w-full pl-10 pr-4 py-2.5 bg-white border border-gray-200 rounded-lg transition-all duration-300 disabled:cursor-not-allowed shadow-sm text-lg font-semibold'
                      placeholder='Enter a number'
                    />
                  </div>
                  {errors?.betNumber && (
                    <p className="mt-1.5 text-xs text-red-500 flex items-center gap-1">
                      <span className="inline-block w-1 h-1 bg-red-500 rounded-full"></span>
                      {errors.betNumber}
                    </p>
                  )}
                </div>

                {/* Amount Input */}
                <div className='relative w-72 h-16'>
                  <div className="relative">
                    <div className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400  ">
                      <FiDollarSign className="w-5 h-5" />
                    </div>
                    <input
                      type='number'
                      ref={amountInputRef} // Reference for shifting focus
                      value={amount}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      disabled={isDisable}
                      name='amount'
                      id='amount'
                      autoComplete='off'
                      className='w-full pl-10 pr-4 py-2.5 bg-white border border-gray-200 rounded-lg transition-all duration-300 disabled:cursor-not-allowed shadow-sm text-lg font-semibold'
                      placeholder='Enter an amount'
                    />
                    {errors.amount && (
                      <p className="mt-1.5 text-xs text-red-500 flex items-center gap-1">
                        <span className="inline-block w-1 h-1 bg-red-500 rounded-full"></span>
                        {errors.amount}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {loading && (
                <div className="absolute inset-0 bg-white/80 backdrop-blur-sm rounded-xl flex items-center justify-center z-50">
                  <div className="relative">
                    <div className="w-12 h-12 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="w-8 h-8 border-4 border-blue-300 border-t-transparent rounded-full animate-spin"></div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default KarachiPlaceBet
