/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { gamesActions, reportActions, userActions } from '../../_actions'
import moment from 'moment'
import { TbArrowsDiagonalMinimize2 } from 'react-icons/tb'
import { LuMaximize2 } from 'react-icons/lu'
import { getCustomTimestamp } from '../../_helpers'

const BetNumber = () => {
  const dispatch = useDispatch()
  const intervalRef = useRef(null)
  const { reports } = useSelector(state => state) || {}
  const [fieldsGame, setFieldsGame] = useState({})
  const [errorsGame, setErrorsGame] = useState({})
  const [isOpen, setOpen] = useState(false)
  const [gameName, setGameName] = useState({})
  let { numberListData, resultDataNum, isPopup } = reports ? reports : []
  const [userData, setUserData] = useState({})
  const [backendTimeStamp, setBackendTimeStamp] = useState(Date.now())
  const keyWord = ''
  const fromDate = ''
  const toDate = ''
  const { gamesList } = useSelector(state => state.games)
  const [sums, setSums] = useState({
    singleSum: 0,
    jodiSum: 0,
    singleOpenSum: 0,
    singleCloseSum: 0,
    jodiCloseSum: 0,
    akOResult: 0,
    akCResult: 0,
    result: 0
  })

  const today = moment(getCustomTimestamp(backendTimeStamp)).format('YYYY-MM-DD')
  useEffect(() => {
    const fetchGlobalDate = async () => {
      try {
        const res = await userActions.getGlobalDate();
        if (res?.data) {
          const stamp = getCustomTimestamp(Number(res.data.timeStamp), Date.now())
          setBackendTimeStamp(() => stamp);
        }
      } catch (error) {
        console.error("Error fetching global date:", error);
        // Optionally set a fallback or error state here
      }
    };

    fetchGlobalDate();
  }, []);


  useEffect(() => {
    const user = JSON.parse(window.sessionStorage.getItem("adminuser"))
    if (user) {
      setUserData(user)
    }
  }, [])

  const {
    singleSum,
    jodiSum,
    singleOpenSum,
    singleCloseSum,
    jodiCloseSum,
    akOResult,
    akCResult,
    result
  } = sums

  useEffect(() => {
    const calculateSums = () => {
      let singleSum = 0
      let jodiSum = 0
      let singleOpenSum = 0
      let singleCloseSum = 0
      let jodiCloseSum = 0
      let akOResult = 0
      let akCResult = 0
      let result = 0

      if (resultDataNum && resultDataNum.length > 0) {
        resultDataNum.forEach(element => {
          const markets = element?.markets || []

          const getSum = marketName =>
            markets
              .filter(ele => ele?.localmarketname === marketName)
              .reduce((sum, record) => sum + (record.totalAmount || 0), 0)

          if (['akbar_open'].includes(element?.shortName)) {
            singleOpenSum = getSum('Single Open')
            jodiCloseSum = getSum('Jodi')
            akOResult = Number(element?.result) ?? 0
          }
          if (['akbar_close'].includes(element?.shortName)) {
            singleCloseSum = getSum('Single Close')
            akCResult = Number(element?.result) ?? 0
          }
          if (!['akbar_open', 'akbar_close'].includes(element?.shortName)) {
            singleSum = getSum('Single Open') + getSum('Single Close')
            jodiSum = getSum('Jodi')
            result = Number(element?.result) ?? 0
          }
        })
      }


      setSums({
        singleSum: singleSum,
        jodiSum: jodiSum,
        singleOpenSum: singleOpenSum,
        singleCloseSum: singleCloseSum,
        jodiCloseSum: jodiCloseSum,
        akOResult: akOResult,
        akCResult: akCResult,
        result: result
      })
    }

    calculateSums()
  }, [resultDataNum])

  useEffect(() => {
    closePopup()

    let gameReq2 = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: '',
      sortOrder: '',
      pageNo: 1,
      size: 500
    }
    dispatch(gamesActions.getAllGames(gameReq2))
    dispatch(reportActions.clearState())

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
        intervalRef.current = null
      }
    }
  }, [])

  useEffect(() => {
    if (isPopup) {
      openPopup()
    }
  }, [resultDataNum])


  const createGameSubmit = async gameRes => {
    if (gameRes && handleValidationCreateGame(gameRes)) {
      const storeId = { ...gameRes }
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
        intervalRef.current = null
      }

      dispatch(reportActions.getNumberWiseMarketSum(storeId))
      // Start a new interval
      intervalRef.current = setInterval(() => {
        dispatch(reportActions.getNumberWiseMarketSum(storeId, intervalRef.current))
      }, 5000)
    }
  }

  const handleValidationCreateGame = gameRes => {
    let formIsValid = true
    let errors = {}

    if (!gameRes?.gameId || gameRes?.gameId === '') {
      formIsValid = false
      errors.userId = 'Select Game Name'
    }
    setErrorsGame(errors)
    return formIsValid
  }

  const inputChangeCreate = e => {
    e.preventDefault()
    const { name, value } = e.target

    clearInterval(intervalRef.current)
    intervalRef.current = null
    setFieldsGame(prevState => {
      if (name === 'userId') {
        setIsSelected(true)
        const game = gamesList?.find(ele => ele?._id === value)
        setGameName(game)
        createGameSubmit({ date: prevState.date || today, gameId: value })
      } else if (name === 'date') {
        createGameSubmit({ date: value || today, gameId: gameName._id })
      }
      return { ...prevState, [name]: value }
    })
    setErrorsGame(prevState => ({ ...prevState, [name]: '' }))

  }

  let openSum = 0
  let closeSum = 0

  const resultRes =
    numberListData && numberListData.result
      ? Number(numberListData.result)
      : null

  const singleOpen =
    numberListData && numberListData?.openBets && numberListData?.openBets?.all
      ? numberListData?.openBets?.all
      : []
  const singleClose =
    numberListData &&
      numberListData?.closedBets &&
      numberListData?.closedBets.all
      ? numberListData?.closedBets.all
      : []

  const newOpenArr = [
    { betNumber: '0', totalAmount: 0 },
    { betNumber: '1', totalAmount: 0 },
    { betNumber: '2', totalAmount: 0 },
    { betNumber: '3', totalAmount: 0 },
    { betNumber: '4', totalAmount: 0 },
    { betNumber: '5', totalAmount: 0 },
    { betNumber: '6', totalAmount: 0 },
    { betNumber: '7', totalAmount: 0 },
    { betNumber: '8', totalAmount: 0 },
    { betNumber: '9', totalAmount: 0 }
  ]

  singleOpen.forEach(openBet => {
    const matchingItem = newOpenArr.find(
      item => item.betNumber === openBet.betNumber
    )
    if (matchingItem) {
      matchingItem.totalAmount = openBet.totalAmount
      openSum += openBet.totalAmount
    }
  })
  const newCloseArr = [
    { betNumber: '0', totalAmount: 0 },
    { betNumber: '1', totalAmount: 0 },
    { betNumber: '2', totalAmount: 0 },
    { betNumber: '3', totalAmount: 0 },
    { betNumber: '4', totalAmount: 0 },
    { betNumber: '5', totalAmount: 0 },
    { betNumber: '6', totalAmount: 0 },
    { betNumber: '7', totalAmount: 0 },
    { betNumber: '8', totalAmount: 0 },
    { betNumber: '9', totalAmount: 0 }
  ]

  singleClose.forEach(closeBet => {
    const matchingItem = newCloseArr.find(
      item => item.betNumber === closeBet.betNumber
    )
    if (matchingItem) {
      matchingItem.totalAmount = closeBet.totalAmount
      closeSum += closeBet.totalAmount
    }
  })

  const openPopup = () => {
    setOpen(true) // Set state to true to hide the popup
  }

  const closePopup = () => {
    setOpen(false) // Set state to false to hide the popup
  }

  const sortedJodiBets = numberListData && numberListData?.jodiBets && numberListData?.jodiBets.all
    ? numberListData?.jodiBets.all
    : []

  const sortedOpenBets = numberListData && numberListData?.openBets && numberListData?.openBets.all
    ? numberListData?.openBets.all
    : []

  const sortedCloseBets = numberListData && numberListData?.closedBets && numberListData?.closedBets.all
    ? numberListData?.closedBets.all
    : []

  const sumTotalAmount = (gameName?.shortName === 'akbar_open' ? sortedOpenBets : gameName?.shortName === 'akbar_close' ? sortedCloseBets : sortedJodiBets || []).reduce((sum, record) => {
    const count = record.totalAmount - (fieldsGame?.cutting ?? 0) // Subtracting the cutting value from totalAmount
    if (count > 0) {
      return sum + count // Adding the count to the sum if it's greater than 0
    }
    return sum // If the count is 0 or less, return the current sum without adding anything
  }, 0)

  const remainingAmount = Math.ceil(sumTotalAmount) - (fieldsGame?.cutting ?? 0)
  const grossAmount = remainingAmount - (singleSum * 9 + jodiSum * 90)

  const sortedByAmountDesc = [...sortedJodiBets].sort(
    (a, b) => b.totalAmount - a.totalAmount
  )
  const jodiHighestBets = sortedByAmountDesc
    .filter(bet => bet.totalAmount > 0)
    .slice(0, 15)

  const sortedByAmountAsc = [...sortedJodiBets].sort(
    (a, b) => a.totalAmount - b.totalAmount
  )
  const jodiLowestBets = sortedByAmountAsc
    .filter(bet => bet.totalAmount > 0)
    .slice(0, 15)

  const totalAmountResponse = gameName?.shortName === 'akbar_open' ? openSum : gameName?.shortName === 'akbar_close' ? closeSum : remainingAmount

  // Function to toggle full screen
  const [isFullScreen, setIsFullScreen] = useState(false)
  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen() // Enter full-screen mode
      setIsFullScreen(true) // Update state to track full-screen mode
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen() // Exit full-screen mode
        setIsFullScreen(false) // Update state to track the exit of full-screen
      }
    }
  }

  const selectorData = useSelector(state => state)
  const [isSelected, setIsSelected] = useState(false)

  useEffect(() => {
    if (!isSelected && selectorData?.games?.gamesList?.length) {
      const game = selectorData?.games?.gamesList?.find(
        status => status.status === 'ACTIVE'
      )
      if (game) {
        let gameRes = {
          date: fieldsGame?.date || today,
          gameId: game._id
        }
        setFieldsGame(prev => ({ ...prev, userId: game._id }))
        createGameSubmit(gameRes)
      }
    }
  }, [selectorData?.games?.gamesList, gameName])


  return (
    <>
      <div className='z-0 h-screen overflow-hidden overflow-y-auto focus:outline-none'>
        <div className='relative z-20 flex min-h-screen'>
          <div className='flex w-full overflow-hidden '>
            <div className='flex flex-col flex-1 overflow-hidden'>
              <main className='relative flex-1'>
                <div className='p-3'>
                  <div className='mx-auto max-w-screen-3xl'>
                    {numberListData ? (
                      <div className='bg-white p-2 rounded-md'>
                        <div className='grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2 gap-2'>
                          <div className='flex flex-col space-y-2 h-[730px] overflow-hidden overflow-y-auto'>
                            {/* Table 1 */}
                            <div className='flex-1 pb-4 p-1 bg-[#FF1900] border border-black'>
                              <table className='bg-[#BFC3FE] table-auto w-full border-collapse border border-gray-300 h-full'>
                                <thead>
                                  <tr className='text-sm '>
                                    <th className='border border-gray-800 '>
                                      Num
                                    </th>
                                    <th className='border border-gray-800 '>
                                      High_15
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Array.isArray(jodiHighestBets) &&
                                    jodiHighestBets?.map((ele, index) => {
                                      const count = ele?.totalAmount
                                        ? ele?.totalAmount -
                                        (fieldsGame?.cutting ?? 0)
                                        : 0
                                      const formattedCount =
                                        count > 0
                                          ? count % 1 === 0
                                            ? count
                                            : count.toFixed(2)
                                          : 0
                                      return (
                                        <tr
                                          key={index}
                                          className='text-center text-sm font-bold'
                                        >
                                          <td className='border border-gray-300'>
                                            {ele?.betNumber ?? null}
                                          </td>
                                          <td className='border border-gray-300'>
                                            {formattedCount}
                                          </td>
                                        </tr>
                                      )
                                    })}
                                </tbody>
                              </table>
                            </div>


                            {/* Table 2 */}
                            <div className='flex-1 pb-4 p-2 bg-[#00A300]  border border-black'>
                              <table className='bg-[#BFC3FE] table-auto w-full border-collapse border border-gray-300 h-full'>
                                <thead>
                                  <tr className='text-xs font-bold'>
                                    <th className='border border-gray-800'>
                                      Num
                                    </th>
                                    <th className='border border-gray-800'>
                                      Low_15
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Array.isArray(jodiLowestBets) &&
                                    jodiLowestBets
                                      ?.slice()
                                      ?.map((ele, index) => {
                                        const count = ele?.totalAmount
                                          ? ele?.totalAmount -
                                          (fieldsGame?.cutting ?? 0)
                                          : 0
                                        const formattedCount =
                                          count > 0
                                            ? count % 1 === 0
                                              ? count
                                              : count.toFixed(2)
                                            : 0

                                        return (
                                          <tr
                                            key={index}
                                            className='text-center text-xs font-bold'
                                          >
                                            <td className='border border-gray-300'>
                                              {ele?.betNumber ?? null}
                                            </td>
                                            <td className='border border-gray-300'>
                                              {formattedCount}
                                            </td>
                                          </tr>
                                        )
                                      })}
                                </tbody>
                              </table>
                            </div>
                          </div>

                          {!['akbar_close'].includes(gameName?.shortName) ? <>
                            <div
                              className='bg-[#FF8D7F] h-[730px] overflow-hidden overflow-y-auto border border-black p-2'
                              style={{
                                backgroundColor: gameName?.color || '#87A922'
                              }}
                            >
                              <table
                                class='table-auto w-full border-collapse border border-gray-300 bg-[#87A922]'
                                style={{
                                  backgroundColor: gameName?.color || '#87A922'
                                }}
                              >
                                <thead>
                                  <tr className='text-center text-lg font-bold text-black'>
                                    <th class='border border-gray-300'>Num</th>
                                    <th class='border border-gray-300'>00 to 24</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Array.isArray(sortedJodiBets) &&
                                    sortedJodiBets
                                      .slice(0, 25)
                                      .map((ele, index) => {
                                        // Calculate the count with conditional checks
                                        const count = ele?.totalAmount
                                          ? ele?.totalAmount -
                                          (fieldsGame?.cutting ?? 0)
                                          : 0

                                        // Format the count
                                        const formattedCount =
                                          count > 0
                                            ? count % 1 === 0
                                              ? count
                                              : count.toFixed(2)
                                            : 0

                                        // Determine the row background based on `isBetPrime`
                                        const rowClass =
                                          String(ele?.betNumber).toLowerCase() ===
                                            String(resultRes).toLowerCase()
                                            ? 'border border-blue-600 bg-green-600'
                                            : ele?.isBetPrime
                                              ? 'border border-gray-300 bg-red-600'
                                              : 'border border-gray-300'

                                        return (
                                          <tr
                                            key={index}
                                            className='text-center text-[16px] font-bold text-black'
                                          >
                                            <td className={rowClass}>
                                              {ele?.betNumber ?? null}
                                            </td>
                                            <td className={rowClass}>
                                              {formattedCount}
                                            </td>
                                          </tr>
                                        )
                                      })}
                                </tbody>
                              </table>
                            </div>

                            <div
                              className='bg-[#FF8D7F] border h-[730px] overflow-hidden overflow-y-auto border-black p-2'
                              style={{
                                backgroundColor: gameName?.color || '#87A922'
                              }}
                            >
                              <table
                                class=' table-auto w-full border-collapse border border-gray-300 bg-[#87A922]'
                                style={{
                                  backgroundColor: gameName?.color || '#87A922'
                                }}
                              >
                                <thead>
                                  <tr className='text-center text-lg font-bold text-black'>
                                    <th class='border border-gray-300'>Num</th>
                                    <th class='border border-gray-300'>
                                      25 to 49
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Array.isArray(sortedJodiBets) &&
                                    sortedJodiBets
                                      ?.slice(25, 50)
                                      .map((ele, index) => {
                                        const count = ele?.totalAmount
                                          ? ele?.totalAmount -
                                          (fieldsGame?.cutting ?? 0)
                                          : 0

                                        const formattedCount =
                                          count > 0
                                            ? count % 1 === 0
                                              ? count
                                              : count.toFixed(2)
                                            : 0

                                        // Determine the row background based on `isBetPrime`
                                        const rowClass =
                                          String(ele?.betNumber).toLowerCase() ===
                                            String(resultRes).toLowerCase()
                                            ? 'border border-blue-600 bg-green-600'
                                            : ele?.isBetPrime
                                              ? 'border border-gray-300 bg-red-600'
                                              : 'border border-gray-300'

                                        return (
                                          <tr
                                            key={index}
                                            className='text-center text-[16px] font-bold text-black'
                                          >
                                            <td className={rowClass}>
                                              {ele?.betNumber ?? null}
                                            </td>
                                            <td className={rowClass}>
                                              {formattedCount}
                                            </td>
                                          </tr>
                                        )
                                      })}
                                </tbody>
                              </table>
                            </div>

                            <div
                              className='bg-[#FF8D7F] border h-[730px] overflow-hidden overflow-y-auto border-black p-2'
                              style={{
                                backgroundColor: gameName?.color || '#87A922'
                              }}
                            >
                              <table
                                class='table-auto w-full border-collapse border border-gray-300 bg-[#87A922]'
                                style={{
                                  backgroundColor: gameName?.color || '#87A922'
                                }}
                              >
                                <thead>
                                  <tr className='text-center text-lg font-bold text-black'>
                                    <th class='border border-gray-300'>Num</th>
                                    <th class='border border-gray-300'>
                                      50 to 74
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Array.isArray(sortedJodiBets) &&
                                    sortedJodiBets
                                      ?.slice(50, 75)
                                      .map((ele, index) => {
                                        const count = ele?.totalAmount
                                          ? ele?.totalAmount -
                                          (fieldsGame?.cutting ?? 0)
                                          : 0

                                        const formattedCount =
                                          count > 0
                                            ? count % 1 === 0
                                              ? count
                                              : count.toFixed(2)
                                            : 0

                                        // Determine the row background based on `isBetPrime`
                                        const rowClass =
                                          String(ele?.betNumber).toLowerCase() ===
                                            String(resultRes).toLowerCase()
                                            ? 'border border-blue-600 bg-green-600'
                                            : ele?.isBetPrime
                                              ? 'border border-gray-300 bg-red-600'
                                              : 'border border-gray-300'

                                        return (
                                          <tr
                                            key={index}
                                            className='text-center text-[16px] font-bold text-black'
                                          >
                                            <td className={rowClass}>
                                              {ele?.betNumber ?? null}
                                            </td>
                                            <td className={rowClass}>
                                              {formattedCount}
                                            </td>
                                          </tr>
                                        )
                                      })}
                                </tbody>
                              </table>
                            </div>

                            <div
                              className='bg-[#FF8D7F] border h-[730px] overflow-hidden overflow-y-auto border-black p-2'
                              style={{
                                backgroundColor: gameName?.color || '#87A922'
                              }}
                            >
                              <table
                                class='table-auto w-full border-collapse border border-gray-300 bg-[#87A922]'
                                style={{
                                  backgroundColor: gameName?.color || '#87A922'
                                }}
                              >
                                <thead>
                                  <tr className='text-center text-lg font-bold text-black'>
                                    <th class='border border-gray-300'>Num</th>
                                    <th class='border border-gray-300'>
                                      75 to 99
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Array.isArray(sortedJodiBets) &&
                                    sortedJodiBets
                                      ?.slice(75, 100)
                                      .map((ele, index) => {
                                        const count = ele?.totalAmount
                                          ? ele?.totalAmount -
                                          (fieldsGame?.cutting ?? 0)
                                          : 0

                                        const formattedCount =
                                          count > 0
                                            ? count % 1 === 0
                                              ? count
                                              : count.toFixed(2)
                                            : 0

                                        // Determine the row background based on `isBetPrime`
                                        const rowClass =
                                          String(ele?.betNumber).toLowerCase() ===
                                            String(resultRes).toLowerCase()
                                            ? 'border border-blue-600 bg-green-600'
                                            : ele?.isBetPrime
                                              ? 'border border-gray-300 bg-red-600'
                                              : 'border border-gray-300'

                                        return (
                                          <tr
                                            key={index}
                                            className='text-center text-[16px] font-bold text-black'
                                          >
                                            <td className={rowClass}>
                                              {ele?.betNumber ?? null}
                                            </td>
                                            <td className={rowClass}>
                                              {formattedCount}
                                            </td>
                                          </tr>
                                        )
                                      })}
                                </tbody>
                              </table>
                            </div>
                          </> : <div className='col-span-4 flex justify-center items-center' style={{
                            backgroundColor: gameName?.color || '#87A922'
                          }}>
                            <span className='text-6xl'>
                              AK Close
                            </span>
                          </div>}


                          <div className='flex flex-col space-y-2 h-[730px] overflow-hidden overflow-y-auto'>
                            {!['akbar_close'].includes(gameName?.shortName) && (
                              <>
                                <div className='bg-[#500F83]  flex-1 pb-4 p-2 border border-black'>
                                  <table className='bg-[#BFC3FE] table-auto w-full border-collapse border border-gray-300 h-full'>
                                    <thead>
                                      <tr className='text-sm font-bold'>
                                        <th className='border border-gray-800 '>
                                          Num
                                        </th>
                                        <th className='border border-gray-800 '></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {Array.isArray(newOpenArr) &&
                                        newOpenArr?.map((ele, index) => {
                                          const count = ele?.totalAmount
                                            ? ele?.totalAmount -
                                            (fieldsGame?.cutting ?? 0)
                                            : 0
                                          const formattedCount =
                                            count > 0
                                              ? count % 1 === 0
                                                ? count
                                                : count.toFixed(2)
                                              : 0

                                          return (
                                            <tr
                                              key={index}
                                              className='text-center text-sm font-bold'
                                            >
                                              <td className='border border-gray-800'>
                                                {ele?.betNumber ?? null}X
                                              </td>
                                              <td className='border border-gray-800'>
                                                {formattedCount}
                                              </td>
                                            </tr>
                                          )
                                        })}
                                    </tbody>
                                  </table>
                                </div>
                                <div className='flex border border-yellow-800 font-bold bg-yellow-300 text-black px-2 justify-between items-center'>
                                  <p>Open Amount:</p>
                                  <p>{openSum.toFixed(2) ?? 0}</p>
                                </div>
                              </>
                            )}
                            {/* Table 2 */}
                            {!['akbar_open'].includes(gameName?.shortName) && (
                              <>
                                <div className='bg-[#500F83] flex-1 pb-4 p-2 border border-black'>
                                  <table className='bg-[#BFC3FE] table-auto w-full border-collapse border border-gray-300 h-full'>
                                    <thead>
                                      <tr className='text-sm font-bold'>
                                        <th className='border border-gray-800'>
                                          Num
                                        </th>
                                        <th className='border border-gray-800'></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {Array.isArray(newCloseArr) &&
                                        newCloseArr?.map((ele, index) => {
                                          const count = ele?.totalAmount
                                            ? ele?.totalAmount -
                                            (fieldsGame?.cutting ?? 0)
                                            : 0
                                          const formattedCount =
                                            count > 0
                                              ? count % 1 === 0
                                                ? count
                                                : count.toFixed(2)
                                              : 0

                                          return (
                                            <tr
                                              key={index}
                                              className='text-center text-sm  font-bold'
                                            >
                                              <td className='border border-gray-800'>
                                                X{ele?.betNumber ?? null}
                                              </td>
                                              <td className='border border-gray-800'>
                                                {formattedCount}
                                              </td>
                                            </tr>
                                          )
                                        })}
                                    </tbody>
                                  </table>
                                </div>
                                <div className='flex border border-yellow-800 font-bold bg-yellow-300 text-black px-2 justify-between items-center'>
                                  <p>Close Amount:</p>
                                  <p>{closeSum.toFixed(2) ?? 0}</p>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className='bg-white p-2 rounded-md'>
                        <div className='grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2 gap-2'>
                          <div className='flex flex-col space-y-2'>
                            {/* Table 1 */}
                            <div className='flex-1 pb-4 p-2 bg-[#FF1900] border border-black'>
                              <table className='bg-[#BFC3FE] table-auto w-full border-collapse border border-gray-300 h-full'>
                                <thead>
                                  <tr className='text-[10px] font-normal'>
                                    <th className='border border-gray-300 font-normal'>
                                      Num
                                    </th>
                                    <th className='border border-gray-300 font-normal'>
                                      Highest_15
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Array.isArray(jodiHighestBets) &&
                                    jodiHighestBets?.map((ele, index) => {
                                      const count = ele?.totalAmount
                                        ? ele?.totalAmount -
                                        (fieldsGame?.cutting ?? 0)
                                        : 0
                                      const formattedCount =
                                        count > 0
                                          ? count % 1 === 0
                                            ? count
                                            : count.toFixed(2)
                                          : 0

                                      return (
                                        <tr
                                          key={index}
                                          className='text-center font-bold text-[10px]'
                                        >
                                          <td className='border border-gray-300'>
                                            {ele?.betNumber ?? null}
                                          </td>
                                          <td className='border border-gray-300'>
                                            {formattedCount}
                                          </td>
                                        </tr>
                                      )
                                    })}
                                </tbody>
                              </table>
                            </div>

                            {/* Table 2 */}
                            <div className='flex-1 pb-4 p-2 bg-[#00A300]  border border-black'>
                              <table className='bg-[#BFC3FE] table-auto w-full border-collapse border border-gray-300 h-full'>
                                <thead>
                                  <tr className='text-[10px] font-normal'>
                                    <th className='border border-gray-300 font-normal'>
                                      Num
                                    </th>
                                    <th className='border border-gray-300 font-normal'>
                                      Lowest_15
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Array.isArray(jodiLowestBets) &&
                                    jodiLowestBets
                                      ?.slice()
                                      ?.map((ele, index) => {
                                        const count = ele?.totalAmount
                                          ? ele?.totalAmount -
                                          (fieldsGame?.cutting ?? 0)
                                          : 0
                                        const formattedCount =
                                          count > 0
                                            ? count % 1 === 0
                                              ? count
                                              : count.toFixed(2)
                                            : 0

                                        return (
                                          <tr
                                            key={index}
                                            className='text-center font-bold text-[10px]'
                                          >
                                            <td className='border border-gray-300'>
                                              {ele?.betNumber ?? null}
                                            </td>
                                            <td className='border border-gray-300'>
                                              {formattedCount}
                                            </td>
                                          </tr>
                                        )
                                      })}
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <div className='bg-[#FF8D7F] h-[40rem] border border-black p-2'>
                            <table class='table-auto w-full border-collapse border border-gray-300 bg-[#87A922]'>
                              <thead>
                                <tr className='text-center text-[10px] text-white'>
                                  <th class='border border-gray-300 font-normal'>
                                    Num
                                  </th>
                                  <th class='border border-gray-300 font-normal'>
                                    00 to 24
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {Array.isArray(sortedJodiBets) &&
                                  sortedJodiBets
                                    ?.slice(0, 25)
                                    .map((ele, index) => {
                                      const count = ele?.totalAmount
                                        ? ele?.totalAmount -
                                        (fieldsGame?.cutting ?? 0)
                                        : 0

                                      const formattedCount =
                                        count > 0
                                          ? count % 1 === 0
                                            ? count
                                            : count.toFixed(2)
                                          : 0

                                      return (
                                        <tr
                                          key={index}
                                          className='text-center text-base font-bold text-white'
                                        >
                                          {ele?.isBetPrime ? (
                                            <td className='border border-gray-300 bg-red-600'>
                                              {ele?.betNumber ?? null}
                                            </td>
                                          ) : (
                                            <td className='border border-gray-300'>
                                              {ele?.betNumber ?? null}
                                            </td>
                                          )}
                                          <td className='border border-gray-300'>
                                            {formattedCount}
                                          </td>
                                        </tr>
                                      )
                                    })}
                              </tbody>
                            </table>
                          </div>

                          <div className='bg-[#FF8D7F] h-[40rem] border border-black p-2'>
                            <table class='table-auto w-full border-collapse border border-gray-300 bg-[#87A922]'>
                              <thead>
                                <tr className='text-[10px] text-white font-normal'>
                                  <th class='border border-gray-300 font-normal'>
                                    Num
                                  </th>
                                  <th class='border border-gray-300 font-normal'>
                                    25 to 49
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {Array.isArray(sortedJodiBets) &&
                                  sortedJodiBets
                                    ?.slice(25, 50)
                                    .map((ele, index) => {
                                      const count = ele?.totalAmount
                                        ? ele?.totalAmount -
                                        (fieldsGame?.cutting ?? 0)
                                        : 0

                                      const formattedCount =
                                        count > 0
                                          ? count % 1 === 0
                                            ? count
                                            : count.toFixed(2)
                                          : 0

                                      return (
                                        <tr
                                          key={index}
                                          className='text-center text-base font-bold  text-white'
                                        >
                                          {ele?.isBetPrime ? (
                                            <td className='border border-gray-300 bg-red-600'>
                                              {ele?.betNumber ?? null}
                                            </td>
                                          ) : (
                                            <td className='border border-gray-300'>
                                              {ele?.betNumber ?? null}
                                            </td>
                                          )}
                                          <td className='border border-gray-300'>
                                            {formattedCount}
                                          </td>
                                        </tr>
                                      )
                                    })}
                              </tbody>
                            </table>
                          </div>

                          <div className='bg-[#FF8D7F] h-[40rem] border border-black p-2'>
                            <table class='table-auto w-full border-collapse border border-gray-300 bg-[#87A922]'>
                              <thead>
                                <tr className='text-[10px] text-white font-normal'>
                                  <th class='border border-gray-300 font-normal'>
                                    Num
                                  </th>
                                  <th class='border border-gray-300 font-normal'>
                                    50 to 74
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {Array.isArray(sortedJodiBets) &&
                                  sortedJodiBets
                                    ?.slice(50, 75)
                                    .map((ele, index) => {
                                      const count = ele?.totalAmount
                                        ? ele?.totalAmount -
                                        (fieldsGame?.cutting ?? 0)
                                        : 0

                                      const formattedCount =
                                        count > 0
                                          ? count % 1 === 0
                                            ? count
                                            : count.toFixed(2)
                                          : 0

                                      return (
                                        <tr
                                          key={index}
                                          className='text-center text-base font-bold text-white'
                                        >
                                          {ele?.isBetPrime ? (
                                            <td className='border border-gray-300 bg-red-600'>
                                              {ele?.betNumber ?? null}
                                            </td>
                                          ) : (
                                            <td className='border border-gray-300'>
                                              {ele?.betNumber ?? null}
                                            </td>
                                          )}
                                          <td className='border border-gray-300'>
                                            {formattedCount}
                                          </td>
                                        </tr>
                                      )
                                    })}
                              </tbody>
                            </table>
                          </div>

                          <div className='bg-[#FF8D7F] h-[40rem] border border-black p-2'>
                            <table class='table-auto w-full border-collapse border border-gray-300 bg-[#87A922]'>
                              <thead>
                                <tr className='text-[10px] text-white font-normal'>
                                  <th class='border border-gray-300 font-normal'>
                                    Num
                                  </th>
                                  <th class='border border-gray-300 font-normal'>
                                    75 to 99
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {Array.isArray(sortedJodiBets) &&
                                  sortedJodiBets
                                    ?.slice(75, 100)
                                    .map((ele, index) => {
                                      const count = ele?.totalAmount
                                        ? ele?.totalAmount -
                                        (fieldsGame?.cutting ?? 0)
                                        : 0

                                      const formattedCount =
                                        count > 0
                                          ? count % 1 === 0
                                            ? count
                                            : count.toFixed(2)
                                          : 0

                                      return (
                                        <tr
                                          key={index}
                                          className='text-center text-base font-bold text-white'
                                        >
                                          {ele?.isBetPrime ? (
                                            <td className='border border-gray-300 bg-red-600'>
                                              {ele?.betNumber ?? null}
                                            </td>
                                          ) : (
                                            <td className='border border-gray-300'>
                                              {ele?.betNumber ?? null}
                                            </td>
                                          )}
                                          <td className='border border-gray-300'>
                                            {formattedCount}
                                          </td>
                                        </tr>
                                      )
                                    })}
                              </tbody>
                            </table>
                          </div>

                          <div className='flex flex-col space-y-2'>
                            <div className='bg-[#500F83] flex-1 pb-4 p-2 border border-black'>
                              <table className='bg-[#BFC3FE] table-auto w-full border-collapse border border-gray-300 h-full'>
                                <thead>
                                  <tr className='text-[10px] font-normal'>
                                    <th className='border border-gray-300 font-normal'>
                                      Num
                                    </th>
                                    <th className='border border-gray-300 font-normal'></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Array.isArray(newOpenArr) &&
                                    newOpenArr?.map((ele, index) => {
                                      const count = ele?.totalAmount
                                        ? ele?.totalAmount -
                                        (fieldsGame?.cutting ?? 0)
                                        : 0
                                      const formattedCount =
                                        count > 0
                                          ? count % 1 === 0
                                            ? count
                                            : count.toFixed(2)
                                          : 0

                                      return (
                                        <tr
                                          key={index}
                                          className='text-center text-[10px] font-normal'
                                        >
                                          <td className='border border-gray-300'>
                                            {ele?.betNumber ?? null}X
                                          </td>
                                          <td className='border border-gray-300'>
                                            {formattedCount}
                                          </td>
                                        </tr>
                                      )
                                    })}
                                </tbody>
                              </table>
                            </div>
                            <div className='flex border border-yellow-800 bg-yellow-300 text-black px-2 justify-between items-center'>
                              <p>Open Amount:</p>
                              <p>{openSum.toFixed(2) ?? 0}</p>
                            </div>
                            {/* Table 2 */}
                            <div className='bg-[#500F83] flex-1 pb-4 p-2 border border-black'>
                              <table className='bg-[#BFC3FE] table-auto w-full border-collapse border border-gray-300 h-full'>
                                <thead>
                                  <tr className='text-[10px] font-normal'>
                                    <th className='border border-gray-300 font-normal'>
                                      Num
                                    </th>
                                    <th className='border border-gray-300'></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Array.isArray(newCloseArr) &&
                                    newCloseArr?.map((ele, index) => {
                                      const count = ele?.totalAmount
                                        ? ele?.totalAmount -
                                        (fieldsGame?.cutting ?? 0)
                                        : 0
                                      const formattedCount =
                                        count > 0
                                          ? count % 1 === 0
                                            ? count
                                            : count.toFixed(2)
                                          : 0

                                      return (
                                        <tr
                                          key={index}
                                          className='text-center text-[10px] font-normal'
                                        >
                                          <td className='border border-gray-300'>
                                            X{ele?.betNumber ?? null}
                                          </td>
                                          <td className='border border-gray-300'>
                                            {formattedCount}
                                          </td>
                                        </tr>
                                      )
                                    })}
                                </tbody>
                              </table>
                            </div>
                            <div className='flex border border-yellow-800 bg-yellow-300 text-black px-2 justify-between items-center'>
                              <p>Close Amount:</p>
                              <p>{closeSum.toFixed(2) ?? 0}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="w-full bg-white rounded-xl shadow-sm px-4 py-2 mt-2">
                      <div className="grid lg:grid-cols-5 md:grid-cols-4 grid-cols-1 gap-4">
                        {/* Date Input */}
                        <div className="space-y-1">
                          <label className="block text-gray-900 text-base font-medium" htmlFor="date">
                            Date
                          </label>
                          <div className="relative">
                            {/* <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                              <CiCalendarDate className="h-5 w-5 text-gray-400" />
                            </div> */}
                            <input
                              type="date"
                              id="date"
                              name="date"
                              value={fieldsGame?.date || today}
                              onChange={inputChangeCreate}
                              className="w-full px-3 py-0.5 text-2xl bg-white border border-gray-400 rounded-md focus:outline-none"
                            />
                          </div>
                          {errorsGame?.date && (
                            <p className="text-red-500 text-xs mt-1">{errorsGame.date}</p>
                          )}
                        </div>

                        {/* Game Name Select */}
                        <div className="space-y-1.5">
                          <label className="block text-gray-900 text-base font-medium">
                            Game Name
                          </label>
                          <div className="relative">
                            {/* <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                              <TfiGame className="h-5 w-5 text-gray-400" />
                            </div> */}
                            <select
                              id="userId"
                              name="userId"
                              value={fieldsGame?.userId || ''}
                              onChange={inputChangeCreate}
                              className="w-full px-3 py-1 text-2xl bg-white border border-gray-400 rounded-md focus:outline-none capitalize"
                            >
                              <option value="">Select Game Name</option>
                              {gamesList?.map((element, index) => (
                                <option key={index} value={element?._id}>
                                  {element?.name}
                                </option>
                              ))}
                            </select>
                            {/* <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                <svg className="h-4 w-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                </svg>
                              </div> */}
                          </div>
                          {errorsGame?.userId && (
                            <p className="text-red-500 text-xs mt-1">{errorsGame.userId}</p>
                          )}
                        </div>

                        {/* Cutting Input - Conditional Rendering */}
                        {userData?.roleId === 0 && (
                          <div className="space-y-1.5">
                            <label className="block text-gray-900 text-base font-medium">
                              Cutting
                            </label>
                            <div className="relative">
                              {/* <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <PiScissorsThin className="h-5 w-5 text-gray-400" />
                              </div> */}
                              <input
                                type="number"
                                id="cutting"
                                name="cutting"
                                placeholder="Enter cutting value"
                                value={fieldsGame?.cutting || ''}
                                onChange={inputChangeCreate}
                                className="w-full px-3 py-0.5 text-2xl bg-white border border-gray-400 rounded-md focus:outline-none"
                              />
                            </div>
                            {errorsGame?.cutting && (
                              <p className="text-red-500 text-xs mt-1">{errorsGame.cutting}</p>
                            )}
                          </div>
                        )}

                        {/* Remaining Amount */}
                        <div className="space-y-1.5">
                          <label className="block text-gray-900 text-base font-medium">
                            Remaining Amount
                          </label>
                          <div className="flex items-center gap-2">
                            <div className="relative flex-1">
                              {/* <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <CiWallet className="h-5 w-5 text-gray-400" />
                              </div> */}
                              <input
                                type="number"
                                id="result"
                                name="result"
                                value={remainingAmount < 0 ? 0 : remainingAmount}
                                readOnly
                                className="w-full px-3 py-0.5 text-2xl bg-white border border-gray-400 rounded-md focus:outline-none"
                              />
                            </div>
                            <button
                              onClick={toggleFullScreen}
                              className="p-2 hover:bg-gray-100 rounded-lg transition-colors duration-200"
                              title={isFullScreen ? "Exit Fullscreen" : "Enter Fullscreen"}
                            >
                              {isFullScreen ? (
                                <TbArrowsDiagonalMinimize2 className="h-5 w-5 text-gray-600" />
                              ) : (
                                <LuMaximize2 className="h-5 w-5 text-gray-600" />
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {isPopup && isOpen && (
                      <div
                        className={
                          isOpen
                            ? `fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster`
                            : 'hidden'
                        }
                        style={{ background: 'rgba(0,0,0,.7)' }}
                      >
                        <div className='z-50 w-11/12 mx-auto overflow-y-auto bg-white border rounded shadow-lg modal-container md:max-w-4xl'>
                          <div className='px-2 py-2 text-left modal-content'>
                            <div className=''>
                              <div className='flex justify-between items-center pb-3'>
                                <p className='text-center font-bold text-sm'>
                                  {gameName?.name ?? null}
                                </p>
                                <button
                                  className='top-2 right-2 bg-gray-200 text-gray-800 rounded-full p-1 hover:bg-gray-300 focus:outline-none'
                                  onClick={() => closePopup()}
                                >
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    className='h-5 w-5'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                    stroke='currentColor'
                                  >
                                    <path
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      strokeWidth='2'
                                      d='M6 18L18 6M6 6l12 12'
                                    ></path>
                                  </svg>
                                </button>
                              </div>

                              <div class='bg-white shadow-md rounded-lg w-full p-4'>
                                <div class='flex space-x-4 text-xl font-semibold border-b pb-2 mb-4'>
                                  <p className='capitalize'> Draw Detail of</p>
                                  <p className='capitalize'>
                                    {' '}
                                    {gameName?.name ?? null}
                                  </p>
                                </div>

                                <div className='grid lg:grid-cols-2 grid-cols-1 gap-4'>
                                  <div className=''>
                                    <div className='grid grid-cols-6 border text-white text-base'>
                                      <div className='bgcolor px-2 py-2 font-semibold col-span-2'>
                                        Mark
                                      </div>
                                      <div className='bgcolor px-2 py-2 font-semibold text-center'>
                                        Total Sale
                                      </div>
                                      <div className='bgcolor px-2 py-2 font-semibold text-center'>
                                        Op . Cz
                                      </div>
                                      <div className='bgcolor px-2 py-2 font-semibold text-center'>
                                        Akara
                                      </div>
                                      <div className='bgcolor px-2 py-2 font-semibold text-center'>
                                        Remaining
                                      </div>
                                    </div>
                                    {gamesList && gamesList.length > 0
                                      ? gamesList.map(ele => {
                                        const totalSingleAmount =
                                          ele?.shortName === 'akbar_open'
                                            ? Number(singleOpenSum) * 9
                                            : ele?.shortName === 'akbar_close'
                                              ? Number(singleCloseSum) * 9
                                              : ele?.name === gameName?.name
                                                ? Number(singleSum ?? 0) * 9 +
                                                Number(jodiSum ?? 0) * 90 || 0
                                                : 0
                                        return (
                                          <>
                                            <div className='grid grid-cols-6 gap-1 my-1 border'>
                                              <div className='bg-[#C0C0C0] px-2 py-2 flex justify-between items-center col-span-2 border border-black font-semibold'>
                                                <span className='textcolor text-base'>
                                                  {ele?.name ?? null}
                                                </span>
                                                <span className='text-green-800 text-base'>
                                                  {ele?.shortName ===
                                                    'akbar_open'
                                                    ? akOResult
                                                    : ele?.shortName ===
                                                      'akbar_close'
                                                      ? akCResult
                                                      : ele?.name ===
                                                        gameName?.name
                                                        ? result || 0
                                                        : 0}
                                                </span>
                                              </div>
                                              <div className='bg-[#FFE4B4] text-base px-2 py-2 text-right text-green-800 font-semibold'>
                                                {ele?.name === gameName?.name
                                                  ? totalAmountResponse || 0
                                                  : 0}
                                                {ele?.shortName ===
                                                  'akbar_open'
                                                  ? numberListData?.openbetsAmoutRes
                                                  : ele?.shortName ===
                                                    'akbar_close'
                                                    ? totalAmountResponse
                                                    : ele?.name ===
                                                      gameName?.name
                                                      ? totalAmountResponse || 0
                                                      : 0}
                                              </div>
                                              <div className='bg-[#FFE4B4] text-base px-2 py-2 text-right text-[#2D3D24] font-semibold'>
                                                {ele?.shortName ===
                                                  'akbar_open'
                                                  ? singleOpenSum
                                                  : ele?.shortName ===
                                                    'akbar_close'
                                                    ? singleCloseSum
                                                    : ele?.name ===
                                                      gameName?.name
                                                      ? singleSum || 0
                                                      : 0}
                                              </div>
                                              <div className='bg-[#FFE4B4] text-base px-2 py-2 text-right text-[#DC64A2] font-semibold'>
                                                {ele?.shortName ===
                                                  'akbar_close'
                                                  ? jodiCloseSum
                                                  : ele?.name ===
                                                    gameName?.name
                                                    ? jodiSum || 0
                                                    : 0}
                                              </div>
                                              <div className='bg-[#FFE4B4] text-base px-2 py-2 text-right font-semibold'>
                                                {ele?.name === gameName?.name
                                                  ? totalSingleAmount || 0
                                                  : 0}
                                              </div>
                                            </div>
                                          </>
                                        )
                                      })
                                      : // openSum
                                      null}
                                    <div className='grid grid-cols-6 gap-1 my-1'>
                                      <div className='px-2 py-2 flex justify-between items-center col-span-2 font-semibold'>
                                        <span className='textcolor text-base'></span>
                                        <span className='text-green-800 text-base'></span>
                                      </div>
                                      <div className='bg-black text-base px-1 py-1 text-right text-white font-semibold'>
                                        {totalAmountResponse ?? 0}
                                      </div>
                                      <div className='bg-black text-base px-1 py-1 text-right text-white font-semibold'>
                                        {gameName?.shortName === 'akbar_open'
                                          ? Number(singleOpenSum) +
                                          Number(singleCloseSum)
                                          : gameName?.shortName ===
                                            'akbar_close'
                                            ? Number(singleOpenSum) +
                                            Number(singleCloseSum)
                                            : singleSum || 0}
                                      </div>
                                      <div className='bg-black text-base px-1 py-1 text-right text-white font-semibold'>
                                        {gameName?.shortName === 'akbar_close'
                                          ? jodiCloseSum
                                          : gameName?.name
                                            ? jodiSum || 0
                                            : 0}
                                      </div>
                                      <div className='text-base px-2 py-2 text-right'></div>
                                    </div>
                                  </div>

                                  <div class='bg-gray-100 p-4 rounded-lg'>
                                    <p class='text-center font-bold text-sm mb-2'>
                                      SUMMARY
                                    </p>
                                    <div class='flex justify-between text-base border-b py-1'>
                                      <span className='textcolor font-semibold'>
                                        Total Sale
                                      </span>
                                      <span className='font-semibold text-green-800'>
                                        {totalAmountResponse}
                                      </span>
                                    </div>
                                    <div class='flex justify-between text-base border-b py-1'>
                                      <span className='textcolor font-semibold'>
                                        O/C Prize Detail
                                      </span>
                                      <span className='text-right text-[#2A0037] font-semibold'>
                                        {singleSum ?? 0} /
                                      </span>
                                      <span className='font-semibold text-green-800'>
                                        {singleSum * 9 ?? 0}
                                      </span>
                                    </div>
                                    <div class='flex justify-between text-base border-b py-1'>
                                      <span className='textcolor font-semibold'>
                                        Akra Prize Detail
                                      </span>
                                      <span className='text-right text-[#2A0037] font-semibold'>
                                        {jodiSum ?? 0} /
                                      </span>
                                      <span className='font-semibold text-green-800'>
                                        {jodiSum * 90 ?? 0}
                                      </span>
                                    </div>
                                    <div className='flex justify-between text-base border-b py-1'>
                                      <span className='textcolor font-semibold'>
                                        Gross Amount
                                      </span>
                                      <span className='text-right text-[#2A0037] font-semibold'>
                                        {grossAmount > 0 ? `Har / ` : `Jeet /`}
                                      </span>
                                      <span className='font-semibold text-green-800'>
                                        {grossAmount > 0
                                          ? `${0}`
                                          : `${Math.abs(grossAmount)}`}
                                      </span>
                                    </div>
                                    <div className='flex justify-between text-base border-b py-1'>
                                      <span className='textcolor font-semibold'>
                                        Less % Percent
                                      </span>
                                      <span className='text-right text-[#2A0037] font-semibold'>
                                        {' '}
                                        {0} % /{' '}
                                      </span>
                                      <span className='font-semibold text-green-800'>
                                        {Math.abs(grossAmount) && 0
                                          ? (Math.abs(grossAmount) * 0) / 100
                                          : 0}
                                      </span>
                                    </div>
                                    <div className='flex justify-between text-base border-b py-1'>
                                      <span className='textcolor font-semibold'>
                                        Final % Net
                                      </span>
                                      <span className='text-right text-[#2A0037] font-semibold'>
                                        {100 - (0 ?? 0)} % /{' '}
                                      </span>
                                      <span className='font-semibold text-green-800'>
                                        {Math.abs(grossAmount) && 0
                                          ? (
                                            (Math.abs(grossAmount) *
                                              (100 - 0)) /
                                            100
                                          )?.toFixed(2)
                                          : 0}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BetNumber
