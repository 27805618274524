/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { gamesActions, walletActions, userActions } from '../../_actions'
import { IoWalletOutline } from 'react-icons/io5'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import Loader from '../../components/Loader/Loader'

const AddFund = () => {
  const dispatch = useDispatch()
  const [fieldsGame, setFieldsGame] = useState({})
  const [errorsGame, setErrorsGame] = useState({})
  const selector = useSelector(state => state)
  const { games, wallet } = selector ? selector : {}
  let { allUserList } = games ? games : {}
  let { loading } = wallet ? wallet : {}

  useEffect(() => {
    dispatch(gamesActions.getAllUser())
    dispatch(userActions.getAdminProfile())
  }, [])

  const inputChangeCreate = e => {
    e.preventDefault()
    const { name, value } = e.target
    setFieldsGame(prevState => ({ ...prevState, [name]: value }))
    setErrorsGame(prevState => ({ ...prevState, [name]: '' }))
  }

  const createGameSubmit = e => {
    e.preventDefault()
    if (handleValidationCreateGame()) {
      const { userId, amount } = fieldsGame
      dispatch(
        walletActions.depositeBalanceByAdmin({
          userId: userId,
          amount: amount
        })
      )
    }
  }

  const handleValidationCreateGame = () => {
    let formIsValid = true
    let errors = {}

    if (!fieldsGame.userId || fieldsGame.userId === '') {
      formIsValid = false
      errors.userId = 'Select User'
    }

    if (!fieldsGame.amount || fieldsGame.amount === '') {
      formIsValid = false
      errors.amount = 'Enter amount'
    }

    setErrorsGame(errors)
    return formIsValid
  }
  let [localStoragedata, setlocalStoragedata] = useState()
  useEffect(() => {
    const stateData = window.sessionStorage.getItem('adminuser')
    setlocalStoragedata(JSON.parse(stateData))
  }, [])

  let { users } = selector ? selector : {}
  let { adminProfileDetails } = users ? users : {}
  let { balance } = adminProfileDetails ? adminProfileDetails : {}

  return (
    <>
      <Loader loading={loading} />
      <div className='z-0 overflow-hidden overflow-y-auto'>
        <div className='relative'>
          <div className='relative z-20 flex'>
            <div className='flex w-full overflow-hidden '>
              <div className='flex flex-col flex-1 overflow-hidden'>
                <main className='relative flex-1 '>
                  <div className='p-3 sm:p-5'>
                    <div className='mx-auto max-w-screen-3xl'>
                      <div className='bg-white mx-auto rounded-xl'>
                        <div className='flex justify-between items-center px-3'>
                          {localStoragedata &&
                            localStoragedata?.roleId !== 0 ? (
                            <span>
                              <span className='flex justify-center items-center bgcolor font-semibold text-white px-3 py-1 rounded-lg'>
                                <IoWalletOutline className='w-5 h-5' />
                                <span className='pl-3'>{balance}</span>
                              </span>
                            </span>
                          ) : (
                            ''
                          )}
                        </div>

                        <div className='p-5 space-y-2'>
                          <div className='grid md:grid-cols-3 grid-cols-1 gap-4'>
                            <div className='shadow-sm w-full'>
                              <label
                                class='block mb-2 text-base font-bold text-gray-900'                               >
                                User List :
                              </label>
                              <select
                                className='w-full px-3 py-2.5 text-base font-semibold bg-white border capitalize border-gray-400 rounded-lg focus:outline-none'
                                id='userId'
                                name='userId'
                                placeholder='name'
                                value={
                                  fieldsGame && fieldsGame['userId']
                                    ? fieldsGame['userId']
                                    : ''
                                }
                                type='text'
                                onChange={inputChangeCreate}
                              >
                                <option value=''>Select User</option>

                                {allUserList && allUserList.length > 0
                                  ? allUserList.map((element, index) => (
                                    <option
                                      value={
                                        element && element._id
                                          ? element._id
                                          : ''
                                      }
                                    >
                                      {element && element.userName
                                        ? element.userName
                                        : ''}
                                    </option>
                                  ))
                                  : null}
                              </select>
                              {errorsGame && errorsGame['userId'] ? (
                                <div className='text-yellow-600 invalid-feedback'>
                                  {errorsGame['userId']}
                                </div>
                              ) : null}
                            </div>
                            <div className='w-full shadow-sm'>
                              <label
                                class='block mb-2 text-base font-bold text-gray-900'
                              >
                                Amount :
                              </label>
                              <input
                                type='number'
                                className='w-full px-3 py-2 text-base font-semibold bg-white border border-gray-400 rounded-lg focus:outline-none'
                                id='amount'
                                name='amount'
                                placeholder='Enter Amount'
                                value={
                                  fieldsGame && fieldsGame['amount']
                                    ? fieldsGame['amount']
                                    : ''
                                }
                                onChange={inputChangeCreate}
                              />
                              {errorsGame && errorsGame['amount'] ? (
                                <div className='text-yellow-600 invalid-feedback'>
                                  {errorsGame['amount']}
                                </div>
                              ) : null}
                            </div>

                            <div className='w-full mt-7'>
                              <button
                                className='w-full lg:w-48 h-12 px-4 text-center text-base font-bold text-white capitalize transition duration-150 ease-in-out all-btn rounded focus:outline-none'
                                type='button'
                                onClick={createGameSubmit}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddFund
