import React, { useState } from 'react'
import { alertActions, userActions } from '../../_actions'
import { useDispatch } from 'react-redux'
import moment from 'moment'

export default function GlobalDateSetUp({ setopenmodel, openmodel, DateTheValue }) {
    const [fieldslogin, setFieldsLogin] = useState({})
    const dispatch = useDispatch()
    const [errorslogin, setErrorsLogin] = useState({})
    const handleClose = () => {
        setopenmodel(false)
        setFieldsLogin({})
    }
    const inputChange = e => {
        e.preventDefault()
        const { name, value } = e.target
        setFieldsLogin(prevState => ({ ...prevState, [name]: value }))
        setErrorsLogin(prevState => ({ ...prevState, [name]: '' }))
    }

    const handleSubmit = e => {
        e.preventDefault()
        if (handleValidationLogin()) {
            const data = {
                globalTimeStamp: moment(fieldslogin.globalTimeStamp).format("DD-MM-YYYY")
            }
            userActions.setGlobalDate(data).then(res => {
                if (res.data) {
                    dispatch(alertActions.success("Global timestamp set successfully"))
                    handleClose()
                    setFieldsLogin({})
                }
            }).catch((err) => {
                dispatch(alertActions.error(err))
            })
        }
    }

    const handleValidationLogin = () => {
        let errorslogin = {}
        let formIsValid = true
        if (!fieldslogin.globalTimeStamp || fieldslogin.globalTimeStamp === '') {
            formIsValid = false
            errorslogin.globalTimeStamp = 'globalTimeStamp Cannot Be Blank.'
        }

        // Clear Confirm Password error if everything else is valid
        if (formIsValid) {
            errorslogin.confirmPassword = ''
        }

        setErrorsLogin(errorslogin)
        return formIsValid
    }
    // const today = moment().format("YYYY-MM-DD");

    return (
        <div>
            {openmodel && (
                <div className='fixed inset-0 flex items-center justify-center z-50 bg-[#911212] bg-opacity-50'>
                    <div className='bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto'>
                        <h2 className='text-2xl font-bold mb-4'>Game Date SetUp</h2>
                        <form onSubmit={handleSubmit}>
                            <div>
                                <div className='form-group'>
                                    <label
                                        className='text-[#363063]  text-base'
                                        htmlFor='globalTimeStamp'
                                    >
                                        Date Select*
                                    </label>
                                    <input
                                        id='globalTimeStamp'
                                        name="globalTimeStamp"
                                        className='w-full border uppercase border-gray-300 p-2.5 focus:outline-none text-base md:text-base font-medium text-gray-800 rounded'
                                        type='Date'
                                        value={fieldslogin?.globalTimeStamp || DateTheValue}
                                        onChange={inputChange}
                                    />
                                </div>
                                {errorslogin && errorslogin['globalTimeStamp'] ? (
                                    <div className='flex justify-center'>
                                        <div className='text-red-700 w-full '>
                                            {errorslogin['globalTimeStamp']}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div className='flex mt-2 items-center justify-between'>
                                <button
                                    type='button'
                                    onClick={handleClose}
                                    className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                                >
                                    Cancel
                                </button>
                                <button
                                    type='submit'
                                    className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                                >
                                    Save Changes
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    )
}