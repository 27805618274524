/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { gamesActions, reportActions } from '../../_actions'
import Loader from '../../components/Loader/Loader'
import moment from 'moment'

const UserProfilLoss = () => {
  const dispatch = useDispatch()
  const { reports } = useSelector(state => state) || {}
  let { crdt22 } = reports ? reports : {}
  const [fieldsGame, setFieldsGame] = useState({})
  const [errorsGame, setErrorsGame] = useState({})
  const today = moment().format('YYYY-MM-DD')
  const keyWord = ''
  const fromDate = ''
  const toDate = ''
  const [userDetails, setUserDetails] = useState({})
  const { subAdminData } = useSelector(state => state.games)

  useEffect(() => {
    let gameReq2 = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: '',
      sortOrder: '',
      pageNo: 1,
      size: 500
    }
    dispatch(gamesActions.getAllGames(gameReq2))
    dispatch(reportActions.getLedgerforUser())
  }, [])

  const createGameSubmit = async e => {
    e.preventDefault()
    if (handleValidationCreateGame()) {
      const yesterday = moment(fieldsGame?.date ?? today)
        .subtract(1, 'days')
        .format('YYYY-MM-DD')
      let game = {
        date: fieldsGame?.date ?? today,
        userId: fieldsGame?.userId
      }
      let game1 = {
        date: yesterday,
        userId: fieldsGame?.userId
      }

      dispatch(reportActions.getGamePaymentsForSubAdmin(game))
      dispatch(reportActions.getGamePaymentsForSubAdmin1(game1))
    }
  }
  const handleValidationCreateGame = () => {
    let formIsValid = true
    let errors = {}

    if (!fieldsGame.userId || fieldsGame.userId === '') {
      formIsValid = false
      errors.userId = 'Please Select Game Name'
    }

    setErrorsGame(errors)
    return formIsValid
  }

  const inputChangeCreate = e => {
    e.preventDefault()

    const { name, value } = e.target
    setFieldsGame(prevState => ({ ...prevState, [name]: value }))
    setErrorsGame(prevState => ({ ...prevState, [name]: '' }))

    if (name === 'userId') {
      const details = subAdminData?.find(ele => ele._id === value)
      setUserDetails(details)
    }
  }

  let { getGamePayments, getGamePayments1, loading } = reports ? reports : []
  const filterJodiAk = getGamePayments?.find(ele => ele?.gameName === 'ak open')
  const akOpenJodi = filterJodiAk?.totalJodiAmount ?? 0
  const totalBetsAmountSum1 = (getGamePayments1 || []).reduce(
    (sum, record) => sum + record.totalBetsAmount,
    0
  )
  const totalSingleCloseAmountSum1 = (getGamePayments1 || []).reduce(
    (sum, record) => sum + record.totalSingleCloseAmount,
    0
  )
  const totalSingleOpenAmountSum1 = (getGamePayments1 || []).reduce(
    (sum, record) => sum + record.totalSingleOpenAmount,
    0
  )
  const totalJodiAmountSum1 = (getGamePayments1 || []).reduce(
    (sum, record) => sum + record.totalJodiAmount,
    0
  )
  const commDetails1 =
    (Number(totalBetsAmountSum1 ?? 0) * Number(userDetails?.comm ?? 0)) / 100
  const totalBetsAmountSum = (getGamePayments || []).reduce(
    (sum, record) => sum + record.totalBetsAmount,
    0
  )
  const totalSingleCloseAmountSum = (getGamePayments || []).reduce(
    (sum, record) => sum + record.totalSingleCloseAmount,
    0
  )
  const totalSingleOpenAmountSum = (getGamePayments || []).reduce(
    (sum, record) => sum + record.totalSingleOpenAmount,
    0
  )
  const totalBetsOpenClose1Sum =
    Number(totalSingleCloseAmountSum) + Number(totalSingleOpenAmountSum)
  const totalJodiAmountSum = (getGamePayments || []).reduce(
    (sum, record) => sum + record.totalJodiAmount,
    0
  )
  const prizeDetail =
    Number(totalSingleCloseAmountSum ?? 0) +
    Number(totalSingleOpenAmountSum ?? 0)
  const commDetails =
    (Number(totalBetsAmountSum ?? 0) * Number(userDetails?.comm ?? 0)) / 100
  const harPrize =
    Number(totalBetsAmountSum ?? 0) -
    (Number(totalSingleCloseAmountSum ?? 0) * 9 +
      Number(totalSingleOpenAmountSum ?? 0) * 9 +
      Number(totalJodiAmountSum ?? 0) * 90 +
      commDetails)
  const installment =
    harPrize +
    ((Number(totalBetsAmountSum1 ?? 0) -
      (Number(totalSingleCloseAmountSum1 ?? 0) * 9 +
        Number(totalSingleOpenAmountSum1 ?? 0) * 9 +
        Number(totalJodiAmountSum1 ?? 0) * 90 +
        commDetails1)) *
      (100 - userDetails?.share ?? 0)) /
    100
  const akraPrizeDetail = Number(totalJodiAmountSum ?? 0)

  return (
    <>
      <Loader loading={loading} />
      <div className='z-0 overflow-hidden overflow-y-auto focus:outline-none bg-gray-50'>
        <div className='relative z-20 flex min-h-screen'>
          <div className='flex w-full overflow-hidden '>
            <div className='flex flex-col flex-1 overflow-hidden'>
              <main className='relative flex-1'>
                <div className='p-3 sm:p-5'>
                  <div className='space-y-4'>
                    <div className='flex justify-between sm:flex-nowrap gap-1 flex-wrap items-end w-full'>
                      <div className='w-full'>
                        <label class='block text-gray-700 text-base mb-1'>
                          Date
                        </label>
                        <input
                          className={`w-full px-3 py-1.5 text-base bg-white border border-gray-400 uppercase rounded-md focus:outline-none`}
                          id='date'
                          name='date'
                          placeholder='Enter Date'
                          value={
                            fieldsGame && fieldsGame['date']
                              ? fieldsGame['date']
                              : ''
                          }
                          max={today}
                          type='date'
                          onChange={inputChangeCreate}
                        />
                        {errorsGame && errorsGame['date'] ? (
                          <div className='text-red-600 text-base invalid-feedback'>
                            {errorsGame['date']}
                          </div>
                        ) : null}
                      </div>

                      <div className='w-full'>
                        <label
                          class='block text-gray-700 text-base mb-1'
                          for='name'
                        >
                          User Name :
                        </label>
                        <select
                          className='w-full px-3 py-2.5 text-base bg-white border border-gray-400 rounded-md focus:outline-none'
                          id='userId'
                          name='userId'
                          placeholder='name'
                          value={
                            fieldsGame && fieldsGame['userId']
                              ? fieldsGame['userId']
                              : ''
                          }
                          type='text'
                          onChange={inputChangeCreate}
                        >
                          <option value=''>Select User Name</option>
                          {crdt22 && crdt22.length > 0
                            ? crdt22.map((element, index) => (
                              <option
                                value={
                                  element && element.userId
                                    ? element.userId
                                    : ''
                                }
                              >
                                {element && element.userName
                                  ? element.userName
                                  : ''}
                              </option>
                            ))
                            : null}
                        </select>
                      </div>

                      <div className='w-full'>
                        <button
                          className='w-full px-4 py-1 text-base font-semibold text-white capitalize transition duration-150 ease-in-out all-btn rounded-xl focus:outline-none'
                          type='button'
                          onClick={createGameSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                    {errorsGame && errorsGame['userId'] ? (
                      <p className='text-red-600 text-sm'>
                        {errorsGame['userId']}
                      </p>
                    ) : null}
                    {getGamePayments ? (
                      <div class='bg-white shadow-md rounded-lg w-full p-4'>
                        <div class='flex space-x-4 text-xl font-semibold border-b pb-2 mb-4'>
                          <p className='capitalize'> Draw Detail of</p>
                          <p>
                            {moment(fieldsGame?.date).format('DD-MM-YYYY') ??
                              moment(today).format('DD-MM-YYYY')}
                          </p>
                          <p className='capitalize'>
                            {' '}
                            {userDetails?.userName ?? null}
                          </p>
                        </div>

                        <div className='grid lg:grid-cols-2 grid-cols-1 gap-4'>
                          <div className=''>
                            <div className='grid grid-cols-6 border text-white text-base'>
                              <div className='bgcolor px-2 py-2 font-semibold col-span-2'>
                                Mark
                              </div>
                              <div className='bgcolor px-2 py-2 font-semibold text-center'>
                                Total Sale
                              </div>
                              <div className='bgcolor px-2 py-2 font-semibold text-center'>
                                Op . Cz
                              </div>
                              <div className='bgcolor px-2 py-2 font-semibold text-center'>
                                Akara
                              </div>
                              <div className='bgcolor px-2 py-2 font-semibold text-center'>
                                Remaining
                              </div>
                            </div>
                            {getGamePayments && getGamePayments.length > 0
                              ? getGamePayments.map(ele => {
                                const totalOpenCloseAmount =
                                  Number(ele?.totalSingleCloseAmount ?? 0) +
                                  Number(ele?.totalSingleOpenAmount ?? 0)
                                const totalSingleAmount =
                                  Number(ele?.totalSingleCloseAmount ?? 0) *
                                  9 +
                                  Number(ele?.totalSingleOpenAmount ?? 0) *
                                  9 +
                                  Number(ele?.totalJodiAmount ?? 0) * 90
                                const profitOrLoss =
                                  ele?.gameName === 'ak open'
                                    ? Number(
                                      ele?.totalSingleOpenAmount ?? 0
                                    ) * 9
                                    : ele?.gameName === 'akc.close'
                                      ? Number(
                                        ele?.totalSingleCloseAmount ?? 0
                                      ) *
                                      9 +
                                      Number(akOpenJodi) * 90
                                      : totalSingleAmount
                                return (
                                  <>
                                    <div className='grid grid-cols-6 gap-1 my-1 border'>
                                      <div className='bg-[#C0C0C0] px-2 py-2 flex justify-between items-center col-span-2 border border-black font-semibold'>
                                        <span className='textcolor text-base'>
                                          {ele?.gameName ?? null}
                                        </span>
                                        <span className='text-green-800 text-base'>
                                          {ele?.result ?? null}
                                        </span>
                                      </div>
                                      <div className='bg-[#FFE4B4] text-base px-2 py-2 text-right text-green-800 font-semibold'>
                                        {ele?.totalBetsAmount ?? 0}
                                      </div>
                                      <div className='bg-[#FFE4B4] text-base px-2 py-2 text-right text-[#2D3D24] font-semibold'>
                                        {totalOpenCloseAmount}
                                      </div>
                                      <div className='bg-[#FFE4B4] text-base px-2 py-2 text-right text-[#DC64A2] font-semibold'>
                                        {ele?.gameName === 'ak open'
                                          ? 0
                                          : ele?.gameName === 'akc.close'
                                            ? akOpenJodi
                                            : ele?.totalJodiAmount ?? 0}
                                      </div>
                                      <div className='bg-[#FFE4B4] text-base px-2 py-2 text-right  font-semibold'>
                                        {profitOrLoss}
                                      </div>
                                    </div>
                                  </>
                                )
                              })
                              : null}
                            <div className='grid grid-cols-6 gap-1 my-1'>
                              <div className='px-2 py-2 flex justify-between items-center col-span-2 font-semibold'>
                                <span className='textcolor text-base'></span>
                                <span className='text-green-800 text-base'></span>
                              </div>
                              <div className='bg-black text-base px-1 py-1 text-right text-white font-semibold'>
                                {totalBetsAmountSum}
                              </div>
                              <div className='bg-black text-base px-1 py-1 text-right text-white font-semibold'>
                                {totalBetsOpenClose1Sum}
                              </div>
                              <div className='bg-black text-base px-1 py-1 text-right text-white font-semibold'>
                                {totalJodiAmountSum}
                              </div>
                              <div className='text-base px-2 py-2 text-right'></div>
                            </div>
                          </div>

                          <div class='bg-gray-100 p-4 rounded-lg'>
                            <p class='text-center font-bold text-lg mb-2'>
                              SUMMARY
                            </p>
                            <div class='flex justify-between text-base border-b py-1'>
                              <span className='textcolor font-semibold'>
                                Total Sale
                              </span>
                              <span className='font-semibold text-green-800'>
                                {totalBetsAmountSum}
                              </span>
                            </div>
                            <div class='flex justify-between text-base border-b py-1'>
                              <span className='textcolor font-semibold'>
                                O/C Prize Detail
                              </span>
                              <span className='text-right text-[#2A0037] font-semibold'>
                                {prizeDetail} /
                              </span>
                              <span className='font-semibold text-green-800'>
                                {Number(prizeDetail ?? 0) * 9}
                              </span>
                            </div>
                            <div class='flex justify-between text-base border-b py-1'>
                              <span className='textcolor font-semibold'>
                                Akra Prize Detail
                              </span>
                              <span className='text-right text-[#2A0037] font-semibold'>
                                {akraPrizeDetail} /
                              </span>
                              <span className='font-semibold text-green-800'>
                                {Number(akraPrizeDetail ?? 0) * 90}
                              </span>
                            </div>
                            {/* <div class="flex justify-between text-base border-b py-1">
                              <span className='textcolor font-semibold'>Comm. Detail</span>
                              <span className='text-right text-[#2A0037] font-semibold'>{userDetails?.comm ?? 0} % /</span>
                              <span className='font-semibold text-green-800'> {commDetails}</span>
                            </div> */}
                            <div className='flex justify-between text-base border-b py-1'>
                              <span className='textcolor font-semibold'>
                                Gross Amount
                              </span>
                              <span className='text-right text-[#2A0037] font-semibold'>
                                {harPrize > 0 ? `Har / ` : `Jeet /`}
                              </span>
                              <span className='font-semibold text-green-800'>
                                {harPrize > 0
                                  ? `${harPrize}`
                                  : `${Math.abs(harPrize)}`}
                              </span>
                            </div>
                            <div className='flex justify-between text-base border-b py-1'>
                              <span className='textcolor font-semibold'>
                                Less % Percent
                              </span>
                              <span className='text-right text-[#2A0037] font-semibold'>
                                {' '}
                                {userDetails?.share ?? 0} % /{' '}
                              </span>
                              <span className='font-semibold text-green-800'>
                                {Math.abs(harPrize) && userDetails?.share
                                  ? (Math.abs(harPrize) * userDetails.share) /
                                  100
                                  : 0}
                              </span>
                            </div>
                            <div className='flex justify-between text-base border-b py-1'>
                              <span className='textcolor font-semibold'>
                                Final % Net
                              </span>
                              <span className='text-right text-[#2A0037] font-semibold'>
                                {100 - (userDetails?.share ?? 0)} % /{' '}
                              </span>
                              <span className='font-semibold text-green-800'>
                                {Math.abs(harPrize) && userDetails?.share
                                  ? (
                                    (Math.abs(harPrize) *
                                      (100 - userDetails.share)) /
                                    100
                                  )?.toFixed(2)
                                  : 0}
                              </span>
                            </div>
                            {/* <div className="flex justify-between text-base border-b py-1">
                              <span className='textcolor font-semibold'>Installment</span>
                              <span className='font-semibold text-green-800'>
                                {installment?.toFixed(2) ?? 0}
                              </span>
                            </div> */}
                            <div class='flex justify-between text-base py-1'>
                              <span className='textcolor font-semibold'>
                                Final Amount
                              </span>
                              <span className='font-semibold text-green-800'>
                                {Math.abs(harPrize) && userDetails?.share
                                  ? (
                                    (Math.abs(harPrize) *
                                      (100 - userDetails.share)) /
                                    100 +
                                    installment
                                  )?.toFixed(2)
                                  : 0}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserProfilLoss
