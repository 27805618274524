/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TfiEye } from "react-icons/tfi";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ReactPaginate from 'react-paginate';
import { isMobile } from "react-device-detect";
import ViewGameModal from './components/ViewGameModal/ViewGameModal';
import Loader from '../../../components/Loader/Loader';
import { gamesActions, walletActions } from '../../../_actions';
import { Tooltip } from "react-tooltip";
import moment from 'moment';



const AddFundReport2 = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  let [rowData, setRowData] = useState(null);
  let [viewModal, setViewModal] = useState(false);
  const errorsGame = {}
  const size = 10
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");

  const { paymentList, totalPayment, loading } = useSelector(state => state.wallet);


  useEffect(() => {
    let gameReq = {
      "fromDate": fromDate,
      "toDate": toDate,
      "keyWord": "",
      "sortBy": "",
      "sortOrder": "",
      "type": "DEPOSIT",
      "pageNo": pageNo,
      "size": size
    }
    dispatch(walletActions.getPaymentListForAdmin(gameReq));


  }, []);




  const handleViewOpenModal = (data) => {
    setViewModal(true);
    setRowData(data);
  }

  const handleViewHideModal = (data) => {
    setViewModal(false);
    setRowData({});
  }


  const createGameSubmit2 = () => {
    let gameReq = {
      "fromDate": fromDate,
      "toDate": toDate,
      "keyWord": "",
      "sortBy": "",
      "sortOrder": "",
      "type": "DEPOSIT",
      "pageNo": pageNo,
      "size": size
    }
    if (fromDate !== "" && toDate !== "") {

      dispatch(walletActions.getPaymentListForAdmin(gameReq));
    }
  }


  const deleteGame = (data, elem) => {
    let gameIdReq = {
      "paymentId": data._id,
      "status": elem
    }

    let gamePageRefresh = {
      "fromDate": "",
      "toDate": "",
      "keyWord": "",
      "sortBy": "",
      "sortOrder": "",
      "type": "DEPOSIT",
      "pageNo": pageNo,
      "size": size
    }

    confirmAlert({

      title: elem === 1 ? 'Confirm to Approve?' : 'Confirm to Reject?',
      message: elem === 1 ? `Are you sure you want to Approve Deposit Fund of ${data?.userId?.userName}?` : `Are you sure you want to Reject Deposit Fund of ${data?.userId?.userName}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(gamesActions.approvePaymentByAdmin(gameIdReq, gamePageRefresh))
        },
        {
          label: 'No'
        }
      ]
    });
  }
  const deleteGame2 = (data, elem) => {
    let gameIdReq = {
      "paymentId": data._id,
      "status": elem
    }

    let gamePageRefresh = {
      "fromDate": "",
      "toDate": "",
      "keyWord": "",
      "sortBy": "",
      "sortOrder": "",
      "type": "DEPOSIT",
      "pageNo": pageNo,
      "size": size
    }

    confirmAlert({

      title: 'Confirm to Change Status?',
      message: `Are you sure you want to change Status of ${data?.userId?.userName}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(gamesActions.approvePaymentByAdmin(gameIdReq, gamePageRefresh))
        },
        {
          label: 'No'
        }
      ]
    });
    setViewModal(false)
  }


  const handlePageClick = (data) => {
    let offset = Math.ceil(data.selected * size);
    setCurrentPage(data.selected)
    setOffset(offset);
    setPageNo(data.selected + 1);
    let pageReq = {
      "fromDate": "",
      "toDate": "",
      "keyWord": "",
      "sortBy": "",
      "sortOrder": "",
      "type": "DEPOSIT",
      "pageNo": data.selected + 1,
      "size": size
    }
    dispatch(walletActions.getPaymentListForAdmin(pageReq));
  }

  const today = new Date().toISOString().split('T')[0];

  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1">
                  <div className="p-3 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl space-y-5">
                      <div className='flex justify-between items-center flex-wrap gap-3'>
                        <div className='flex items-center space-x-3 md:flex-nowrap flex-wrap'>
                          <div className="w-ful">
                            <label class="block mb-1 text-base font-medium text-gray-900">From:</label>
                            <input className="w-full px-3 py-1.5 text-lg font-semibold bg-white uppercase border border-gray-400 rounded focus:outline-none"
                              id="date" name="date" placeholder="Enter date" value={fromDate} type="date" onChange={(e) => setfromDate(e.target.value)} />
                            {errorsGame && errorsGame["date"] ?
                              <div className="text-yellow-600 invalid-feedback">
                                {errorsGame["date"]}
                              </div>
                              : null}
                          </div>
                          <div className="w-ful">
                            <label class="block mb-1 text-base font-medium text-gray-900">To:</label>
                            <input className="w-full px-3 py-1.5 text-lg font-semibold bg-white border uppercase border-gray-400 rounded focus:outline-none"
                              id="date" name="date" placeholder="Enter date" value={toDate} max={today} type="date" onChange={(e) => settoDate(e.target.value)} />
                            {errorsGame && errorsGame["date"] ?
                              <div className="text-yellow-600 invalid-feedback">
                                {errorsGame["date"]}
                              </div>
                              : null}
                          </div>
                          <div className="w-full pt-6">
                            <button className='flex w-full lg:w-48 justify-center px-4 py-0.5 text-base font-semibold text-white capitalize transition duration-150 ease-in-out all-btn rounded focus:outline-none' type="button" onClick={createGameSubmit2}>Submit</button>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg border">
                          <table className="w-full text-base text-left text-gray-600 ">
                            <thead className="text-gray-900 text-base capitalize bgcolortb">
                              <tr>
                                <th scope="col" className="px-6 py-3">#</th>
                                <th scope="col" className="px-6 py-3">user name</th>
                                <th scope="col" className="px-6 py-3">amount</th>
                                <th scope="col" className="px-6 py-3">Receipt Image</th>
                                <th scope="col" className="px-6 py-3">Date</th>
                                <th scope="col" className="px-6 py-3">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {paymentList && paymentList.length > 0 ? paymentList.map((element, index) => {
                                return (
                                  element.status === 0 && <React.Fragment key={index}>
                                    <tr key={index} className={`bg-white border-b border-gray-400 font-semibold`}>
                                      <th scope="row" className="px-6 py-3 text-gray-600 whitespace-nowrap ">
                                        {offset + index + 1}
                                      </th>
                                      <th scope="row" className="px-6 py-3 text-gray-600 whitespace-nowrap ">
                                        {element && element.userId ? element?.userId?.userName : "-"}
                                      </th>
                                      <th scope="row" className="px-6 py-3 text-gray-600 whitespace-nowrap ">
                                        {element && element.amount ? element.amount : "-"}
                                      </th>

                                      <td className="px-6 ml-6 flex justify-start py-3">
                                        <TfiEye onClick={() => handleViewOpenModal(element)} className=' text-3xl text-[#7D1970] cursor-pointer' data-tooltip-id="edit-tooltip" data-tooltip-content="Receipt Image" />
                                        <Tooltip id="edit-tooltip" place="top" className="z-50" />
                                      </td>
                                      <th scope="row" className="px-6 py-3 text-gray-600 whitespace-nowrap ">
                                        {/* {element && element.createdAt ? new Date(element.createdAt).toLocaleString() : "-"} */}
                                        {moment(element?.createdAt).format("DD:MM:YYYY s:mm:hh a")}
                                      </th>
                                      <th scope="row" className="px-6 py-3 text-gray-600 whitespace-nowrap ">


                                        {element && element.status === 0 &&
                                          <>
                                            <span className="relative ">
                                              <button className="px-4 py-2 tracking-wider text-white bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-900" onClick={() => deleteGame(element, 1)}>Approve</button>
                                            </span>
                                            <span className="relative ">
                                              <button className="px-4 py-2 tracking-wider text-white bg-red-500 border rounded-md shadow-sm hover:shadow-lg hover:bg-red-700" onClick={() => deleteGame(element, 2)}>Reject</button>
                                            </span>
                                          </>
                                        }

                                      </th>

                                    </tr>
                                  </React.Fragment>
                                )
                              })
                                : <tr className='bg-white'>
                                  <td colSpan={6} className='text-center py-3 font-semibold text-slate-800'>No Data Found</td>
                                </tr>
                              }
                            </tbody>
                          </table>
                          {
                            isMobile ?
                              <nav className="relative z-0 flex justify-end mt-5 w-76">
                                {
                                  totalPayment && totalPayment > 10 ?
                                    <ReactPaginate
                                      previousLabel={'Prev'}
                                      nextLabel={'Next'}
                                      breakLabel={'...'}
                                      breakClassName={'break-me'}
                                      pageCount={totalPayment / size}
                                      marginPagesDisplayed={1}
                                      pageRangeDisplayed={1}
                                      onPageChange={handlePageClick}
                                      containerClassName={'pagination'}
                                      pageClassName={'page-cls'}
                                      activeClassName={'active'}
                                      forcePage={currentPage}
                                    />
                                    : null}
                              </nav> : <nav className="relative z-0 flex justify-end mt-5 w-76">
                                {
                                  totalPayment && totalPayment > 10 ?
                                    <ReactPaginate
                                      previousLabel={'Previous'}
                                      nextLabel={'Next'}
                                      breakLabel={'...'}
                                      breakClassName={'break-me'}
                                      pageCount={totalPayment / size}
                                      marginPagesDisplayed={3}
                                      pageRangeDisplayed={3}
                                      onPageChange={handlePageClick}
                                      containerClassName={'pagination'}
                                      pageClassName={'page-cls'}
                                      activeClassName={'active'}
                                      forcePage={currentPage}
                                    />
                                    : null}
                              </nav>
                          }
                        </div>

                      </div>
                    </div>
                  </div>

                  <ViewGameModal
                    rowData={rowData}
                    viewModal={viewModal}
                    handleViewHideModal={handleViewHideModal}
                    deleteGame2={deleteGame2}
                  />


                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddFundReport2;