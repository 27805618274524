/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardJSON from "./MarketManagement.json";
import { karachiMarketActions, userActions } from "../../_actions";
import CreateMarketModal from "./components/CreateMarketModal/CreateMarketModal";
import UpdateMarketModal from "./components/UpdateMarketModal/UpdateMarketModal";
import ViewMarketModal from "./components/ViewMarketModal/ViewMarketModal";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ReactPaginate from "react-paginate";
import { isMobile } from "react-device-detect";
import { FiEdit } from "react-icons/fi";
import Loader from "../../components/Loader/Loader";

const KarachiMarketManagement = () => {
  let addref = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [updateModal, setUpdateModal] = useState(false);
  let [createModal, setCreateModal] = useState(false);
  let [viewModal, setViewModal] = useState(false);
  const [fieldsUpdate, setFieldsUpdate] = useState({});
  const [errorsUpdate, setErrorsUpdate] = useState({});
  const [fieldsMarket, setFieldsMarket] = useState({});
  const [errorsMarket, setErrorsMarket] = useState({});
  const size = 1000
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const { karachiMarketList, marketTotal, loading } = useSelector(
    (state) => state.karachiMarket
  );

  useEffect(() => {
    let marketReq = {
      fromDate: "",
      toDate: "",
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
      isJackpot: false,
      isStarLine: false,
    };
    dispatch(karachiMarketActions.getKarachiMarketList(marketReq));
  }, []);


  const handleAddHideModal = (data) => {
    if (addref?.current) {
      addref.current.value = "";
    }
    setCreateModal(false);
    setFieldsMarket({});
    setErrorsMarket({});
    dispatch(userActions.clearImage());
  };


  const handleUpdateHideModal = (data) => {
    setUpdateModal(false);
    setErrorsUpdate({});
  };

  const handleViewHideModal = (data) => {
    setViewModal(false);
  };

  const inputChangeCreate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsMarket((prevState) => ({ ...prevState, [name]: value }));
    setErrorsMarket((prevState) => ({ ...prevState, [name]: "" }));
  };

  const inputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsUpdate((prevState) => ({ ...prevState, [name]: value }));
    setErrorsUpdate((prevState) => ({ ...prevState, [name]: "" }));
  };

  let selector = useSelector((state) => state);
  const createMarketSubmit = (e) => {
    let { users } = selector ? selector : {};
    let { fileData } = users ? users : {};
    e.preventDefault();
    if (handleValidationCreateMarket()) {
      let marketPageRefresh = {
        fromDate: "",
        toDate: "",
        keyWord: "",
        sortBy: "",
        sortOrder: "",
        pageNo: pageNo,
        size: size,
      };

      const { name, point, shortName } = fieldsMarket;
      dispatch(
        karachiMarketActions.createKarachiMarket(
          {
            name: name && name.trim(),
            image: fileData || " ",
            shortName: shortName && shortName.trim(),
            point: point,
            isOpenClose: true,
            numList: ["000", 111, 222, 333, 444, 555, 666, 777, 888, 999],
          },
          setCreateModal,
          marketPageRefresh
        )
      );
    }
  };

  const updateMarketSubmit = (e) => {
    e.preventDefault();
    if (handleValidationUpdateMarket()) {
      let marketPageRefresh = {
        fromDate: "",
        toDate: "",
        keyWord: "",
        sortBy: "",
        sortOrder: "",
        pageNo: pageNo,
        size: size,
      };

      const { name, shortName, _id } = fieldsUpdate;
      dispatch(
        karachiMarketActions.updateKarachiMarket(
          {
            name: name,
            image:
              "https://res.cloudinary.com/dnd2isyjo/image/upload/v1712923995/your_folder_name/cmbpkevpflbvf9l0c6v0.png",
            shortName: shortName,
            id: _id,
          },
          setUpdateModal,
          marketPageRefresh
        )
      );
    }
  };

  const handleValidationUpdateMarket = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsUpdate.name || fieldsUpdate.name.trim() === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    if (!fieldsUpdate.shortName || fieldsUpdate.shortName.trim() === "") {
      formIsValid = false;
      errors.shortName = DashboardJSON.ErrorMsg.shortName;
    }

    setErrorsUpdate(errors);
    return formIsValid;
  };

  const handleValidationCreateMarket = () => {
    let formIsValid = true;
    let errors = {};
    let { users } = selector ? selector : {};
    let { fileData } = users ? users : {};

    if (!fieldsMarket.name || fieldsMarket.name.trim() === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    if (!fieldsMarket.shortName || fieldsMarket.shortName.trim() === "") {
      formIsValid = false;
      errors.shortName = DashboardJSON.ErrorMsg.shortName;
    }

    if (!fieldsMarket.point || fieldsMarket.point.trim() === "") {
      formIsValid = false;
      errors.point = DashboardJSON.ErrorMsg.point;
    }
    if (!fileData || fileData.trim() === "") {
      formIsValid = false;
      errors.img = "Please upload image";
    }

    setErrorsMarket(errors);
    return formIsValid;
  };

  const disableMarket = (data) => {

    let marketIdReq = {
      id: data._id,
    };
    let marketPageRefresh = {
      fromDate: "",
      toDate: "",
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
    };
    confirmAlert({
      title: "Confirm to disable Market?",
      message: `Are you sure you want to disable ${data.name}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(
              karachiMarketActions.updateKarachiMarketStatus(marketIdReq, marketPageRefresh)
            ),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleFile = (event) => {
    if (event.target.files[0]) {
      dispatch(userActions.uploadImage(event));
      setErrorsMarket((prevState) => ({ ...prevState, img: "" }));
    } else {
      console.log("No File To Upload!");
    }
  };

  const addContentField = () => {
  };

  const handlePageClick = (data) => {
    let offset = Math.ceil(data.selected * size);
    setOffset(offset);
    setPageNo(data.selected + 1);
    let pageReq = {
      fromDate: "",
      toDate: "",
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: data.selected + 1,
      size: size,
    };

    dispatch(karachiMarketActions.getKarachiMarketList(pageReq));
  };

  const handleEditMarket = (url, element) => {
    navigate(url + element._id);
  };


  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3  sm:p-5">
                    <div className="mx-auto max-w-screen-3xl">
                      <section className="flex justify-between my-2 ">
                        {/* <p className="text-xl font-bold">
                          Karachi Market Management
                        </p> */}
                      </section>

                      <div>
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg border bg-white">
                          <table className="w-full text-base text-left">
                            <thead className="text-base capitalize bgcolortb">
                              <tr className="text-left text-lg font-bold">
                                <th className="px-3 py-2">#</th>
                                <th className="px-3 py-2">name</th>
                                <th className="px-3 py-2">Game Rate</th>
                                <th className="px-3 py-2">Status</th>
                                <th className="px-3 py-2">action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {karachiMarketList && karachiMarketList.length > 0
                                ? karachiMarketList.map((element, index) => (
                                  <React.Fragment key={index}>
                                    <tr
                                      key={index}
                                      className={
                                        (offset + index + 1) % 2 === 0
                                          ? "bg-white border-b text-left whitespace-nowrap text-lg uppercase"
                                          : "bg-gray-100 border-b text-left whitespace-nowrap text-lg uppercase"
                                      }
                                    >
                                      <th className="px-3 py-2">{offset + index + 1}</th>
                                      <th className="px-3 py-2 text-left">{element && element.name ? element.name : "-"}</th>
                                      <th className="px-3 py-2"> {element.name === "FS Ring" ? <div><div>{`1 ka ${element.fPoint}`} </div> <div>{`1 ka ${element.sPoint}`}</div></div> : `1 ka ${element && element.point ? element.point : "-"}`}</th>
                                      {/* <td className="px-3 py-2">
                                        <img src={element && element.image ? element.image : "-"} alt=" " className=" h-10 w-10" />
                                      </td> */}
                                      <td className="px-3 py-2">
                                        <button className={element.isDisable === false ? "px-4 text-base border border-green-700  bg-green-700 text-white rounded" : "px-4 py-1 text-base border border-red-700 text-white bg-red-700 rounded"} onClick={() => disableMarket(element)}>  {element && element.isDisable === false ? "Yes" : "No"}</button>
                                      </td>
                                      <td className="px-3 py-2">
                                        <span className="flex items-center justify-start">
                                          <FiEdit onClick={() => handleEditMarket("/app/EditKarachiMarket/", element)} title="Edit" className=" text-2xl text-blue-600 cursor-pointer" />
                                        </span>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))
                                : null}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  {isMobile ? (
                    <nav className="relative z-0 flex justify-end mt-5 w-76">
                      {marketTotal && marketTotal > size ? (
                        <ReactPaginate
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={marketTotal / size}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={1}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          pageClassName={"page-cls"}
                          activeClassName={"active"}
                        />
                      ) : null}
                    </nav>
                  ) : (
                    <nav className="relative z-0 flex justify-end mt-5 w-76">
                      {marketTotal && marketTotal > size ? (
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={marketTotal / size}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          pageClassName={"page-cls"}
                          activeClassName={"active"}
                        />
                      ) : null}
                    </nav>
                  )}
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateMarketModal
        createModal={createModal}
        handleAddHideModal={handleAddHideModal}
        handleFile={handleFile}
        inputChangeCreate={inputChangeCreate}
        fieldsMarket={fieldsMarket}
        errorsMarket={errorsMarket}
        createMarketSubmit={createMarketSubmit}
        addref={addref}
      />

      <UpdateMarketModal
        updateModal={updateModal}
        handleUpdateHideModal={handleUpdateHideModal}
        handleFile={handleFile}
        inputChange={inputChange}
        fieldsUpdate={fieldsUpdate}
        errorsUpdate={errorsUpdate}
        addContentField={addContentField}
        updateMarketSubmit={updateMarketSubmit}
      />

      <ViewMarketModal
        viewModal={viewModal}
        handleViewHideModal={handleViewHideModal}
      />
    </>
  );
};

export default KarachiMarketManagement;
