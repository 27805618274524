/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardJSON from './GameManagement.json'
import { gamesActions, marketActions, userActions } from '../../_actions'
import CreateGameModal from './components/CreateGameModal/CreateGameModal'
import UpdateGameModal from './components/UpdateGameModal/UpdateGameModal'
import ViewGameModal from './components/ViewGameModal/ViewGameModal'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import Loader from '../../components/Loader/Loader'
import moment from 'moment'
import { FiEdit } from 'react-icons/fi'
import { IoBarChartSharp } from 'react-icons/io5'
import { useNavigate } from 'react-router'
import { confirmAlert } from 'react-confirm-alert'

const GameManagement = () => {
  let addref = useRef(null)
  let addref2 = useRef(null)
  const [resetKey, setResetKey] = useState(0)
  const dispatch = useDispatch()
  let [createModal, setCreateModal] = useState(false)
  let [updateModal, setUpdateModal] = useState(false)
  let [viewModal, setViewModal] = useState(false)
  const [marketSelected, setMarketSelected] = useState([])
  const [fieldsUpdate, setFieldsUpdate] = useState({})
  const [errorsUpdate, setErrorsUpdate] = useState({})
  const [fieldsGame, setFieldsGame] = useState({})
  const [errorsGame, setErrorsGame] = useState({})
  const size = 10
  const offset = 0
  const pageNo = 1
  const options = [
    { name: 'option one', id: '1' },
    { name: 'option owo', id: '2' }
  ]
  const { gamesList, loading } = useSelector(state => state.games)
  const { allMarket } = useSelector(state => state.markets)

  useEffect(() => {
    let gameReq = {
      fromDate: '',
      toDate: '',
      keyWord: '',
      sortBy: '',
      sortOrder: '',
      pageNo: pageNo,
      size: size
    }
    dispatch(gamesActions.getAllGames(gameReq))
    dispatch(marketActions.getAllMarket())
  }, [])

  const handleCreateHideModal = () => {
    if (addref?.current?.value) {
      addref.current.value = ''
    }
    if (addref2?.current?.value) {
      addref2.current.value = ''
    }
    setCreateModal(false)
    setFieldsGame({})
    setErrorsGame({})
    setMarketSelected([])
    dispatch(userActions.clearImage())
  }

  const handleUpdateOpenModal = data => {
    setFieldsUpdate({
      ...data,
      closingTime: data['closingTime']
        ? moment.utc(data['closingTime'] * 1000).format('HH:mm')
        : '',
      resultTime: data['resultTime']
        ? moment.utc(data['resultTime'] * 1000).format('HH:mm')
        : '',
      openTime: data['openTime']
        ? moment.utc(data['openTime'] * 1000).format('HH:mm')
        : '',
      betClosingTime: data['betClosingTime']
        ? moment.utc(data['betClosingTime'] * 1000).format('mm:ss')
        : '',
      betOpenTime: data['betOpenTime']
        ? moment.utc(data['betOpenTime'] * 1000).format('mm:ss')
        : ''
    })
    let datas = data.marketList.map(e => ({ name: e.name, id: e._id }))
    dispatch(userActions.clearImage())
    setMarketSelected(datas)
    setUpdateModal(true)
  }

  const handleUpdateHideModal = data => {
    if (addref2?.current?.value) {
      addref2.current.value = ''
    }
    dispatch(userActions.clearImage())
    setUpdateModal(false)
    setErrorsUpdate({})
    setMarketSelected([])
  }

  const handleViewHideModal = data => {
    setViewModal(false)
  }

  const inputChangeUpdate = e => {
    e.preventDefault()
    const { name, value } = e.target
    setFieldsUpdate(prevState => ({ ...prevState, [name]: value }))
    setErrorsUpdate(prevState => ({ ...prevState, [name]: '' }))
  }

  const inputChangeCreate = e => {
    e.preventDefault()
    const { name, value } = e.target
    setFieldsGame(prevState => ({ ...prevState, [name]: value }))
    setErrorsGame(prevState => ({ ...prevState, [name]: '' }))
  }

  const updateGameSubmit = e => {
    e.preventDefault()
    if (handleValidationUpdateGame()) {
      let selectedMarketData =
        marketSelected && marketSelected.length > 0
          ? marketSelected.map(element => element.id)
          : null
      let { users } = selector ? selector : {}
      let { fileData } = users ? users : {}
      let gamePageRefresh = {
        fromDate: '',
        toDate: '',
        keyWord: '',
        sortBy: '',
        sortOrder: '',
        pageNo: pageNo,
        size: size
      }

      const {
        name,
        shortName,
        openTime,
        closingTime,
        resultTime,
        priority,
        color,
        backgroundColor,
        _id
      } = fieldsUpdate
      dispatch(
        gamesActions.updateGames(
          {
            id: _id,
            name: name && name.trim(),
            shortName: shortName && shortName.trim(),
            openTime: openTime,
            closingTime: closingTime,
            resultTime: resultTime,
            betOpenTime: '00:00',
            betClosingTime: '00:00',
            priority: priority,
            color: color,
            backgroundColor: backgroundColor && backgroundColor.trim(),
            marketList: selectedMarketData,
            image: fileData || fieldsUpdate?.image
          },
          setUpdateModal,
          gamePageRefresh
        )
      )
      if (addref2?.current?.value) {
        addref2.current.value = ''
      }
    }
  }

  let selector = useSelector(state => state)

  const createGameSubmit = e => {
    let { users } = selector ? selector : {}
    let { fileData } = users ? users : {}
    e.preventDefault()
    if (handleValidationCreateGame()) {
      let selectedmarketData =
        marketSelected && marketSelected.length > 0
          ? marketSelected.map(element => element.id)
          : null

      let gamePageRefresh = {
        fromDate: '',
        toDate: '',
        keyWord: '',
        sortBy: '',
        sortOrder: '',
        pageNo: pageNo,
        size: size
      }

      const {
        name,
        shortName,
        openTime,
        closingTime,
        resultTime,
        priority,
        color,
        max,
        min
      } = fieldsGame
      dispatch(
        gamesActions.createGames(
          {
            name: name && name.trim(),
            shortName: shortName && shortName.trim(),
            betOpenTime: '00:00',
            betClosingTime: '00:00',
            openTime: openTime,
            max: max,
            min: min,
            closingTime: closingTime,
            resultTime: resultTime,
            priority: priority,
            color: color,
            brandGame: false,
            backgroundColor: '',
            marketList: selectedmarketData,
            image: fileData ? fileData : ' '
          },
          gamePageRefresh
        )
      )
      handleCreateHideModal()
    }
  }

  const handleValidationUpdateGame = () => {
    let formIsValid = true
    let errors = {}
    let selectedmarketData =
      marketSelected && marketSelected.length > 0
        ? marketSelected.map(element => element._id)
        : null

    if (!selectedmarketData || selectedmarketData.length === 0) {
      formIsValid = false
      errors.marketSelected = 'Selected market data'
    }
    if (!fieldsUpdate.name || fieldsUpdate.name.trim() === '') {
      formIsValid = false
      errors.name = DashboardJSON.ErrorMsg.name
    }
    if (!fieldsUpdate.shortName || fieldsUpdate.shortName.trim() === '') {
      formIsValid = false
      errors.shortName = DashboardJSON.ErrorMsg.shortName
    }

    if (!fieldsUpdate.openTime || fieldsUpdate.openTime === '') {
      formIsValid = false
      errors.openTime = DashboardJSON.ErrorMsg.openTime
    }

    if (!fieldsUpdate.closingTime || fieldsUpdate.closingTime === '') {
      formIsValid = false
      errors.closingTime = DashboardJSON.ErrorMsg.closingTime
    }
    if (fieldsUpdate.openTime === fieldsUpdate.closingTime) {
      formIsValid = false
      errors.closingTime = 'Open Time , Close Time should be different'
      errors.openTime = 'Open Time , Close Time should be different'
    }

    if (!fieldsUpdate.priority || fieldsUpdate.priority === '') {
      formIsValid = false
      errors.priority = DashboardJSON.ErrorMsg.priority
    }
    setErrorsUpdate(errors)
    return formIsValid
  }

  const handleValidationCreateGame = () => {
    let formIsValid = true
    let errors = {}
    let selectedmarketData =
      marketSelected && marketSelected.length > 0
        ? marketSelected.map(element => element._id)
        : null

    if (!selectedmarketData || selectedmarketData.length === 0) {
      formIsValid = false
      errors.selectedmarketData = 'Selected market data'
    }

    if (!fieldsGame.name || fieldsGame.name.trim() === '') {
      formIsValid = false
      errors.name = DashboardJSON.ErrorMsg.name
    }

    if (fieldsGame.openTime === fieldsGame.closingTime) {
      formIsValid = false
      errors.closingTime = 'Open Time , Close Time should be different'
      errors.openTime = 'Open Time , Close Time should be different'
    }

    if (!fieldsGame.shortName || fieldsGame.shortName.trim() === '') {
      formIsValid = false
      errors.shortName = DashboardJSON.ErrorMsg.shortName
    }

    if (!fieldsGame.openTime || fieldsGame.openTime === '') {
      formIsValid = false
      errors.openTime = DashboardJSON.ErrorMsg.openTime
    }

    if (!fieldsGame.closingTime || fieldsGame.closingTime === '') {
      formIsValid = false
      errors.closingTime = DashboardJSON.ErrorMsg.closingTime
    }

    if (!fieldsGame.priority || fieldsGame.priority === '') {
      formIsValid = false
      errors.priority = DashboardJSON.ErrorMsg.priority
    }

    setErrorsGame(errors)
    return formIsValid
  }

  const handleFile = event => {
    if (event.target.files[0]) {
      dispatch(userActions.uploadImage(event))
      setErrorsGame(prevState => ({ ...prevState, img: '' }))
    } else {
      console.log('No File To Upload!')
    }
  }

  const onSelect = (selectedList, selectedItem) => {
    let data = selectedList.map(e => ({ name: e.name, id: e._id || e.id }))
    setMarketSelected(data)
    setErrorsGame(prevState => ({ ...prevState, selectedmarketData: '' }))
  }

  const onRemove = (selectedList, removedItem) => {
    let filtervalue =
      selectedList && selectedList.filter(item => item.id !== removedItem.id)
    setMarketSelected(filtervalue)
  }

  const navigate = useNavigate()


  const disableUser = (data) => {
    let userIdReq = {
      id: data._id,
    };

    let userPageRefresh = {
      fromDate: '',
      toDate: '',
      keyWord: '',
      sortBy: '',
      sortOrder: '',
      pageNo: pageNo,
      size: size
    };
    confirmAlert({
      title: `${data?.isDisable ? "Confirm to Enable Game?" : "Confirm to Disable Game?"
        }`,
      message: `Are you sure you want to ${data?.isDisable ? "Disable" : "Enable"
        } ${data?.name}?`,

      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(gamesActions.updateGamesStatus(userIdReq, userPageRefresh)),
        },
        {
          label: "No",
        },
      ],
    });
  };
  return (
    <>
      <Loader loading={loading} />
      <div className='z-0 overflow-hidden overflow-y-auto focus:outline-none'>
        <div className='relative'>
          <div className='relative z-20 flex min-h-screen'>
            <div className='flex w-full overflow-hidden '>
              <div className='flex flex-col flex-1 overflow-hidden'>
                <main className='relative flex-1 '>
                  <div className='p-3 sm:p-5'>
                    <div className='mx-auto max-w-screen-3xl'>
                      <section className='flex justify-between my-2'>
                        <div></div>
                        <div>
                          <span className='relative'>
                            {/* <button
                              className='px-4 py-1 font-normal lg:font-semibold text-base tracking-wider text-white all-btn rounded shadow-sm hover:shadow-lg w-44'
                              onClick={() => handleCreateOpenModal()}
                            >
                              Add
                            </button> */}
                          </span>
                        </div>
                      </section>

                      <div>
                        <div className='relative overflow-x-auto shadow-md sm:rounded-lg border'>
                          <table className='w-full text-base text-left rtl:text-right text-black'>
                            <thead className='text-base text-white capitalize bgcolortb'>
                              <tr>
                                <th className='px-2 text-[12px] lg:text-[16px] whitespace-nowrap py-2'>
                                  #
                                </th>
                                <th className='px-2 text-[12px] lg:text-[16px] whitespace-nowrap py-2'>
                                  Games
                                </th>
                                <th className='px-2 text-[12px] lg:text-[16px] whitespace-nowrap py-2'>
                                  open Time
                                </th>
                                <th className='px-2 text-[12px] lg:text-[16px] whitespace-nowrap py-2'>
                                  closing Time
                                </th>
                                <th className='px-2 text-[12px] lg:text-[16px] whitespace-nowrap py-2'>
                                  Result Time
                                </th>
                                <th className='px-2 text-[12px] lg:text-[16px] whitespace-nowrap py-2'>
                                  Status
                                </th>
                                <th className='px-2 text-[12px] lg:text-[16px] whitespace-nowrap py-2'>
                                  Color Code
                                </th>
                                <th className='px-2 text-[12px] lg:text-[16px] whitespace-nowrap py-2'>
                                  Chart
                                </th>
                                <th className='px-2 text-[12px] lg:text-[16px] whitespace-nowrap  text-center py-2'>
                                  action
                                </th>
                              </tr>
                            </thead>
                            <tbody className=''>
                              {gamesList && gamesList.length > 0
                                ?  gamesList.map((element, index) => {
                                    return (
                                      !element?.brandGame && (
                                        <React.Fragment key={index}>
                                          <tr
                                            key={index}
                                            className={`${element.status === 'ACTIVE'
                                              ? 'border-b-2 font-semibold text-white'
                                              : 'bg-gray-100 border-b-2 font-semibold'
                                              }`}
                                            style={element.status === 'ACTIVE' ? { background: element.color } : {}}
                                          >
                                            <th className='px-2 text-base py-2 font-font-semibold whitespace-nowrap'>
                                              {offset + index + 1}{' '}
                                            </th>

                                            <th className='px-2 text-base py-2 uppercase  font-semibold whitespace-nowrap'>
                                              {element && element.name
                                                ? element.name
                                                : '-'}
                                            </th>

                                            <th className='px-2 text-base py-2 font-font-semibold whitespace-nowrap'>
                                              {element &&
                                                element.openTime &&
                                                moment()
                                                  .hours(
                                                    parseInt(
                                                      element.openTime / 3600
                                                    )
                                                  )
                                                  .minutes(
                                                    parseInt(
                                                      (element.openTime %
                                                        3600) /
                                                      60
                                                    )
                                                  )
                                                  .seconds(
                                                    parseInt(
                                                      element.openTime % 60
                                                    )
                                                  )
                                                  .milliseconds(0)
                                                  .format('h:mm A')}
                                            </th>

                                            <th
                                              scope='row'
                                              className='px-2 text-base py-2 font-font-semibold whitespace-nowrap'
                                            >
                                              {' '}
                                              {element &&
                                                element.closingTime &&
                                                moment()
                                                  .hours(
                                                    parseInt(
                                                      element.closingTime /
                                                      3600
                                                    )
                                                  )
                                                  .minutes(
                                                    parseInt(
                                                      (element.closingTime %
                                                        3600) /
                                                      60
                                                    )
                                                  )
                                                  .seconds(
                                                    parseInt(
                                                      element.closingTime % 60
                                                    )
                                                  )
                                                  .milliseconds(0)
                                                  .format('h:mm A')}
                                            </th>

                                            <th
                                              scope='row'
                                              className='px-2 text-base py-2 font-font-semibold whitespace-nowrap'
                                            >
                                              {' '}
                                              {element &&
                                                element.resultTime &&
                                                moment()
                                                  .hours(
                                                    parseInt(
                                                      element.resultTime /
                                                      3600
                                                    )
                                                  )
                                                  .minutes(
                                                    parseInt(
                                                      (element.resultTime %
                                                        3600) /
                                                      60
                                                    )
                                                  )
                                                  .seconds(
                                                    parseInt(
                                                      element.resultTime % 60
                                                    )
                                                  )
                                                  .milliseconds(0)
                                                  .format('h:mm A')}
                                            </th>

                                            <td className='px-2 text-base py-2 text-left'>
                                              {element && element.status
                                                ? element.status
                                                : 'N/A'}
                                            </td>

                                            <td className='px-2 text-base py-2 text-left'>
                                              {element && element.color
                                                ? element.color
                                                : 'N/A'}
                                            </td>

                                            <td className='px-2 text-base py-2 text-center'>
                                              <IoBarChartSharp
                                                onClick={() =>
                                                  navigate(
                                                    `/app/chart/${element?._id}/${element?.name}`
                                                  )
                                                }
                                                className='cursor-pointer'
                                                title='Chart'
                                              />
                                            </td>

                                            <td className='px-2 text-base py-2 text-right justify-center items-center flex gap-1'>
                                              <span
                                                onClick={() =>
                                                  handleUpdateOpenModal(
                                                    element
                                                  )
                                                }
                                                className='relative text-lg flex justify-start px-2 py-1 rounded cursor-pointer text-blue-700'
                                                data-tooltip-id='edit-tooltip'
                                                data-tooltip-content='Edit'
                                              >
                                                {' '}
                                                <FiEdit className='cursor-pointer' />
                                              </span>
                                              <div className="justify-center items-center flex">
                                                <button className={element.isDisable === false ? "px-4 py-0.5 border border-green-700  bg-green-700 text-white rounded" : "px-4 py-0.5 border border-red-700 text-white bg-red-700 rounded"} onClick={() => disableUser(element)}>  {element.isDisable === false ? "Enable" : "Disable"}</button>
                                              </div>
                                            </td>
                                          </tr>
                                        </React.Fragment>
                                      )
                                    )
                                  })
                             
                                : null}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateGameModal
        createModal={createModal}
        handleCreateHideModal={handleCreateHideModal}
        handleFile={handleFile}
        selectedMarket={marketSelected}
        inputChangeCreate={inputChangeCreate}
        fieldsGame={fieldsGame}
        setMarketSelected={setMarketSelected}
        errorsGame={errorsGame}
        allMarket={allMarket}
        onSelect={onSelect}
        resetKey={resetKey}
        setResetKey={setResetKey}
        onRemove={onRemove}
        createGameSubmit={createGameSubmit}
        addref={addref}
      />

      <UpdateGameModal
        options={options}
        updateModal={updateModal}
        handleUpdateHideModal={handleUpdateHideModal}
        handleFile={handleFile}
        inputChangeUpdate={inputChangeUpdate}
        fieldsUpdate={fieldsUpdate}
        addref2={addref2}
        errorsUpdate={errorsUpdate}
        allMarket={allMarket}
        marketSelected={marketSelected}
        onSelect={onSelect}
        onRemove={onRemove}
        updateGameSubmit={updateGameSubmit}
      />

      <ViewGameModal
        viewModal={viewModal}
        handleViewHideModal={handleViewHideModal}
      />
    </>
  )
}

export default GameManagement
