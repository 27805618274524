/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { gamesActions, userActions } from '../../_actions'
import { MdDeleteOutline } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import moment from 'moment'
import { useTime } from 'react-timer-hook'
import { CiCoinInsert } from "react-icons/ci";
import { IoGameControllerOutline } from 'react-icons/io5'
import { LuAlarmClock } from 'react-icons/lu'
import { LiaHandPointRight } from "react-icons/lia";
import { FaDice } from 'react-icons/fa6'
import { FiDollarSign } from 'react-icons/fi'
import { FaRegUser } from "react-icons/fa6";
import { getCustomTimestamp } from '../../_helpers'
import { v4 as uuidv4 } from "uuid";


const PlaceBet = () => {
  const dispatch = useDispatch()
  const { games } = useSelector(state => state) || {}
  const { allMarket, gamesList, loading } = games || {}
  const { id, name } = useParams()
  let [localStoragedata, setlocalStoragedata] = useState()
  let [isDisable, setIsDisable] = useState(false)
  const [fieldsGame, setFieldsGame] = useState({})
  const [errorsGame, setErrorsGame] = useState({})
  const [userName, setUserName] = useState({})
  const [gameName, setGameName] = useState({})
  const { subAdminData, betsByUserId } = useSelector(state => state.games)
  const [isBetPrime, setIsBetPrime] = useState(false)
  const [gameNameRes, setGameNameRes] = useState(null)
  const [stateKey, setStateKey] = useState(null)
  let [options, setoptions] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)
  const [errors, setErrors] = useState({})
  const [selectedCountry, setSelectedCountry] = useState('')
  const [savedAmount, setSavedAmount] = useState('') // Value saved from the API call
  const [amount, setAmount] = useState('')
  const [isAmountBlank, setIsAmountBlank] = useState(false)

  const [savedBetNumber, setSavedBetNumber] = useState('') // Value saved from the API call
  const [betNumber, setBetNumber] = useState('')
  const [isBetNumberBlank, setIsBetNumberBlank] = useState(false)

  const userPalyerName = games?.betsByUserId && games?.betsByUserId?.[0]?.userId && games?.betsByUserId?.[0]?.userId?.userName ? games?.betsByUserId?.[0]?.userId?.userName
    : userName && userName?.userName ? userName?.userName : null

  const handleKeyDown1 = event => {
    if (event.key.toLowerCase() === 'l') {
      setIsBetPrime(prevState => !prevState) // Toggle the status
    }
  }


  const [backendTimeStamp, setBackendTimeStamp] = useState(Date.now())

  useEffect(() => {
    const fetchGlobalDate = async () => {
      try {
        const res = await userActions.getGlobalDate();
        if (res?.data) {
          const stamp = getCustomTimestamp(Number(res.data.timeStamp), Date.now())
          setBackendTimeStamp(() => stamp);
        }
      } catch (error) {
        console.error("Error fetching global date:", error);
        // Optionally set a fallback or error state here
      }
    };

    fetchGlobalDate();
  }, []);

  const [enterPressCount, setEnterPressCount] = useState(0) // Track Enter presses
  const betNumberInputRef = useRef(null)
  const amountInputRef = useRef(null) // Reference for the amount input
  const scrollContainerRef = useRef(null)

  useEffect(() => {
    return () => {
      dispatch(gamesActions.getPlaceBetsByUserIdLocalCall([]))
    }
  }, [dispatch])



  useEffect(() => {
    if (scrollContainerRef.current) {
      setTimeout(() => {
        scrollContainerRef.current.scrollTop =
          scrollContainerRef.current.scrollHeight
      }, 100)
    }
    const totalAmountRes =
      betsByUserId && betsByUserId.length > 0
        ? betsByUserId.reduce((sum, record) => {
          if (
            ['Single Open', 'Single Close'].includes(record?.localmarketname)
          ) {
            return sum + Number(record.amount) * 10 || 0
          }
          if (
            ['Single Kenchi', 'Double Kenchi'].includes(
              record?.localmarketname
            )
          ) {
            return sum + Number(record.amount) * record?.packet?.length || 0
          }
          return sum + Number(record.amount) || 0
        }, 0)
        : 0
    setTotalAmount(totalAmountRes)
  }, [betsByUserId])

  const handleKeyDown = async e => {
    if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
      e.preventDefault()
      return
    }

    if (e.key === 'Enter') {
      e.preventDefault()
      const { name } = e.target

      if (!e.target.value && e.target.name === 'betNumber') {
        setErrors(prev => {
          return {
            ...prev,
            betNumber: 'Bet Number is Required'
          }
        })
        return
      }

      if (name === 'betNumber') {
        if (
          currentOption.toShow === 'Single Open' &&
          String(e.target.value).length === 2
        ) {
          const findData = options.findIndex(ele => ele.toShow === 'Jodi')
          setCurrentIndex(() => (findData === -1 ? 0 : findData))
        } else if (
          currentOption.toShow === 'Single Open' &&
          String(e.target.value).length === 1
        ) {
          setCurrentIndex(() => 0)
        }
      }
      let error = validate(e.target.name, e.target.value)
      if (name === 'betNumber' && error) {
        setErrors(prev => {
          return {
            ...prev,
            betNumber: error
          }
        })
      }
      if (name === 'amount' && error) {
        setErrors(prev => {
          return {
            ...prev,
            amount: error
          }
        })
        return
      }
      if (error) {
        return
      }
      if (
        ['F9'].includes(stateKey) &&
        enterPressCount === 0 &&
        name === 'betNumber'
      ) {
        amountInputRef.current?.focus()
        setEnterPressCount(prevCount => prevCount + 1)
      }
      if (['F9'].includes(stateKey) && enterPressCount === 1) {
        await handleSubmit()
        setEnterPressCount(0)
        betNumberInputRef.current?.focus()
        return
      }

      if (enterPressCount === 0 && name === 'betNumber') {
        amountInputRef.current?.focus()
        setEnterPressCount(prevCount => prevCount + 1)
      }
      if (enterPressCount === 1 || enterPressCount === 2) {
        const amount = amountInputRef.current.value.trim()
        if (amount === '') {
          return
        }
        const betNumber = betNumberInputRef.current.value.trim()
        if (betNumber === '') {
          return
        }
        setIsAmountBlank(true)
        setSavedAmount(amount)
        setIsBetNumberBlank(true)
        setSavedBetNumber(betNumber)

        await handleSubmit()
        setEnterPressCount(0)
        betNumberInputRef.current?.focus()
        return
      }
    }
  }
  const getPacketByMarketData = (marketData, betNumber) => {
    switch (marketData) {
      case 'Single Open':
        return getSingleOpenCombinations(betNumber)
      case 'Single Close':
        return getSingleCloseCombinations(betNumber)
      case 'Jodi':
        return [betNumber]
      case 'Single Kenchi':
        return getUniqueTwoDigitCombinations(betNumber)
      case 'Double Kenchi':
        return getTwoDigitCombinations(betNumber)
      default:
        return []
    }
  }

  const validateBet = (marketData, betNumber) => {
    if (
      ['Single Close', 'Single Open'].includes(marketData) &&
      betNumber.length > 1
    )
      return false
    if (
      ['Single Kenchi', 'Double Kenchi'].includes(marketData) &&
      (betNumber.length < 2 || betNumber.length > 7)
    )
      return false
    if (marketData === 'Jodi' && betNumber.length !== 2) return false
    return true
  }

  const resetFormState = setStateFunctions => {
    const {
      setStateKey,
      setBetNumber,
      setCurrentIndex,
      setIsBetPrime,
      setErrors,
      setFormData
    } = setStateFunctions

    setStateKey(null)
    setBetNumber('')
    setCurrentIndex(0)
    setIsBetPrime(false)
    setErrors({})
    setFormData(prevData => ({ ...prevData, betNumber: '' }))
  }

  const handleSubmit = async e => {
    try {
      if (amount < 5 || !betNumber) return

      let packet = []
      let isOpen = false
      const marketId = currentOption

      if (['F9'].includes(stateKey) && betNumber.toString() === '11') {
        const JodiRes = [
          '00',
          '11',
          '22',
          '33',
          '44',
          '55',
          '66',
          '77',
          '88',
          '99'
        ]
        const resData = [
          {
            ...formData,
            isOpen,
            marketId,
            games: currentOption2,
            localmarketname: 'Jodi',
            isBetPrime
          }
        ]
        const gameId = gameName && gameName['_id'] ? gameName['_id'] : id
        const res = {
          gameId,
          userId: fieldsGame?.userId || null
        }

        resData.forEach(games => {
          const obj = {
            userId: fieldsGame?.userId || null,
            amount,
            marketId: games?.marketId?._id,
            gameId,
            isBetPrime: games?.isBetPrime,
            isOpen: games?.isOpen,
            localmarketname: 'Jodi',
            packet: JodiRes
          }
          dispatch(gamesActions.placeBetByAdminRes(obj, res))
        })

        resetFormState({
          setStateKey,
          setBetNumber,
          setCurrentIndex,
          setIsBetPrime,
          setErrors,
          setFormData
        })
        return
      }

      const marketData = ['Single Open'].includes(marketId?.toShow)
        ? betNumber.length === 1
          ? 'Single Open'
          : betNumber.length === 2
            ? 'Jodi'
            : marketId?.toShow
        : marketId?.toShow

      if (!validateBet(marketData, betNumber)) return

      if (marketData === 'Single Open') isOpen = true

      packet = getPacketByMarketData(marketData, betNumber)

      const resData = [
        {
          betNumber,
          packet,
          isOpen,
          marketId,
          games: currentOption2,
          localmarketname: marketData,
          isBetPrime
        }
      ]

      const gameId = gameName && gameName['_id'] ? gameName['_id'] : id
      const res = {
        gameId,
        userId: fieldsGame?.userId || null
      }

      resData.forEach(games => {
        const amountRes = ['Single Open', 'Single Close'].includes(
          games?.localmarketname
        )
          ? (Number(amount) / 10).toString()
          : amount.toString()

        const obj = {
          userId: fieldsGame?.userId || null,
          betNumber: games?.betNumber,
          amount: amountRes,
          marketId: games?.marketId?._id,
          gameId,
          isBetPrime: games?.isBetPrime,
          isOpen: games?.isOpen,
          localmarketname: games?.localmarketname,
          packet: games?.packet,
          uniqueKey: uuidv4()
        }

        dispatch(
          gamesActions.placeBetByAdmin(
            obj,
            res,
            betsByUserId?.length > 0 ? betsByUserId : []
          )
        )
      })

      resetFormState({
        setStateKey,
        setBetNumber,
        setCurrentIndex,
        setIsBetPrime,
        setErrors,
        setFormData
      })
    } catch (err) {
      console.error('Error in handleSubmit:', err)
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown1)
    return () => {
      document.removeEventListener('keydown', handleKeyDown1)
    }
  }, [])

  const isControlPressed = useRef(false);

  const handleKeyDown4 = useCallback(
    (event) => {
      if (event.key === "Control") {
        isControlPressed.current = true;
      }

      if (isControlPressed.current && (event.key === "q" || event.key === "Q")) {
        const index = betsByUserId?.length - 1;
        const userData = betsByUserId?.[index];

        if (userData && (gameName?._id || id || fieldsGame?.userId)) {
          const res = {
            gameId: gameName?._id ? gameName._id : id,
            userId: fieldsGame?.userId ?? null,
          };

          dispatch(
            gamesActions.deleteBetsById({ id: userData?._id || userData?.uniqueKey }, res, betsByUserId)
          );
        }
      }
    },
    [betsByUserId, dispatch, gameName, id, fieldsGame]
  );

  const handleKeyUp = useCallback((event) => {
    if (event.key === "Control") {
      isControlPressed.current = false;
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown4);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown4);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyDown4, handleKeyUp]);

  useEffect(() => {
    if (!isDisable) {
      window.addEventListener('keydown', handleKeyDown2)
      return () => {
        window.removeEventListener('keydown', handleKeyDown2)
      }
    }
    if (fieldsGame) {
      if (!isDisable) {
        window.addEventListener('keydown', handleKeyDown)
        return () => {
          window.removeEventListener('keydown', handleKeyDown)
        }
      }
    }
  }, [options, isDisable, fieldsGame])

  useEffect(() => {
    if (!isDisable) {
      window.addEventListener('keydown', handleKeyDown3)
      return () => {
        window.removeEventListener('keydown', handleKeyDown3)
      }
    }
  }, [isDisable, fieldsGame])

  const [formData, setFormData] = useState({})

  let [options2, setoptions2] = useState([])
  let currentOption2 =
    Array.isArray(options2) && options2.length > 0 ? options2[0] : null

  const [currentIndex, setCurrentIndex] = useState(0)
  let currentOption =
    Array.isArray(options) && options.length > 0 ? options[currentIndex] : null

  useEffect(() => {
    if (name) {
      setGameNameRes(name)
    }

    const stateData = window.sessionStorage.getItem('adminuser')
    setlocalStoragedata(JSON.parse(stateData))
    dispatch(gamesActions.getAllMarket())
    dispatch(gamesActions.getAllGames())
    dispatch(gamesActions.getSubAdminList())

  }, [dispatch])

  useEffect(() => {
    if (fieldsGame?.userId) {
      dispatch(
        gamesActions.getPlaceBetsByUserId({
          gameId: gameName?._id,
          userId: fieldsGame?.userId
        })
      )
    } else if (!fieldsGame.userId) {
      dispatch(
        gamesActions.getPlaceBetsByUserId({
          gameId: id
        })
      )
    }
  }, [fieldsGame, dispatch])

  function getSingleOpenCombinations(number) {
    const digits = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
    const combinations = []

    for (let j = 0; j < digits.length; j++) {
      combinations.push(number + digits[j])
    }
    return combinations
  }
  function getSingleCloseCombinations(number) {
    const digits = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
    const combinations = []

    for (let j = 0; j < digits.length; j++) {
      combinations.push(digits[j] + number)
    }
    return combinations
  }

  function getUniqueTwoDigitCombinations(number) {
    const digits = [...new Set(number?.toString().split(''))]
    const combinations = []

    for (let i = 0; i < digits.length; i++) {
      for (let j = 0; j < digits.length; j++) {
        if (i !== j) {
          combinations.push(digits[i] + digits[j])
        }
      }
    }
    return combinations
  }

  function getTwoDigitCombinations(number) {
    const digits = [...new Set(number?.toString().split(''))]
    const combinations = []

    for (let i = 0; i < digits.length; i++) {
      for (let j = 0; j < digits.length; j++) {
        combinations.push(digits[i] + digits[j])
      }
    }
    return combinations
  }

  const handleChange = e => {
    const { name, value } = e.target
    if (name === 'betNumber') {
      if (
        currentOption.toShow === 'Single Open' &&
        e.target.value.toString().length === 2
      ) {
        const findData = options.findIndex(ele => ele.toShow === 'Jodi')
        setCurrentIndex(() => (findData === -1 ? 0 : findData))
      } else if (
        currentOption.toShow === 'Single Open' &&
        e.target.value.toString().length === 1
      ) {
        setCurrentIndex(() => 0)
      }
    }
    const error = validate(name, value)
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: error
    }))

    const updateFormDataAndErrors = (key, val, err) => {
      setFormData(prev => ({
        ...prev,
        [key]: val
      }))
    }

    if (name === 'betNumber') {
      if (savedBetNumber === '') {
        setBetNumber(value)
        setIsBetNumberBlank(false)
      } else if (value !== savedBetNumber && isBetNumberBlank) {
        updateFormDataAndErrors(name, value, error)
        setBetNumber(e.target.value)
        setIsBetNumberBlank(false)
      } else {
        setBetNumber(value)
      }

      if (error) {
        return
      } else {
        setEnterPressCount(0)
      }
    }

    if (name === 'amount') {
      if (savedAmount === '') {
        setAmount(value)
        setIsAmountBlank(false)
      } else if (value !== savedAmount && isAmountBlank) {
        updateFormDataAndErrors(name, value, error)

        const lastCharacter = value[value.length - 1] || ''
        amountInputRef.current.value = lastCharacter
        setAmount(e.target.value)
        setIsAmountBlank(false)
      } else {
        setAmount(value)
      }
      if (error) {
        return
      }
      setEnterPressCount(1)
    }
  }

  const validate = (name, value = '') => {
    let error = ''

    // Check if value is empty
    if (!value) {
      error = 'Please enter a value.'
    }
    if (value === null || value === undefined || value === '') {
      error = 'Please enter a value.'
    }

    if (name === 'amount') {
      if (value < 5) {
        error = 'Amount must be greater than 5.'
      }
    }

    let indexChange = currentIndex
    if (name === 'betNumber') {
      if (
        currentOption.toShow === 'Single Open' &&
        value.toString().length === 2
      ) {
        const findData = options.findIndex(ele => ele.toShow === 'Jodi')
        setCurrentIndex(() => (findData === -1 ? 0 : findData))
        indexChange = findData === -1 ? 0 : findData
      } else if (
        currentOption.toShow === 'Single Open' &&
        value.toString().length === 1
      ) {
        setCurrentIndex(() => 0)
        indexChange = 0
      }
      const marketData =
        Array.isArray(options) && options.length > 0
          ? options[indexChange]
          : null
      if (!marketData) {
        error = 'Market data is unavailable.'
      } else {
        error = errorRespondsFunction(marketData.toShow, value.toString())
      }
    }
    return error
  }

  function errorRespondsFunction(marketName, value) {
    let error = ''
    if (
      ['Single Close', 'Single Open'].includes(marketName) &&
      value.length > 1
    ) {
      error = 'Only a single digit is allowed for Single Close.'
    } else if (['Single Kenchi', 'Double Kenchi'].includes(marketName)) {
      if (value.length < 3 || value.length > 7) {
        error =
          'Please enter between 3 to 7 unique digits for Single Kenchi or Double Kenchi.'
      } else if (new Set(value.split('')).size !== value.length) {
        error =
          'Duplicate digits are not allowed for Single Kenchi or Double Kenchi.'
      }
    } else if (['Jodi'].includes(marketName)) {
      if (value.length !== 2) {
        error = 'Jodi and Single Open require exactly 2 digits.'
      }
    }
    return error
  }

  const handleDelete = (data) => {
    const res = {
      gameId: gameName?._id,
      userId: fieldsGame?.userId
    }
    dispatch(gamesActions.deleteBetsById({ id: data?._id || data?.uniqueKey }, res, betsByUserId))
  }


  let singleId =
    allMarket &&
    allMarket?.length > 0 &&
    allMarket.find(e => e?.shortName === 'SINGLEDIGIT')
  let JodiId =
    allMarket &&
    allMarket?.length > 0 &&
    allMarket.find(e => e?.shortName === 'JODI')

  useEffect(() => {
    const localData = JSON.parse(window?.sessionStorage.getItem("adminuser"))
    if (!selectedCountry) {
      const availableGames = localData?.roleId === 0 ? gamesList?.filter(
        game => game?.status === 'ACTIVE'
      ) : gamesList?.filter(
        game => game?._id === id
      )

      if (availableGames?.length > 0) {
        setGameNameRes(availableGames?.[0]?.name)
        setGameName(availableGames?.[0])
      }

      let data = [
        { ...JodiId, toShow: 'Single Open' },
        { ...JodiId, toShow: 'Single Close' },
        { ...JodiId, toShow: 'Jodi' },
        { ...JodiId, toShow: 'Single Kenchi' },
        { ...JodiId, toShow: 'Double Kenchi' }
      ].filter(item => item !== null)

      if (
        ['akc.close'].includes(availableGames?.[0]?.name) &&
        !['F9'].includes(stateKey)
      ) {
        data = [{ ...JodiId, toShow: 'Single Close' }].filter(
          item => item !== null
        )
      } else if (['ak open'].includes(availableGames?.[0]?.name)) {
        data = [
          { ...JodiId, toShow: 'Single Open' },
          { ...JodiId, toShow: 'Jodi' },
          { ...JodiId, toShow: 'Single Kenchi' },
          { ...JodiId, toShow: 'Double Kenchi' }
        ].filter(item => item !== null)
      }
      setoptions(data)
      setoptions2(gamesList)
    }
  }, [stateKey, fieldsGame, gameNameRes, singleId, JodiId, gamesList, games])

  const handleNext = e => {
    e.preventDefault()
    setCurrentIndex(prevIndex =>
      prevIndex === options?.length - 1 ? 0 : prevIndex + 1
    )
  }

  const handleKeyDown2 = event => {
    if (event.key === 'ArrowUp') {
      setCurrentIndex(prevIndex => {
        const singleKenchiIndex = options.findIndex(
          item => item.toShow === 'Single Kenchi'
        )
        return singleKenchiIndex >= 0 ? singleKenchiIndex : prevIndex
      })
    }

    if (event.key === 'ArrowRight') {
      setCurrentIndex(prevIndex => {
        const singleCloseIndex = options.findIndex(
          item => item.toShow === 'Single Close'
        )
        return singleCloseIndex >= 0 ? singleCloseIndex : prevIndex
      })
    }

    if (event.key === 'ArrowLeft') {
      setCurrentIndex(prevIndex => {
        const singleOpenIndex = options.findIndex(
          item => item.toShow === 'Single Open'
        )
        return singleOpenIndex >= 0 ? singleOpenIndex : prevIndex
      })
    }

    if (event.key === 'ArrowDown') {
      setCurrentIndex(prevIndex => {
        const doubleKenchiIndex = options.findIndex(
          item => item.toShow === 'Double Kenchi'
        )
        return doubleKenchiIndex >= 0 ? doubleKenchiIndex : prevIndex
      })
    }
  }

  const handleKeyDown3 = event => {
    if (['F9'].includes(event.key) && !['akc.close'].includes(gameNameRes)) {
      setStateKey(event.key)
      const findData = options.findIndex(ele => ele.toShow === 'Jodi')
      setCurrentIndex(() => (findData === -1 ? 0 : findData))
      setBetNumber('11')
    }
  }

  const inputChangeCreate = e => {
    e.preventDefault()
    setStateKey(null)
    const { name, value } = e.target
    setFieldsGame(prevState => ({ ...prevState, [name]: value }))
    setErrorsGame(prevState => ({ ...prevState, [name]: '' }))

    if (name === "userId") {
      const user = subAdminData?.find(ele => ele?._id === value)
      setUserName(user)
    }
    if (name === 'userId' && gameNameRes) {
      setTimeout(() => {
        betNumberInputRef.current?.focus()
      }, 10)
      const user = subAdminData?.find(ele => ele?._id === value)
      setUserName(user)
      setIsDisable(false)
    }
  }

  const { seconds, minutes, hours, ampm } = useTime({ format: '12-hour' })
  const handleChangeSelect = event => {
    setSelectedCountry(event.target.value)
    const availableGames = gamesList?.find(
      game => game?._id === event.target.value
    )

    dispatch(
      gamesActions.getPlaceBetsByUserId({
        gameId: event.target.value,
        userId: fieldsGame?.userId
      })
    )


    let data = [
      { ...JodiId, toShow: 'Single Open' },
      { ...JodiId, toShow: 'Single Close' },
      { ...JodiId, toShow: 'Jodi' },
      { ...JodiId, toShow: 'Single Kenchi' },
      { ...JodiId, toShow: 'Double Kenchi' }
    ].filter(item => item !== null)

    if (
      ['akc.close'].includes(availableGames?.name) &&
      !['F9'].includes(stateKey)
    ) {
      data = [{ ...JodiId, toShow: 'Single Close' }].filter(
        item => item !== null
      )
    } else if (['ak open'].includes(availableGames?.name)) {
      data = [
        { ...JodiId, toShow: 'Single Open' },
        { ...JodiId, toShow: 'Jodi' },
        { ...JodiId, toShow: 'Single Kenchi' },
        { ...JodiId, toShow: 'Double Kenchi' }
      ].filter(item => item !== null)
    }
    setoptions(data)
    setoptions2(gamesList)

    setGameNameRes(availableGames?.name)
    setGameName(availableGames)

    if (availableGames && userName) {
      setTimeout(() => {
        betNumberInputRef.current?.focus()
      }, 10)
      setIsDisable(false)
    }
  }


  useEffect(() => {
    const localData = JSON.parse(window?.sessionStorage.getItem("adminuser"))

    if (localData?.roleId !== 0) {
      const user = subAdminData?.find(ele => ele?._id === localData?.userId)
      setUserName(() => user)
      setFieldsGame((prev) => {
        return {
          ...prev,
          userId: localData?.userId
        }
      })
    }
  }, [subAdminData])

  return (
    <div className='w-full bg-white h-screen overflow-hidden overflow-y-auto'>
      <div className='relative'>
        <div className='justify-between flex flex-col bg-white rounded-md border'>
          {localStoragedata && localStoragedata.roleId === 0 && (
            <div className="w-full p-3 px-5 space-y-4 bg-gray-50 rounded-lg">
              <div className="flex flex-col sm:flex-row gap-4 items-start sm:items-center justify-end">
                <div className="relative w-full sm:w-72">
                  <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                    <FaRegUser className="h-4 w-4 text-black" />
                  </div>
                  <select
                    className="w-full pl-10 pr-4 py-2.5 text-base bg-white border border-gray-200 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent appearance-none cursor-pointer hover:border-gray-300 transition-colors font-semibold capitalize"
                    id="userId"
                    name="userId"
                    value={fieldsGame?.userId || ''}
                    onChange={inputChangeCreate}
                  >
                    <option value="">Select Agent Name</option>
                    {subAdminData?.map((element, index) => (
                      <option key={index} value={element?._id || ''}>
                        {element?.userName || ''}
                      </option>
                    ))}
                  </select>
                  <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
                    <svg className="h-4 w-4 text-gray-black" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </div>
                </div>
                {errorsGame?.userId && (
                  <p className="text-sm text-red-500 mt-1">
                    {errorsGame['userId']}
                  </p>
                )}
                <div className="relative w-full sm:w-72">
                  <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                    <IoGameControllerOutline className="h-5 w-5 text-black" />
                  </div>
                  <select
                    className="w-full pl-10 pr-4 py-2.5 text-base bg-white border border-gray-200 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent appearance-none cursor-pointer hover:border-gray-300 transition-colors font-semibold uppercase"
                    value={selectedCountry}
                    onChange={handleChangeSelect}
                  >
                    <option value="">Choose Inactive Game</option>
                    {games?.gamesList
                      // ?.filter(game => game?.status === 'CLOSED')
                      ?.map(ele => (
                        <option key={ele._id} value={ele._id}>
                          {ele.name}
                        </option>
                      ))}
                  </select>
                  <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
                    <svg className="h-4 w-4 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          )}


          <div className='w-full px-4'>
            <div className="w-full bg-gray-200 px-4 py-2 rounded-md shadow-lg">
              <div className="flex flex-col lg:flex-row lg:justify-between gap-4 items-center">
                {/* Game Stats Section */}
                <div className="flex flex-wrap gap-3 justify-start">
                  {/* Game Name Card */}
                  <div className="flex items-center px-4">
                    <IoGameControllerOutline className="w-6 h-6 text-black mr-2" />
                    <div className="flex flex-row items-center font-semibold space-x-2 text-xl">
                      <span className="text-black">Game Name :</span>
                      <span className="text-black uppercase">{gameNameRes || 'N/A'}</span>
                    </div>
                  </div>

                  {/* User Name Card */}
                  <div className="flex items-center px-4">
                    <FaRegUser className="w-5 h-5 text-black mr-2" />
                    <div className="flex flex-row items-center font-semibold space-x-2 text-xl">
                      <span className="text-black">User Name :</span>
                      <span className="text-black capitalize">{userPalyerName || 'N/A'}</span>
                    </div>
                  </div>

                  {/* Total Bet Amount Card */}
                  <div className="flex items-center px-4">
                    <CiCoinInsert className="w-6 h-6 text-black mr-2" />
                    <div className="flex flex-row items-center font-semibold space-x-2 text-xl">
                      <span className="text-black">Total Bet Amount :</span>
                      <span className="text-black capitalize">{" " + totalAmount || '0'}</span>
                    </div>
                  </div>
                </div>

                {/* Time Display */}
                <div className="px-4 min-w-[200px]">
                  <div className="flex items-center justify-center gap-2">
                    <LuAlarmClock className="w-5 h-5 text-black" />
                    <div className="flex items-center">
                      <span className="text-black font-mono font-semibold text-xl">
                        {String(hours).padStart(2, '0')}:
                        {String(minutes).padStart(2, '0')}:
                        {String(seconds).padStart(2, '0')}
                      </span>
                      <span className="text-gray-200 ml-2 text-sm uppercase">{ampm}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div
              className='h-[34rem] overflow-x-auto overflow-y-auto'
              ref={scrollContainerRef}
            >
              <table className='min-w-full border border-gray-300 text-sm sm:text-base'>
                <thead className='sticky top-0 bgcolortb z-10'>
                  <tr className="text-lg font-semibold">
                    <th className="px-4 py-3 tracking-wider">#</th>
                    {localStoragedata?.roleId === 0 && (
                      <>
                        <th className="px-4 py-3 tracking-wider">Agent</th>
                        <th className="px-4 py-3 tracking-wider">Game</th>
                      </>
                    )}
                    <th className="px-4 py-3 tracking-wider">Market</th>
                    <th className="px-4 py-3 tracking-wider">Number</th>
                    <th className="px-4 py-3 tracking-wider">Amount</th>
                    <th className="px-4 py-3 tracking-wider">Packet</th>
                    <th className="px-4 py-3 tracking-wider">Date/Time</th>
                    <th className="px-4 py-3 tracking-wider">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {betsByUserId && betsByUserId.length > 0 ? (
                    betsByUserId.map((bet, index) => (
                      <tr key={index} className="hover:bg-gray-50 text-center last:bg-green-300 transition-colors duration-200 text-lg font-semibold border-b border-gray-400">
                        <td className="px-4 py-2 whitespace-nowrap">{index + 1}</td>
                        {localStoragedata?.roleId === 0 && (
                          <>
                            <td className="px-4 py-2 whitespace-nowrap capitalize">{userPalyerName ?? 'N/A'}</td>
                            <td className="px-4 py-2 whitespace-nowrap capitalize">{gameNameRes ?? 'N/A'}</td>
                          </>
                        )}
                        <td className="px-4 py-2 whitespace-nowrap capitalize">{bet?.localmarketname}</td>
                        <td className={`px-4 py-2 whitespace-nowrap ${bet.isBetPrime ? 'text-red-600' : 'text-gray-700'}`}>{bet.betNumber}</td>
                        <td className={`px-4 py-2 whitespace-nowrap ${bet.isBetPrime ? 'text-red-600' : 'text-gray-700'}`}>
                          {['Single Open', 'Single Close'].includes(bet?.localmarketname) ? bet?.amount * 10 : bet?.amount}</td>
                        <td className="px-4 py-2 whitespace-nowrap">
                          {['Single Open', 'Single Close'].includes(bet?.marketId?.name) ? bet?.packet?.length * 10 : bet?.packet?.length}</td>
                        <td className="px-4 py-2 whitespace-nowrap">{moment(bet?.createdAt || backendTimeStamp).format('DD-MM-YYYY HH:mm')}</td>
                        <td className="px-4 py-2 whitespace-nowrap">
                          <button onClick={() => handleDelete(bet)} className="text-red-500 hover:text-red-600 transition-colors duration-200 p-1 rounded-full hover:bg-red-50" title="Delete">  <MdDeleteOutline className="w-5 h-5" /></button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={localStoragedata?.roleId === 0 ? 9 : 7}
                        className="px-4 py-8 text-center text-gray-500 bg-gray-50"
                      >
                        <div className="flex flex-col items-center justify-center gap-2">
                          <span className="text-lg">No bets available</span>
                          <span className="text-sm text-gray-400">Try adjusting your filters or adding new bets</span>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="relative">
              <form className="p-6 bg-[#D4EBF8] rounded-xl shadow-lg">
                <div className="flex flex-col lg:flex-row items-center gap-6">
                  {/* Current Option Display */}
                  <div className="gap-3 h-16">
                    <div className="w-44 bg-white border border-blue-200 rounded-lg px-6 py-2.5 text-center font-semibold text-gray-700 shadow-sm">
                      {currentOption?.toShow}
                    </div>
                  </div>
                  <div className='h-16'>
                    <button onClick={handleNext} disabled={isDisable} className="bg-blue-500 hover:bg-blue-600 disabled:bg-blue-300 text-black p-3 rounded-lg transition-all duration-300 flex items-center justify-center shadow-md hover:shadow-lg active:scale-95" title="Next">  <LiaHandPointRight className="w-5 h-5" /></button>
                  </div>
                  {/* Bet Number Input */}
                  <div className="relative w-72 h-16">
                    <div className="relative">
                      <div className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400">
                        <FaDice className="w-5 h-5" />
                      </div>
                      <input
                        type="text"
                        ref={betNumberInputRef}
                        value={betNumber || ''}
                        onChange={e => {
                          const value = e.target.value;
                          if (/^\d*$/.test(value)) {
                            handleChange(e);
                          }
                        }}
                        autoComplete="off"
                        onKeyDown={handleKeyDown}
                        disabled={!userPalyerName}

                        name="betNumber"
                        className="w-full pl-10 pr-4 py-2.5 bg-white border border-gray-200 rounded-lg transition-all duration-300 disabled:cursor-not-allowed shadow-sm text-lg font-semibold"
                        placeholder="Enter betting number"
                      />
                    </div>
                    {errors?.betNumber && (
                      <p className="mt-1.5 text-xs text-red-500 flex items-center gap-1">
                        <span className="inline-block w-1 h-1 bg-red-500 rounded-full"></span>
                        {errors.betNumber}
                      </p>
                    )}
                  </div>

                  {/* Amount Input */}
                  <div className="relative w-72 h-16">
                    <div className="relative">
                      <div className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400">
                        <FiDollarSign className="w-5 h-5" />
                      </div>
                      <input
                        type="number"
                        ref={amountInputRef}
                        value={amount}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        name="amount"
                        autoComplete="off"
                        className="w-full pl-10 pr-4 py-2.5 bg-white border border-gray-200 rounded-lg transition-all duration-300 disabled:cursor-not-allowed shadow-sm text-lg font-semibold"
                        placeholder="Enter bet amount"
                      />
                    </div>
                    {errors?.amount && (
                      <p className="mt-1.5 text-xs text-red-500 flex items-center gap-1">
                        <span className="inline-block w-1 h-1 bg-red-500 rounded-full"></span>
                        {errors.amount}
                      </p>
                    )}
                  </div>
                </div>

                {/* Loading Overlay */}
                {loading && (
                  <div className="absolute inset-0 bg-white/80 backdrop-blur-sm rounded-xl flex items-center justify-center z-50">
                    <div className="relative">
                      <div className="w-12 h-12 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                      <div className="absolute inset-0 flex items-center justify-center">
                        <div className="w-8 h-8 border-4 border-blue-300 border-t-transparent rounded-full animate-spin"></div>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default PlaceBet
