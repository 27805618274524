import { React, useState, useEffect } from 'react'
import Select from 'react-select'

export default function CreateAppSettingModal (props) {
  const [Options, setOptions] = useState([])

  let {
    createModal,
    handleCreateHideModal,
    inputChangeCreate2,
    selecteddata,
    inputChangeCreate,
    fieldsAppSetting,
    errorsAppSetting,
    createAppSettingSubmit,
    allUserList
  } = props

  useEffect(() => {
    if (allUserList) {
      const options = allUserList?.map(user => ({
        label: user.userName,
        value: user._id
      }))

      setOptions(options)
    }
  }, [allUserList])

  return (
    <div
      className={
        createModal
          ? `fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster`
          : 'hidden'
      }
      style={{
        background: 'rgba(0, 0, 0, 0.7)',
        backdropFilter: 'blur(5px)'
      }}
    >
      <div className='z-50 w-11/12 mx-auto overflow-y-auto bg-white border  rounded shadow-lg modal-container md:max-w-md'>
        <div className='px-6 py-4 text-left modal-content'>
          <div className='flex items-center justify-between mb-3 rounded-lg px-2 py-3 text-black'>
            <p className='text-2xl font-bold'>Create Notification </p>
            <div
              className='z-50 cursor-pointer modal-close text-black rounded-full p-2 bg-black/20 hover:bg-black/40'
              onClick={() => handleCreateHideModal()}
            >
              <svg
                className='text-black fill-current'
                xmlns='http://www.w3.org/2000/svg'
                width={18}
                height={18}
                viewBox='0 0 18 18'
              >
                <path d='M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z'></path>
              </svg>
            </div>
          </div>
          {/*Body*/}
          <form autoComplete='off' className='space-y-2'>
            <div className='mt-1 shadow-sm w-full'>
              <label
                class='block text-gray-700 text-base font-bold mb-2'
                for='name'
              >
                Select User:
              </label>
              <Select
                isMulti
                name='userId'
                value={selecteddata}
                options={Options}
                className={`basic-multi-select outline-none ${
                  errorsAppSetting && !errorsAppSetting['userId']
                    ? 'border  placeholder-gray-500'
                    : 'border border-opacity-100 border-red-500 '
                }`}
                classNamePrefix='select'
                onChange={inputChangeCreate2}
              />
              {errorsAppSetting && errorsAppSetting['userId'] ? (
                <div className='text-yellow-600 invalid-feedback'>
                  {errorsAppSetting['userId']}
                </div>
              ) : null}
            </div>

            <div className="{otpSent?'disableArea':''}">
              <div className='relative mt-1 shadow-sm'>
                <label
                  class='block text-gray-700 text-base font-bold mb-2'
                  for='name'
                >
                  Title:
                </label>
                <input
                  className={`w-full px-3 py-2 text-base transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 outline-none ${
                    errorsAppSetting && !errorsAppSetting['name']
                      ? 'border  placeholder-gray-500'
                      : 'border border-opacity-100 border-red-500 '
                  }`}
                  id='name'
                  name='name'
                  placeholder='Title'
                  value={
                    fieldsAppSetting && fieldsAppSetting['name']
                      ? fieldsAppSetting['name']
                      : ''
                  }
                  type='text'
                  onChange={inputChangeCreate}
                />
                {errorsAppSetting && errorsAppSetting['name'] ? (
                  <div className='text-red-600 invalid-feedback'>
                    {errorsAppSetting['name']}
                  </div>
                ) : null}
              </div>
            </div>

            <div>
              <div className='relative mt-1 shadow-sm'>
                <label
                  class='block text-gray-700 text-base font-bold mb-2'
                  for='appsettingname'
                >
                  Message :
                </label>
                <textarea
                  className={`w-full px-3 py-3 text-base transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 outline-none ${
                    errorsAppSetting && !errorsAppSetting['key']
                      ? 'border  placeholder-gray-500'
                      : 'border border-opacity-100 border-red-500 '
                  }`}
                  id='key'
                  name='key'
                  placeholder='Message'
                  value={
                    fieldsAppSetting && fieldsAppSetting['key']
                      ? fieldsAppSetting['key']
                      : ''
                  }
                  type='text'
                  onChange={inputChangeCreate}
                />
                {errorsAppSetting && errorsAppSetting['key'] ? (
                  <div className='text-red-600 invalid-feedback'>
                    {errorsAppSetting['key']}
                  </div>
                ) : null}
              </div>
            </div>
            <div className='w-full mx-auto mt-4'>
              <button
                className='flex justify-center w-full px-4 py-2 mx-auto text-lg font-bold text-white uppercase rounded-lg xl all-btn'
                type='button'
                onClick={createAppSettingSubmit}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
