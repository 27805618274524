import { createStore, applyMiddleware } from 'redux';
import {thunk} from 'redux-thunk'; // Import thunk from 'redux-thunk'
// import { createLogger } from 'redux-logger';
import rootReducer from '../_reducers';

// const loggerMiddleware = createLogger();

export const store = createStore(
  rootReducer,
  applyMiddleware(
    thunk,
    // loggerMiddleware
  )
);