import { reportConstants } from '../_constants';
import { alertActions } from './alert.actions';
import { APIcallFunction, headerForPrivateAPI } from '../_helpers';
export const reportActions = {
    getBetsListForAdmin,
    getKarachiBetsListForAdmin,
    DeleteKarachiBet,
    getWinningList,
    getNumberWiseMarketSum,
    getNumberKarachiWiseMarketSum,
    clearState,
    getResultDeclareByAdmin,
    getAllEntries,
    betwiseLedger,
    getDownLineUsers,
    getLedger,
    getLedgerforUser,
    getAllEntriesbyId,
    getBetsList,
    getGamePaymentsForSubAdmin,
    getGamePaymentsForSubAdmin1,
    getKarachiGamePaymentsForSubAdmin,
    getKarachiGamePaymentsForSubAdmin1,
    getNumberKarachiWiseMarketSumOpen,
    getNumberKarachiWiseMarketSumClose,
    getNumberKarachiWiseMarketSumCenter,
    getNumberKarachiWiseMarketSumJodi,
    getNumberKarachiWiseMarketSumRing,
    getKarachiList
};

function DeleteKarachiBet(data, reff) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: "/DeleteKarachiBet",
    };
    return (dispatch) => {
        dispatch(request({ data }));
        APIcallFunction(credentials).then(
            (user) => {
                dispatch(success(user));
                dispatch(alertActions.success("Deleted"));
                dispatch(reportActions.getKarachiBetsListForAdmin(reff));
            },
            (error) => {
                dispatch(failure(error));
                dispatch(alertActions.error(error));
            }
        );
    };
    function request(user) {
        return { type: reportConstants.DELETE_KARACHI_BETS_REQUEST, user };
    }
    function success(user) {
        return { type: reportConstants.DELETE_KARACHI_BETS_SUCCESS, user };
    }
    function failure(error) {
        return { type: reportConstants.DELETE_KARACHI_BETS_FAILURE, error };
    }
}

function getGamePaymentsForSubAdmin(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getGamePaymentsForSubAdmin'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then((report) => {
                dispatch(success(report));
            },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_GAME_PAYMENTS_FOR_SUB_ADMIN_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_GAME_PAYMENTS_FOR_SUB_ADMIN_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_GAME_PAYMENTS_FOR_SUB_ADMIN_FAILURE, error } }
}

function getGamePaymentsForSubAdmin1(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getGamePaymentsForSubAdmin'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then((report) => {
                dispatch(success(report));
            },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request() { return { type: reportConstants.GET_GAME_PAYMENTS_FOR_SUB_ADMIN_1_REQUEST } }
    function success(report) { return { type: reportConstants.GET_GAME_PAYMENTS_FOR_SUB_ADMIN_1_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_GAME_PAYMENTS_FOR_SUB_ADMIN_1_FAILURE, error } }
}
function getKarachiGamePaymentsForSubAdmin(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getKarachiGamePaymentsForSubAdmin'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then((report) => {
                dispatch(success(report));
            },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_KARACHI_GAME_PAYMENTS_FOR_SUB_ADMIN_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_KARACHI_GAME_PAYMENTS_FOR_SUB_ADMIN_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_KARACHI_GAME_PAYMENTS_FOR_SUB_ADMIN_FAILURE, error } }
}

function getKarachiGamePaymentsForSubAdmin1(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getKarachiGamePaymentsForSubAdmin'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then((report) => {
                dispatch(success(report));
            },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request() { return { type: reportConstants.GET_KARACHI_GAME_PAYMENTS_FOR_SUB_ADMIN_1_REQUEST } }
    function success(report) { return { type: reportConstants.GET_KARACHI_GAME_PAYMENTS_FOR_SUB_ADMIN_1_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_KARACHI_GAME_PAYMENTS_FOR_SUB_ADMIN_1_FAILURE, error } }
}

function getBetsList(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getUserBetListListNewLean'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then((report) => {
                dispatch(success(report));
            },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_BET_LISTSS_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_BET_LISTSS_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_BET_LISTSS_FAILURE, error } }
}

function getKarachiList(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getUserKarachiBetsByUserIdListed'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then((report) => {
                dispatch(success(report));
            },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_KARACHI_LISTSS_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_KARACHI_LISTSS_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_KARACHI_LISTSS_FAILURE, error } }
}





function betwiseLedger(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/betwiseLedger'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then((report) => {
                dispatch(success(report));
            },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GETBET_WISE_LEDGER_DATA_REQUEST, report } }
    function success(report) { return { type: reportConstants.GETBET_WISE_LEDGER_DATA_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GETBET_WISE_LEDGER_DATA_FAILURE, error } }
}
function getAllEntriesbyId(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getAllEntriesbyId'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then((report) => {
                dispatch(success(report));
            },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_LEDFERS_ID_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_LEDFERS_ID_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_LEDFERS_ID_FAILURE, error } }
}
function getLedger(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getLedger'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then((report) => {
                dispatch(success(report));
            },
                error => {
                    dispatch(failure(error));
                    // dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_LEDFERS_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_LEDFERS_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_LEDFERS_FAILURE, error } }
}



function getLedgerforUser(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getLedgerforUser'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then((report) => {
                dispatch(success(report));
            },
                error => {
                    dispatch(failure(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_LEDFERS_REQUEST22, report } }
    function success(report) { return { type: reportConstants.GET_LEDFERS_SUCCESS22, report } }
    function failure(error) { return { type: reportConstants.GET_LEDFERS_FAILURE22, error } }
}



function getDownLineUsers(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getDownLineUsers'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_DOWN_LINE_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_DOWN_LINE_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_DOWN_LINE_FAILURE, error } }
}
function getAllEntries(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getAllEntries'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_ALL_ENTRIES_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_ALL_ENTRIES_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_ALL_ENTRIES_FAILURE, error } }
}
function getResultDeclareByAdmin(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getResultDeclareByAdmin'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_RESULT_DECLARE_BY_ADMIN_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_RESULT_DECLARE_BY_ADMIN_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_RESULT_DECLARE_BY_ADMIN_FAILURE, error } }
}
function getNumberKarachiWiseMarketSumOpen(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getNumberKarachiWiseMarketSumOpen'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_OPEN_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_OPEN_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_OPEN_FAILURE, error } }
}
function getNumberKarachiWiseMarketSumClose(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getNumberKarachiWiseMarketSumClose'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_CLOSE_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_CLOSE_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_CLOSE_FAILURE, error } }
}
function getNumberKarachiWiseMarketSumCenter(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getNumberKarachiWiseMarketSumCenter'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_CENTER_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_CENTER_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_CENTER_FAILURE, error } }
}
function getNumberKarachiWiseMarketSumJodi(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getNumberKarachiWiseMarketSumJodi'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_JODI_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_JODI_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_JODI_FAILURE, error } }
}
function getNumberKarachiWiseMarketSumRing(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getNumberKarachiWiseMarketSumRing'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_RING_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_RING_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_RING_FAILURE, error } }
}
function getNumberWiseMarketSum(data, intervalRef) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getNumberWiseMarketSum'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_NUMBER_WISE_MARKET_SUM_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_NUMBER_WISE_MARKET_SUM_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_NUMBER_WISE_MARKET_SUM_FAILURE, error } }
}
function getNumberKarachiWiseMarketSum(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getNumberKarachiWiseMarketSum'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request() { return { type: reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_REQUEST }; }
    function success(report) { return { type: reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_SUCCESS, report }; }
    function failure(error) { return { type: reportConstants.GET_NUMBER_KARACHI_WISE_MARKET_SUM_FAILURE, error }; }
}

function clearState() {
    return (dispatch) => {
        dispatch(success());
    };
    function success(report) { return { type: reportConstants.CLEAR_STATE_SUCCESS, report } }
}
function getWinningList(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getWinningList'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_WIN_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_WIN_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_WIN_FAILURE, error } }
}
function getBetsListForAdmin(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getBetsListForAdmin'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_BETLIST_FOR_ADMIN_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_BETLIST_FOR_ADMIN_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_BETLIST_FOR_ADMIN_FAILURE, error } }
}
function getKarachiBetsListForAdmin(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getKarachiBetsListForAdmin'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                report => {
                    dispatch(success(report));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(report) { return { type: reportConstants.GET_KARACHI_BETLIST_FOR_ADMIN_REQUEST, report } }
    function success(report) { return { type: reportConstants.GET_KARACHI_BETLIST_FOR_ADMIN_SUCCESS, report } }
    function failure(error) { return { type: reportConstants.GET_KARACHI_BETLIST_FOR_ADMIN_FAILURE, error } }
}