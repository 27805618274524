/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from "react";
import { Menu, Transition } from '@headlessui/react';
import { logoutFunction } from "../../_helpers";
import { FaRegCircleUser } from "react-icons/fa6";
import { IoIosArrowDown } from "react-icons/io";
import { useSelector } from "react-redux";
import ChangePasswordModel from "../../pages/dashboard/ChangePasswordModel";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Header() {
  const [openmodel, setopenmodel] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("");

  const handelLogout = () => {
    logoutFunction();
  };

  const { adminProfileDetails } = useSelector(state => state.users);

  useEffect(() => {
    const pathToTitleMap = {
      "/app/dashboard": "Dashboard",
      "/app/test": "Test",
      "/user": "User Management",
      "/app/downsideUser": "Agent",
      "/app/userofSuperadmin": "User",
      "/EditGame": "Edit Game",
      "/app/market": "Market Management",
      "/marketJackpot": "Jackpot Management",
      "/marketStarLine": "Starline Management",
      "/app/game": "Game Management",
      "/Brandgame": "Brand Game Management",
      "/IndianJackpots": "Indian Jackpots",
      "/brand": "Brand",
      "/app/slider": "Slider",
      "/fundRequest": "Fund Request",
      "/app/withdrawRequest": "Withdraw Fund Report",
      "/app/AddFundRequest": "Add Fund Report",
      "/bidRevert": "Bid Revert",
      "/addFundReport": "Add Fund Report",
      "/withdrawReport": "Withdraw Report",
      "/pointTransfer": "Point Transfer Report",
      "/app/userbidhistory": "User Bid History",
      "/app/user-karachi-bid-history": "User Karachi Bid History",
      "/app/jackpotBetHistory": "Jackpot Bid History",
      "/app/starlineBidHistory": "Starline Bid History",
      "/app/ledger": "Ledger",
      "/app/karachiPlaceBet/:id?/:name?": "Karachi Place Bet",
      "/mybets": "My Bets",
      "/ledgers": "Ledgers",
      "/app/addFund": " Add Fund",
      "/app/withdrawfund": " Withdraw Fund",
      "/app/declareResult": "Save Result",
      "/app/karachi-declare-result": "Karachi Game result",
      "/declareResultStarline": "Declare Result Starline",
      "/declareResultJackPot": "Declare Result Jackpot",
      "/app/winnerList": "Winner List",
      "/app/betHistory": "Point Bid History",
      "/app/starline-bid-point-history": "Starline Bid Point History",
      "/app/jackpot-bid-point-history": "Jackpot Bid Point History",
      "/app/appsetting": "App Setting",
      "/bankdetails": "Bank Details",
      "/singleDigits": "Single Digits",
      "/app/notification": "Notification",
      "/app/game-profit-loss": "Game Profit Loss",
      "/app/user-profit-loss": "User Profit Loss",
      "/app/karachi-profit-loss": "Karachi Profit Loss",
      "/app/karachi-game": "Karachi Game Management",
      "/app/karachi-Management": "Karachi Market Management",
      "/app/placebet": "Bet Table"
    };

    const currentPath = window.location.pathname;


    setHeaderTitle(pathToTitleMap[currentPath] || " ");
  }, [window.location.pathname]);

  return (
    <Fragment>
      <div className="bgcolor relative z-10 flex-shrink-0 flex h-16 border-b border-black border-opacity-10">
        <div className="flex-1 px-4 flex justify-between">
          <div className="ml-12 lg:ml-0">
            <h1 className="font-semibold text-[#fff] lg:ml-9 lg:text-2xl uppercase mt-4 text-md">
              {headerTitle}
            </h1>
          </div>
          <div className="flex justify-center items-center space-x-4"></div>
          <div className="flex items-center">
            <FaRegCircleUser className="rounded-full lg:text-4xl text-2xl relative flex justify-center text-white items-end" />
            <Menu as="div" className="relative inline-block text-left Z-50">
              {({ open }) => (
                <>
                  <div className="ml-2 relative">
                    <Menu.Button className="max-w-xs flex items-center text-base rounded-full focus:outline-none focus:shadow-outline">
                      <div className="font-semibold text-white flex justify-center items-center capitalize text-md lg:text-lg gap-3">
                        <span>
                          {adminProfileDetails?.adminData?.userName || ""}
                        </span>{" "}
                        <IoIosArrowDown />
                      </div>
                    </Menu.Button>
                  </div>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items static className="origin-top-right absolute right-0 mt-2 w-44 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none Z-50">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <span
                              onClick={() => setopenmodel((e) => !e)}
                              className={classNames(
                                active
                                  ? "bg-shine text-gray-900"
                                  : "text-gray-900",
                                "transition ease-in-out duration-500 cursor-pointer block px-4 py-2 text-medium hover:bg-[#024CAA] text-gray-700 hover:text-white"
                              )}
                            >
                              Change Password
                            </span>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <span
                              onClick={handelLogout}
                              className={classNames(
                                active
                                  ? "bg-shine text-gray-900"
                                  : "text-gray-900",
                                "transition ease-in-out duration-500 cursor-pointer block px-4 py-2 text-medium hover:bg-red-500 text-red-500 hover:text-white"
                              )}
                            >
                              Logout
                            </span>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        </div>
        <ChangePasswordModel openmodel={openmodel} setopenmodel={setopenmodel} />
      </div>
    </Fragment>
  );
}
