import React from "react";

export default function ViewGameModal(props) {

  let { viewModal, handleViewHideModal, rowData, deleteGame2 } = props;

  return (

    <div className={viewModal ? `fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster` : "hidden"} style={{ background: 'rgba(0,0,0,.7)' }}>
      <div className="z-50  mx-auto overflow-y-auto bg-white border border-teal-500 rounded shadow-lg modal-container ">
        <div className="px-6 py-4 text-left modal-content">
          {/*Title*/}
          <div className="flex items-center justify-between bg-[#911212] text-white py-2 px-1 rounded-lg">
            <p className="text-2xl font-bold">Receipt Image</p>
            <div className="z-50 cursor-pointer modal-close">
              <svg onClick={() => handleViewHideModal()} className="text-white fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                </path>
              </svg>
            </div>
          </div>
          {/*Body*/}
          <div className="p-3">
            <img className=" h-[50vh] w-[50vw]" src={rowData?.image} alt="n Found" />
          </div>
          <div className=" flex gap-4 flex-col justify-center items-center">

            <div className=" text-xl font-bold">
              Amount : {rowData?.amount || ""}
            </div>
            <div>

              <span className="relative ">
                <button className="px-4 py-2 font-medium tracking-wider text-white bg-green-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-green-300" onClick={() => deleteGame2(rowData, 1)}>Approve</button>
              </span>
              <span className="relative ">
                <button className="px-4 py-2 font-medium tracking-wider text-white bg-red-500 border rounded-md shadow-sm hover:shadow-lg hover:bg-red-300" onClick={() => deleteGame2(rowData, 2)}>Reject</button>
              </span>
            </div>
          </div>

        </div>
      </div>
    </div>



  );
}
