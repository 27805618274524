/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  gamesActions,
  karachiGamesActions,
  reportActions
} from '../../_actions'
import Loader from '../../components/Loader/Loader'
import moment from 'moment'
import { FaDownload } from 'react-icons/fa6'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

const KarachiProfitLoss = () => {
  const dispatch = useDispatch()
  const { reports } = useSelector(state => state) || {}

  const [fieldsGame, setFieldsGame] = useState({})
  const [errorsGame, setErrorsGame] = useState({})
  const [isOpen, setIsOpen] = useState(false)
  const today = moment().format('YYYY-MM-DD')
  const [userDetails, setUserDetails] = useState({})
  const [localData, setLocalData] = useState(null)
  const { subAdminData } = useSelector(state => state.games)

  useEffect(() => {
    dispatch(karachiGamesActions.getAllKarachiGames())
    dispatch(gamesActions.getSubAdminList())
  }, [])

  const createGameSubmit = async e => {
    e.preventDefault()
    if (handleValidationCreateGame()) {
      setIsOpen(true)
      let game = {
        date: fieldsGame?.date || today,
        userId: fieldsGame?.userId
      }
      dispatch(reportActions.getKarachiGamePaymentsForSubAdmin(game))
    }
  }
  const handleValidationCreateGame = () => {
    let formIsValid = true
    let errors = {}

    if (!fieldsGame.userId || fieldsGame.userId === '') {
      formIsValid = false
      errors.userId = 'Select Game Name'
    }

    setErrorsGame(errors)
    return formIsValid
  }

  useEffect(() => {
    const adminUser = window.localStorage.getItem("adminuser");

    if (adminUser) {
      try {
        const parsedAdminUser = JSON.parse(adminUser);
        setLocalData(parsedAdminUser)

        if (parsedAdminUser?.userId && parsedAdminUser.roleId !== 0) {
          // Update `fieldsGame` state
          setFieldsGame((prev) => ({ ...prev, userId: parsedAdminUser.userId }));
          // Find details in `subAdminData`
          if (Array.isArray(subAdminData)) {
            const details = subAdminData.find(ele => ele._id === parsedAdminUser.userId);
            setUserDetails(details || null); // Set `null` if no details are found
          }
        }
      } catch (error) {
        console.error("Error parsing adminuser from localStorage:", error);
      }
    }
  }, [subAdminData]); // Add subAdminData if it's dynamic

  const inputChangeCreate = e => {
    e.preventDefault()
    setIsOpen(false)
    const { name, value } = e.target
    setFieldsGame(prevState => ({ ...prevState, [name]: value }))
    setErrorsGame(prevState => ({ ...prevState, [name]: '' }))

    if (name === 'userId') {
      const details = subAdminData.find(ele => ele._id === value)
      setUserDetails(details)
    }
  }

  let { getKarachiGamePayments, loading } = reports ? reports : []

  // const fsRingResult = getKarachiGamePayments?.[0]?.f_s_ring_reult.map(ele => ele + '-')
  // const fsRingResult =
  //   getKarachiGamePayments?.[0]?.f_s_ring_reult.map((ele, index, arr) =>
  //     index === arr.length - 1 ? ele : ele + '-'
  //   ) ?? null
  // const totalRingWinAmount =
  //   getKarachiGamePayments?.[0]?.totalRingWinAmount || 0

  const totalBetsAmountSum = (getKarachiGamePayments || []).reduce(
    (sum, record) => sum + record.totalBetsAmount,
    0
  )

  const commDetails =
    (Number(totalBetsAmountSum ?? 0) * Number(userDetails?.karachiComm ?? 0)) /
    100

  const handleDownloadPDF = async () => {
    const input = document.getElementById('report-section')
    const pdf = new jsPDF('p', 'mm', 'a2')
    const canvas = await html2canvas(input, { scale: 2 })
    const imgData = canvas.toDataURL('image/png')

    const pdfWidth = pdf.internal.pageSize.getWidth()
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width

    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)
    pdf.save(`Report-${moment(fieldsGame?.date).format('DD-MM-YYYY')}.pdf`)
  }

  return (
    <>
      <Loader loading={loading} />
      <div className='z-0 overflow-hidden bg-gray-50 overflow-y-auto h-screen'>
        <div className='flex flex-col flex-1'>
          <main className='relative mt-4 px-4'>
            <div className='space-y-2'>

              <div className="justify-between gap-6 px-4 md:px-10 lg:px-44 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 pb-4">
                <div className="w-full">
                  <label className="block text-gray-700 text-base mb-1">Date</label>
                  <input
                    className="w-full px-3 py-1 text-base font-semibold bg-white border uppercase border-gray-400 rounded-md focus:outline-none"
                    id="date"
                    name="date"
                    placeholder="Enter Date"
                    value={
                      fieldsGame && fieldsGame["date"]
                        ? fieldsGame["date"]
                        : new Date().toISOString().split("T")[0]
                    }
                    max={new Date().toISOString().split("T")[0]}
                    type="date"
                    onChange={inputChangeCreate}
                  />
                  {errorsGame && errorsGame["date"] && (
                    <div className="text-red-600 text-base invalid-feedback">
                      {errorsGame["date"]}
                    </div>
                  )}
                </div>

                <div className="w-full">
                  <label className="block text-gray-700 text-base mb-1" htmlFor="name">
                    User Name :
                  </label>
                  {localData?.roleId === 0 ? <select
                    className="w-full px-3 py-2 text-base font-semibold bg-white border capitalize border-gray-400 rounded-md focus:outline-none"
                    id="userId"
                    name="userId"
                    placeholder="name"
                    value={
                      fieldsGame && fieldsGame["userId"] ? fieldsGame["userId"] : ""
                    }
                    type="text"
                    onChange={inputChangeCreate}
                  >
                    <option value="">Select User Name</option>
                    {subAdminData?.length > 0 &&
                      subAdminData.map((element, index) => (
                        <option key={index} value={element?._id || ""}>
                          {element?.userName || ""}
                        </option>
                      ))}
                  </select> : <div className='px-5 py-1 border border-gray-400 rounded'>
                    {userDetails?.userName || "Guest"}
                  </div>}
                  {errorsGame && errorsGame["userId"] && (
                    <div className="text-red-600 invalid-feedback">
                      {errorsGame["userId"]}
                    </div>
                  )}
                </div>

                <div className="w-full">
                  <label className="invisible">Submit Button</label>
                  <button
                    className="px-6 py-2 all-btn text-white font-bold rounded-md shadow-lg transition-all duration-200 w-full"
                    type="button"
                    onClick={createGameSubmit}
                  >
                    Submit
                  </button>
                </div>

                <div className="w-full">
                  <label className="invisible">Download Button</label>
                  <button
                    className="px-6 flex items-center gap-2 py-2 all-btn text-white font-bold rounded-md shadow-lg transition-all duration-200 w-full"
                    type="button"
                    onClick={handleDownloadPDF}
                  >
                    <FaDownload className="lg:ml-16 ml-2" /> Download Report
                  </button>
                </div>
              </div>
              {/* 
              {fieldsGame && !fieldsGame['userId'] && (
                <div className='font-bold flex justify-center text-xl py-40'>
                  Please Select User Name to see Game Profit Loss of User
                </div>
              )} */}
              {isOpen ? (
                <div
                  id='report-section'
                  class='bg-white shadow-md rounded-lg p-3 mx-auto w-2/5'
                >
                  <div className=' items-center'>
                    <div class='flex justify-center space-x-4 border-b bg-[#cecece] p-1'>
                      <div className='uppercase text-lg font-semibold'>
                        {' '}
                        {`${'<< KARACHI RING Draw Detail of'} ${moment(fieldsGame?.date).format('DD-MM-YYYY') ??
                          moment(today).format('DD-MM-YYYY')
                          } >>`}{' '}
                      </div>
                    </div>
                    <div className='border border-gray-400 p-0.5 flex justify-center font-bold uppercase bg-gray-200 text-lg'>{`<< ${userDetails?.userName ?? null
                      } >>`}</div>
                  </div>

                  <div className=''>
                    <div className=''>
                      <div className='grid grid-cols-4 border text-white text-base'>
                        {/* <div className='bgcolor px-2 py-2 font-semibold'>Mark</div> */}
                        <div className='bgcolor p-2 font-semibold text-center'>First Result</div>
                        <div className='bgcolor p-2 font-semibold text-center'>Second Result</div>
                        <div className='bgcolor p-2 font-semibold text-center'>Total Sale</div>
                        <div className='bgcolor p-2 font-semibold text-center'>Prize</div>
                      </div>
                      {getKarachiGamePayments &&
                        getKarachiGamePayments.length > 0 ? (
                        getKarachiGamePayments.map((ele) => {
                          return (
                            <>
                              <div className='grid grid-cols-4 border gap-[2px]'>
                                <div className='bg-[#C6E7FF] text-base p-2 text-center text-black font-semibold border border-gray-400'>{ele?.resultDateData?.firstResult ?? null}</div>
                                <div className='bg-[#C6E7FF] text-base p-2 text-center text-black font-semibold border border-gray-400'>{ele?.resultDateData?.secondResult?.join(" ,") ?? "---"}</div>
                                <div className='bg-[#C6E7FF] text-base p-2 text-center text-black font-semibold border border-gray-400'>{ele?.totalBetsAmount ?? 0}</div>
                                <div className='bg-[#C6E7FF] text-base p-2 text-center text-black font-semibold border border-gray-400'>{ele?.totalWinAmount ?? 0}</div>
                              </div>
                              <div className="relative overflow-x-auto">
                                <table className="w-full text-left text-black ">
                                  <thead className="text-sm font-semibold text-black uppercase bg-green-400 ">
                                    <tr>
                                      <th scope="col" className="px-6 py-2">
                                        Market
                                      </th>
                                      <th scope="col" className="px-6 py-2">
                                        Total Sale
                                      </th>
                                      <th scope="col" className="px-6 py-2">
                                        Price
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className='bg-gray-100'>
                                    <tr className="border-b  dark:border-gray-700 border-gray-200 font-semibold">
                                      <th
                                        scope="row"
                                        className="px-6 py-2 text-gray-900 whitespace-nowrap"
                                      >
                                        Open {`(${ele?.openResult})` !== "(null)" ? `(${ele?.openResult})` : ""}
                                      </th>
                                      <td className="px-6 py-2">{ele?.totalSingleOpenAmount || 0}</td>
                                      <td className="px-6 py-2">{ele?.totalSingleOpenWinAmount || 0}</td>
                                    </tr>
                                    <tr className="border-b  dark:border-gray-700 border-gray-200 font-semibold">
                                      <th
                                        scope="row"
                                        className="px-6 py-2 text-gray-900 whitespace-nowrap"
                                      >
                                        Close {`(${ele?.closeResult})` !== "(null)" ? `(${ele?.closeResult})` : ""}
                                      </th>
                                      <td className="px-6 py-2">{ele?.totalSingleCloseAmount || 0}</td>
                                      <td className="px-6 py-2">{ele?.totalSingleCloseWinAmount || 0}</td>
                                    </tr>
                                    <tr className="border-b  dark:border-gray-700 border-gray-200 font-semibold">
                                      <th
                                        scope="row"
                                        className="px-6 py-2 text-gray-900 whitespace-nowrap"
                                      >
                                        Center {`(${ele?.centerResult})` !== "(null)" ? `(${ele?.centerResult})` : ""}
                                      </th>
                                      <td className="px-6 py-2">{ele?.totalCenterAmount || 0}</td>
                                      <td className="px-6 py-2">{ele?.totalCenterWinAmount || 0}</td>
                                    </tr>
                                    <tr className="border-b  dark:border-gray-700 border-gray-200 font-semibold">
                                      <th
                                        scope="row"
                                        className="px-6 py-2 text-gray-900 whitespace-nowrap"
                                      >
                                        First Akda {`(${ele?.firstAkdaResult})` !== "(null)" ? `(${ele?.firstAkdaResult})` : ""}
                                      </th>
                                      <td className="px-6 py-2">{ele?.totalFirstJodiAmount || 0}</td>
                                      <td className="px-6 py-2">{ele?.totalFirstJodiWinAmount || 0}</td>
                                    </tr>
                                    <tr className="border-b  dark:border-gray-700 border-gray-200 font-semibold">
                                      <th
                                        scope="row"
                                        className="px-6 py-2 text-gray-900 whitespace-nowrap"
                                      >
                                        Second Akda {`(${ele?.secondAkdaResult})` !== "(null)" ? `(${ele?.secondAkdaResult})` : ""}
                                      </th>
                                      <td className="px-6 py-2">{ele?.totalSecondJodiAmount || 0}</td>
                                      <td className="px-6 py-2">{ele?.totalSecondJodiWinAmount || 0}</td>
                                    </tr>
                                    <tr className="border-b  dark:border-gray-700 border-gray-200 font-semibold">
                                      <th
                                        scope="row"
                                        className="px-6 py-2 text-gray-900 whitespace-nowrap"
                                      >
                                        First TND {`(${ele?.firstTndResult})` !== "(null)" ? `(${ele?.firstTndResult})` : ""}
                                      </th>
                                      <td className="px-6 py-2">{ele?.totalFirstTNDAmount || 0}</td>
                                      <td className="px-6 py-2">{ele?.totalFirstTNDWinAmount || 0}</td>
                                    </tr>
                                    <tr className="border-b  dark:border-gray-700 border-gray-200 font-semibold">
                                      <th
                                        scope="row"
                                        className="px-6 py-2 text-gray-900 whitespace-nowrap"
                                      >
                                        Second TND {`(${ele?.secondTndResult})` !== "(null)" ? `(${ele?.secondTndResult})` : ""}
                                      </th>
                                      <td className="px-6 py-2">{ele?.totalSecondTNDAmount || 0}</td>
                                      <td className="px-6 py-2">{ele?.totalSecondTNDWinAmount || 0}</td>
                                    </tr>
                                    <tr className="border-b  dark:border-gray-700 border-gray-200 font-semibold">
                                      <th
                                        scope="row"
                                        className="px-6 py-2 text-gray-900 whitespace-nowrap"
                                      >
                                        FS Ring {`(${ele?.resultDateData?.firstResult || ""}, ${(ele?.resultDateData?.secondResult.length > 0 ? ele?.resultDateData?.secondResult : [])?.join(", ")})` !== "(, )" ? `(${ele?.resultDateData?.firstResult || ""}, ${(ele?.resultDateData?.secondResult.length > 0 ? ele?.resultDateData?.secondResult : [])?.join(", ")})` : ""}
                                      </th>
                                      <td className="px-6 py-2">{ele?.totalRingAmount || 0}</td>
                                      <td className="px-6 py-2">{ele?.totalRingWinAmount || 0}</td>
                                    </tr>


                                  </tbody>
                                </table>
                              </div>



                              <div class='bg-gray-100 rounded-lg'>
                                {/* <p class='text-center font-bold text-md mb-1 border border-gray-400 py-1'>
                        SUMMARY
                      </p> */}
                                <div className='border border-gray-400 py-1'>
                                  <div
                                    class='flex justify-between text-base border-b border-neutral-400 mx-4'
                                    style={{ padding: '2px' }}
                                  >
                                    <span className='text-red-700 font-semibold'>
                                      Total Sale
                                    </span>
                                    <span className='font-semibold text-green-800'>
                                      {totalBetsAmountSum ?? 0}
                                    </span>
                                  </div>

                                  <div
                                    class='flex justify-between text-base border-b border-neutral-400 mx-4'
                                    style={{ padding: '2px' }}
                                  >
                                    <span className='text-red-700 font-semibold'>
                                      Total Prize
                                    </span>
                                    <span className='font-semibold text-green-800'>
                                      {ele?.totalWinAmount ?? 0}
                                    </span>
                                  </div>

                                  <div
                                    class='flex justify-between text-base border-b border-neutral-400 mx-4'
                                    style={{ padding: '2px' }}
                                  >
                                    <span className='text-red-700 font-semibold'>
                                      Comm. Detail
                                    </span>
                                    <span className='text-right text-[#2A0037] font-semibold'>
                                      {userDetails?.karachiComm ?? 0} %
                                    </span>
                                    <span className='font-semibold text-green-800'>
                                      {commDetails}
                                    </span>
                                  </div>

                                  <div
                                    class='flex justify-between text-base border-b border-neutral-400 mx-4'
                                    style={{ padding: '2px' }}
                                  >
                                    <span className='text-red-700 font-semibold'>
                                      {' '}
                                      Gross Amount
                                    </span>
                                    <span className='text-right text-[#2A0037] font-semibold'>
                                      {ele?.totalBetsAmount < ele?.totalWinAmount ? `Har  ` : `Jeet `}
                                    </span>
                                    <span className='font-semibold text-green-800'>
                                      {(userDetails?.karachiComm && Math.abs(ele?.totalBetsAmount - ((ele?.totalBetsAmount / userDetails?.karachiComm) + parseFloat(ele.totalWinAmount)))) || 0}
                                    </span>
                                  </div>


                                  <div
                                    class='flex justify-between text-base border-b border-neutral-400 mx-4'
                                    style={{ padding: '2px' }}
                                  >
                                    <span className='text-red-700 font-semibold'>
                                      {' '}
                                      Less % Percent
                                    </span>
                                    <span className=' text-[#2A0037] font-semibold'>
                                      {' '}
                                      {userDetails?.karachiShare || 0} %
                                    </span>
                                    <span className='font-semibold text-green-800'>
                                      {' '}
                                      {(userDetails?.karachiShare && Math.abs(ele?.totalBetsAmount - ((ele?.totalBetsAmount / userDetails?.karachiComm) + parseFloat(ele.totalWinAmount))) / userDetails?.karachiShare) || 0}
                                    </span>
                                  </div>

                                  <div
                                    class='flex justify-between text-base border-b border-neutral-400 mx-4'
                                    style={{ padding: '2px' }}
                                  >
                                    <span className='text-red-700 font-semibold'>
                                      {' '}
                                      Final % Net
                                    </span>
                                    <span className=' text-[#2A0037] font-semibold'>
                                      {' '}
                                      {ele?.totalBetsAmount < ele?.totalWinAmount ? (100 - userDetails?.karachiShare || 0) : userDetails?.karachiShare} %
                                    </span>
                                    <span className='font-semibold text-green-800'>
                                      {' '}
                                      {ele?.totalBetsAmount < ele?.totalWinAmount ? (((userDetails?.karachiComm && Math.abs(ele?.totalBetsAmount - ((ele?.totalBetsAmount / userDetails?.karachiComm) + parseFloat(ele.totalWinAmount)))) || 0) - ((userDetails?.karachiShare && Math.abs(ele?.totalBetsAmount - ((ele?.totalBetsAmount / userDetails?.karachiComm) + parseFloat(ele.totalWinAmount))) / userDetails?.karachiShare) || 0)) : (ele?.totalBetsAmount / userDetails?.karachiComm || 0)}
                                    </span>
                                  </div>

                                  <div
                                    class='flex justify-between text-base border-neutral-400 mx-4'
                                    style={{ padding: '2px' }}
                                  >
                                    <span className='text-red-700 font-semibold'>
                                      Final Amount
                                    </span>
                                    <span className='font-semibold text-green-800'>
                                      {' '}
                                      {ele?.totalBetsAmount < ele?.totalWinAmount ? (((userDetails?.karachiComm && Math.abs(ele?.totalBetsAmount - ((ele?.totalBetsAmount / userDetails?.karachiComm) + parseFloat(ele.totalWinAmount)))) || 0) - ((userDetails?.karachiShare && Math.abs(ele?.totalBetsAmount - ((ele?.totalBetsAmount / userDetails?.karachiComm) + parseFloat(ele.totalWinAmount))) / userDetails?.karachiShare) || 0)) : (ele?.totalBetsAmount / userDetails?.karachiComm || 0)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </main>
        </div>
      </div>
    </>
  )
}



export default KarachiProfitLoss
